import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBKS6glr7TnLNt-ZpwgCQQkvDyJwUbG9O4",
    authDomain: "ollincr-23001.firebaseapp.com",
    projectId: "ollincr-23001",
    storageBucket: "ollincr-23001.appspot.com",
    messagingSenderId: "519402509397",
    appId: "1:519402509397:web:77e8059fbe73d9695ab0dc",
    measurementId: "G-SM48NTWDJC"
};

// // Inicializar Firebase
// const app = initializeApp(firebaseConfig);

// // Obtener instancias de Auth y Firestore
// export const auth = getAuth(app);
// export const db = getFirestore(app);

// export default app;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };