import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Chip,
  Grid,
  Card,
  CardContent,
  CardMedia
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  GetApp as DownloadIcon,
  Print as PrintIcon,
  AccessTime as ClockIcon,
  ShoppingCart as CartIcon,
  ChevronLeft,
  ChevronRight,
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import { collection, query, getDocs, where, Timestamp, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { format, addDays, addWeeks, addMonths, isSameDay, getWeekOfMonth, getDay } from 'date-fns';
import { es } from 'date-fns/locale';
import { CSVLink } from 'react-csv';
import { RouteGroup, Route, Client, Order, OrderItem, InventoryItem } from '../models';

interface OrderTableItem {
  id: string;
  clientName: string;
  clientPhone: string;
  createdAt: Date;
  routeName: string;
  status: 'pending' | 'completed' | 'cancelled';
  deliveryType: 'pickup' | 'ollinItems';
  order: Order;
  routeGroup: RouteGroup;
}

const OrdersTable: React.FC = () => {
  const [orders, setOrders] = useState<OrderTableItem[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<OrderTableItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [deliveryTypeFilter, setDeliveryTypeFilter] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [clients, setClients] = useState<{ [key: string]: Client }>({});
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<OrderTableItem | null>(null);
  const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false);

  useEffect(() => {
    fetchClients();
    fetchInventoryItems();
    fetchOrders();
  }, [selectedDate]);

  const fetchClients = async () => {
    try {
      const clientsRef = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsRef);
      const clientsData: { [key: string]: Client } = {};
      clientsSnapshot.forEach((doc) => {
        clientsData[doc.id] = { id: doc.id, ...doc.data() } as Client;
      });
      setClients(clientsData);
      console.log("Clients fetched:", clientsData);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };

  const fetchInventoryItems = async () => {
    try {
      const inventoryRef = collection(db, 'inventoryItems');
      const inventorySnapshot = await getDocs(inventoryRef);
      const inventoryData: InventoryItem[] = [];
      inventorySnapshot.forEach((doc) => {
        inventoryData.push({ id: doc.id, ...doc.data() } as InventoryItem);
      });
      setInventoryItems(inventoryData);
    } catch (error) {
      console.error("Error fetching inventory items:", error);
    }
  };

  const fetchOrders = async () => {
    try {
      const routeGroupsRef = collection(db, 'routeGroups');
      const querySnapshot = await getDocs(routeGroupsRef);

      let fetchedOrders: OrderTableItem[] = [];

      for (const docSnapshot of querySnapshot.docs) {
        const routeGroup = { id: docSnapshot.id, ...docSnapshot.data() } as RouteGroup;
        console.log("Processing RouteGroup:", routeGroup);

        // Fetch routes as a subcollection
        const routesRef = collection(db, 'routeGroups', docSnapshot.id, 'routes');
        const routesSnapshot = await getDocs(routesRef);

        console.log(`Found ${routesSnapshot.size} routes for RouteGroup ${routeGroup.name}`);

        for (const routeDoc of routesSnapshot.docs) {
          const route = { id: routeDoc.id, ...routeDoc.data() } as Route;
          console.log("Processing Route:", route);

          if (route.order) {
            // Fetch client data
            const clientDoc = await getDoc(doc(db, 'clients', route.clientId));
            const clientData = clientDoc.data() as Client;
            console.log("Client data:", clientData);

            const occurrences = getRouteOccurrences(routeGroup, selectedDate);

            occurrences.forEach(occurrence => {
              fetchedOrders.push({
                id: `${docSnapshot.id}-${routeDoc.id}-${occurrence.getTime()}`,
                clientName: clientData ? clientData.name : 'Unknown',
                clientPhone: clientData ? clientData.phoneNumber : 'Unknown',
                createdAt: occurrence,
                routeName: routeGroup.name,
                status: route.status,
                deliveryType: 'ollinItems',
                order: route.order as Order, // Type assertion to ensure order is not undefined
                routeGroup: routeGroup
              });
            });
          }
        }
      }

      // Filter orders for the selected date
      fetchedOrders = fetchedOrders.filter(order => isSameDay(order.createdAt, selectedDate));

      console.log("Fetched orders:", fetchedOrders);
      setOrders(fetchedOrders);
      setFilteredOrders(fetchedOrders);
    } catch (error) {
      console.error("Error fetching orders: ", error);
    }
  };

  const getRouteOccurrences = (routeGroup: RouteGroup, date: Date): Date[] => {
    const occurrences: Date[] = [];
    const createdAt = routeGroup.createdAt instanceof Timestamp ? routeGroup.createdAt.toDate() : new Date(routeGroup.createdAt);

    switch (routeGroup.routeType) {
      case 'weekly':
        if (createdAt <= date) {
          let currentDate = createdAt;
          while (currentDate <= date) {
            if (isSameDay(currentDate, date)) {
              occurrences.push(new Date(currentDate));
            }
            currentDate = addWeeks(currentDate, 1);
          }
        }
        break;
      case 'biweekly':
        if (createdAt <= date) {
          let currentDate = createdAt;
          while (currentDate <= date) {
            if (isSameDay(currentDate, date)) {
              occurrences.push(new Date(currentDate));
            }
            currentDate = addWeeks(currentDate, 2);
          }
        }
        break;
      case 'monthly':
        if (getDay(date) === routeGroup.dayOfWeek && getWeekOfMonth(date) === routeGroup.weekOfMonth) {
          occurrences.push(new Date(date));
        }
        break;
    }

    return occurrences;
  };

  useEffect(() => {
    applyFilters();
  }, [orders, searchTerm, statusFilter, deliveryTypeFilter]);

  const applyFilters = () => {
    console.log("Applying filters. Current orders:", orders);
    console.log("Search term:", searchTerm);
    console.log("Status filter:", statusFilter);
    console.log("Delivery type filter:", deliveryTypeFilter);

    let filtered = orders.filter(order =>
      order.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.clientPhone.includes(searchTerm)
    );

    if (statusFilter) {
      filtered = filtered.filter(order => order.status === statusFilter);
    }

    if (deliveryTypeFilter) {
      filtered = filtered.filter(order => order.deliveryType === deliveryTypeFilter);
    }

    console.log("Filtered orders:", filtered);
    setFilteredOrders(filtered);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenFilterDialog = () => {
    setIsFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setIsFilterDialogOpen(false);
  };

  const handleApplyFilters = () => {
    applyFilters();
    handleCloseFilterDialog();
  };

  const handleResetFilters = () => {
    setStatusFilter(null);
    setDeliveryTypeFilter(null);
    setSearchTerm('');
    setFilteredOrders(orders);
    handleCloseFilterDialog();
  };

  const handlePreviousDay = () => {
    const newDate = addDays(selectedDate, -1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = addDays(selectedDate, 1);
    setSelectedDate(newDate);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleViewOrder = (order: OrderTableItem) => {
    setSelectedOrder(order);
    setIsOrderDetailsOpen(true);
  };

  const handleCloseOrderDetails = () => {
    setIsOrderDetailsOpen(false);
    setSelectedOrder(null);
  };

  const csvData = filteredOrders.map(order => ({
    ID: order.id,
    Nombre: order.clientName,
    Teléfono: order.clientPhone,
    'Fecha de Pedido': format(order.createdAt, 'dd/MM/yyyy'),
    'Nombre de Ruta': order.routeName,
    Estado: order.status,
    'Tipo de Entrega': order.deliveryType
  }));

  return (
    <Box sx={{ padding: 3 }}>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center', // Centrar todo
        alignItems: 'center',
        mb: 2,
      }}>
        <IconButton
          onClick={handlePreviousDay}
          sx={{
            color: 'white',
            backgroundColor: 'rgb(0, 180, 216)',
            borderRadius: '50%', // Botón circular
            '&:hover': {
              backgroundColor: 'rgba(0, 180, 216, 0.8)', // Efecto hover
            },
            width: 40,
            height: 40,
          }}
        >
          <ChevronLeft />
        </IconButton>

        <Box sx={{
          mx: 2,
          padding: 2,
          borderRadius: '20px', // Radio circular
          backgroundColor: 'rgb(0, 180, 216)',
          textAlign: 'center'
        }}>
          <Typography sx={{ fontWeight: 'bold', color: 'white', fontSize: '1.5rem' }}>
            {format(selectedDate, "EEEE d 'de' MMMM", { locale: es })}
          </Typography>
        </Box>

        <IconButton
          onClick={handleNextDay}
          sx={{
            color: 'white',
            backgroundColor: 'rgb(0, 180, 216)',
            borderRadius: '50%', // Botón circular
            '&:hover': {
              backgroundColor: 'rgba(0, 180, 216, 0.8)', // Efecto hover
            },
            width: 40,
            height: 40,
          }}
        >
          <ChevronRight />
        </IconButton>
      </Box>

      <Box sx={{ display: 'contents', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
            }}
          />
          <Box>
            <Tooltip title="Filtrar">
              <IconButton onClick={handleOpenFilterDialog}>
                <FilterIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Imprimir">
              <IconButton onClick={handlePrint}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
            <CSVLink data={csvData} filename={`pedidos_${format(selectedDate, 'yyyy-MM-dd')}.csv`}>
              <Tooltip title="Descargar CSV">
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </CSVLink>
          </Box>
        </Box>
      </Box>
      {(statusFilter || deliveryTypeFilter) && (
        <Box sx={{ mb: 2 }}>
          {statusFilter && (
            <Chip
              label={`Estado: ${statusFilter}`}
              onDelete={() => setStatusFilter(null)}
              sx={{ mr: 1, backgroundColor: 'rgb(0, 180, 216)', color: 'white' }}
            />
          )}
          {deliveryTypeFilter && (
            <Chip
              label={`Tipo: ${deliveryTypeFilter}`}
              onDelete={() => setDeliveryTypeFilter(null)}
              sx={{ mr: 1, backgroundColor: 'rgb(0, 180, 216)', color: 'white' }}
            />
          )}
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: 'rgb(0, 180, 216)' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Estado</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Tipo</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Nombre</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Tel</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Fecha de Pedido</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Nombre de Ruta</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders.length > 0 ? (
              filteredOrders.map((order) => (
                <TableRow key={order.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 180, 216, 0.1)',
                      '& .edit-icon': {
                        opacity: 1,
                      },
                    },
                  }}>
                  <TableCell>
                    <ClockIcon color={order.status === 'pending' ? 'action' : 'disabled'} />
                  </TableCell>
                  <TableCell>
                    <CartIcon color="primary" />
                  </TableCell>
                  <TableCell>{order.clientName}</TableCell>
                  <TableCell>{order.clientPhone}</TableCell>
                  <TableCell>{format(order.createdAt, 'dd/MM/yyyy')}</TableCell>
                  <TableCell>{order.routeName}</TableCell>
                  <TableCell>
                    <IconButton size="small" aria-label="ver" onClick={() => handleViewOrder(order)}
                      sx={{
                        color: '#00b4d8',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 180, 216, 0.1)',
                        },
                      }}>
                      <VisibilityIcon sx={{ color: 'rgb(0, 180, 216)' }} />
                    </IconButton>
                    {/* <IconButton size="small" aria-label="editar">
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" aria-label="eliminar">
                      <DeleteIcon />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No hay pedidos para mostrar
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isFilterDialogOpen} onClose={handleCloseFilterDialog}>
        <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
          Filtrar Pedidos
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Estado</InputLabel>
            <Select
              value={statusFilter || ''}
              onChange={(e) => setStatusFilter(e.target.value as string | null)}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="pending">Pendiente</MenuItem>
              <MenuItem value="completed">Completado</MenuItem>
              <MenuItem value="cancelled">Cancelado</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Tipo de Entrega</InputLabel>
            <Select
              value={deliveryTypeFilter || ''}
              onChange={(e) => setDeliveryTypeFilter(e.target.value as string | null)}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="pickup">Recolección</MenuItem>
              <MenuItem value="ollinItems">Ollin Items</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{
          justifyContent: 'center',
          px: 3,
          py: 2,
          backgroundColor: 'rgba(135, 206, 235, 0.1)'
        }}>
          <Button onClick={handleResetFilters}
            variant="outlined"
            sx={{
              color: '#87CEEB',
              borderColor: '#87CEEB',
              '&:hover': {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
                borderColor: '#5CACEE',
              },
              fontWeight: 'bold',
              px: 4,
              py: 1,
              mr: 2,
            }}>Resetear</Button>
          <Button onClick={handleApplyFilters}
            variant="contained"
            sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              '&:hover': {
                backgroundColor: '#5CACEE',
              },
              fontWeight: 'bold',
              px: 4,
              py: 1,
            }}>Aplicar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOrderDetailsOpen} onClose={handleCloseOrderDetails} maxWidth="md" fullWidth>
        <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
          Detalles del Pedido
        </DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 2 }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                Detalles del Pedido
              </Typography>
              <Typography variant="h6" gutterBottom>
                Cliente: <span style={{ fontWeight: 'normal' }}>{selectedOrder.clientName}</span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Estado: <span style={{ fontWeight: 'normal' }}>{selectedOrder.status}</span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Fecha: <span style={{ fontWeight: 'normal' }}>{format(selectedOrder.createdAt, 'dd/MM/yyyy')}</span>
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Ruta: <span style={{ fontWeight: 'normal' }}>{selectedOrder.routeName}</span>
              </Typography>
              <Typography variant="h6" sx={{ mt: 2, mb: 1, color: '#007BFF' }}>
                Ítems del Pedido
              </Typography>
              <Grid container spacing={2}>
                {selectedOrder.order.items.map((item: OrderItem) => {
                  const inventoryItem = inventoryItems.find(invItem => invItem.id === item.inventoryItemId);
                  return (
                    <Grid item xs={12} sm={6} md={4} key={item.inventoryItemId}>
                      <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: 3, borderRadius: 2 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={inventoryItem?.imageUrl}
                          alt={inventoryItem?.name || 'Product Image'}
                          sx={{
                            objectFit: 'contain', // Mantiene la proporción de la imagen
                            width: '100%', // Ancho completo
                            height: '140px', // Altura fija
                            backgroundColor: '#f0f0f0', // Fondo gris claro
                            borderTopLeftRadius: 2,
                            borderTopRightRadius: 2,
                          }}
                        />
                        <CardContent sx={{ flexGrow: 1, padding: 2 }}>
                          <Typography variant="h6" sx={{ color: '#333', fontWeight: 'bold' }}>{inventoryItem?.name || 'Unknown Product'}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            Precio: <span style={{ fontWeight: 'bold' }}>₡{inventoryItem?.price.toLocaleString() || 'N/A'}</span>
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Disponible: <span style={{ fontWeight: 'bold' }}>{inventoryItem?.quantity || 0}</span>
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <IconButton size="small" disabled>
                              <RemoveIcon />
                            </IconButton>
                            <Typography sx={{ mx: 1 }}>{item.quantity}</Typography>
                            <IconButton size="small" disabled>
                              <AddIcon />
                            </IconButton>
                          </Box>
                          <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>
                            Total: <span style={{ color: '#007BFF' }}>₡{((inventoryItem?.price || 0) * item.quantity).toLocaleString()}</span>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
              <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', color: '#333' }}>
                Total de la Orden: <span style={{ color: '#007BFF' }}>₡{selectedOrder.order.items.reduce((total, item) => {
                  const inventoryItem = inventoryItems.find(invItem => invItem.id === item.inventoryItemId);
                  return total + (inventoryItem?.price || 0) * item.quantity;
                }, 0).toLocaleString()}</span>
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
          <Button onClick={handleCloseOrderDetails}
            variant="outlined"
            sx={{
              color: '#87CEEB',
              borderColor: '#87CEEB',
              '&:hover': {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
                borderColor: '#5CACEE',
              },
              fontWeight: 'bold',
              px: 4,
              py: 1,
            }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
};

export default OrdersTable;