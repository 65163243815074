import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,

  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Paper,
  Card,
  CardContent,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  Chip,
  FormControlLabel,
  Tabs,
  Tab,
  OutlinedInput,
  InputAdornment,
  Switch,
  ListItemIcon,
  Snackbar,
  Alert,
  DialogContentText,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Print as PrintIcon,
  GetApp as DownloadIcon,
  FilterList as FilterIcon,
  ViewColumn as ViewColumnIcon,
  Search as SearchIcon,
  Remove as RemoveIcon,
  Navigation as WazeIcon,
  DirectionsCar as MapIcon,
  Update as UpdateIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { Client, PublicClient } from '../models';
import GoogleMapComponent from './GoogleMapComponent';
import { CSVLink } from 'react-csv';
import { alpha } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { keyframes, margin } from '@mui/system';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { Polyline } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
import { CodeIcon, PhoneIcon, MailIcon, MapPinIcon, FullscreenIcon } from 'lucide-react';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Create';
import TransferIcon from '@mui/icons-material/TransferWithinAStation'; // Adjust the import based on the correct icon
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Menu, Badge } from '@mui/material';
import { set } from 'date-fns';
import { Map as LeafletMap } from 'leaflet'; // Import the Leaflet Map type
import LocationOnIcon from '@mui/icons-material/LocationOn';

const MapEventHandler = ({ onClick }: { onClick: (e: L.LeafletMouseEvent) => void }) => {
  const map = useMap();

  React.useEffect(() => {
    // Agregar el evento de clic al mapa
    map.on('click', onClick);

    // Limpiar el evento cuando el componente se desmonte
    return () => {
      map.off('click', onClick);
    };
  }, [map, onClick]);

  return null; // Este componente no necesita renderizar nada
};
// Datos estáticos para Costa Rica
const costaRicaData = {
  provinces: ['Alajuela', 'Cartago', 'Guanacaste', 'Heredia', 'Limón', 'Puntarenas', 'San José'],
  cantons: {
    'Alajuela': ['Alajuela', 'San Ramón', 'Grecia', 'San Mateo', 'Atenas', 'Naranjo', 'Palmares', 'Poás', 'Orotina', 'San Carlos', 'Zarcero', 'Sarchí', 'Upala', 'Los Chiles', 'Guatuso', 'Río Cuarto'],
    'Cartago': ['Cartago', 'Paraíso', 'La Unión', 'Jiménez', 'Turrialba', 'Alvarado', 'Oreamuno', 'El Guarco'],
    'Guanacaste': ['Liberia', 'Nicoya', 'Santa Cruz', 'Bagaces', 'Carrillo', 'Cañas', 'Abangares', 'Tilarán', 'Nandayure', 'La Cruz', 'Hojancha'],
    'Heredia': ['Heredia', 'Barva', 'Santo Domingo', 'Santa Bárbara', 'San Rafael', 'San Isidro', 'Belén', 'Flores', 'San Pablo', 'Sarapiquí'],
    'Limón': ['Limón', 'Pococí', 'Siquirres', 'Talamanca', 'Matina', 'Guácimo'],
    'Puntarenas': ['Puntarenas', 'Esparza', 'Buenos Aires', 'Montes de Oro', 'Osa', 'Quepos', 'Golfito', 'Coto Brus', 'Parrita', 'Corredores', 'Garabito'],
    'San José': ['San José', 'Escazú', 'Desamparados', 'Puriscal', 'Tarrazú', 'Aserrí', 'Mora', 'Goicoechea', 'Santa Ana', 'Alajuelita', 'Vázquez de Coronado', 'Acosta', 'Tibás', 'Moravia', 'Montes de Oca', 'Turrubares', 'Dota', 'Curridabat', 'Pérez Zeledón', 'León Cortés Castro']
  },
  districts: {
    'Alajuela': {
      'Alajuela': ['Alajuela', 'San José', 'Carrizal', 'San Antonio', 'Guácima', 'San Isidro', 'Sabanilla', 'San Rafael', 'Río Segundo', 'Desamparados', 'Turrúcares', 'Tambor', 'Garita', 'Sarapiquí'],
      'San Ramón': ['San Ramón', 'Santiago', 'San Juan', 'Piedades Norte', 'Piedades Sur', 'San Rafael', 'San Isidro', 'Ángeles', 'Alfaro', 'Volio', 'Concepción', 'Zapotal', 'Peñas Blancas', 'San Lorenzo'],
      'Grecia': ['Grecia', 'San Isidro', 'San José', 'San Roque', 'Tacares', 'Río Cuarto', 'Puente de Piedra', 'Bolívar'],
      'San Mateo': ['San Mateo', 'Desmonte', 'Jesús María', 'Labrador'],
      'Atenas': ['Atenas', 'Jesús', 'Mercedes', 'San Isidro', 'Concepción', 'San José', 'Santa Eulalia', 'Escobal'],
      'Naranjo': ['Naranjo', 'San Miguel', 'San José', 'Cirrí Sur', 'San Jerónimo', 'San Juan', 'El Rosario', 'Palmitos'],
      'Palmares': ['Palmares', 'Zaragoza', 'Buenos Aires', 'Santiago', 'Candelaria', 'Esquipulas', 'La Granja'],
      'Poás': ['San Pedro', 'San Juan', 'San Rafael', 'Carrillos', 'Sabana Redonda'],
      'Orotina': ['Orotina', 'El Mastate', 'Hacienda Vieja', 'Coyolar', 'La Ceiba'],
      'San Carlos': ['Quesada', 'Florencia', 'Buenavista', 'Aguas Zarcas', 'Venecia', 'Pital', 'La Fortuna', 'La Tigra', 'La Palmera', 'Venado', 'Cutris', 'Monterrey', 'Pocosol'],
      'Zarcero': ['Zarcero', 'Laguna', 'Tapesco', 'Guadalupe', 'Palmira', 'Zapote', 'Brisas'],
      'Sarchí': ['Sarchí Norte', 'Sarchí Sur', 'Toro Amarillo', 'San Pedro', 'Rodríguez'],
      'Upala': ['Upala', 'Aguas Claras', 'San José o Pizote', 'Bijagua', 'Delicias', 'Dos Ríos', 'Yolillal', 'Canalete'],
      'Los Chiles': ['Los Chiles', 'Caño Negro', 'El Amparo', 'San Jorge'],
      'Guatuso': ['San Rafael', 'Buenavista', 'Cote', 'Katira'],
      'Río Cuarto': ['Río Cuarto', 'Santa Rita', 'Santa Isabel']
    },
    'Cartago': {
      'Cartago': ['Oriental', 'Occidental', 'Carmen', 'San Nicolás', 'Aguacaliente', 'Guadalupe', 'Corralillo', 'Tierra Blanca', 'Dulce Nombre', 'Llano Grande', 'Quebradilla'],
      'Paraíso': ['Paraíso', 'Santiago', 'Orosi', 'Cachí', 'Llanos de Santa Lucía'],
      'La Unión': ['Tres Ríos', 'San Diego', 'San Juan', 'San Rafael', 'Concepción', 'Dulce Nombre', 'San Ramón', 'Río Azul'],
      'Jiménez': ['Juan Viñas', 'Tucurrique', 'Pejibaye'],
      'Turrialba': ['Turrialba', 'La Suiza', 'Peralta', 'Santa Cruz', 'Santa Teresita', 'Pavones', 'Tuis', 'Tayutic', 'Santa Rosa', 'Tres Equis', 'La Isabel', 'Chirripó'],
      'Alvarado': ['Pacayas', 'Cervantes', 'Capellades'],
      'Oreamuno': ['San Rafael', 'Cot', 'Potrero Cerrado', 'Cipreses', 'Santa Rosa'],
      'El Guarco': ['El Tejar', 'San Isidro', 'Tobosi', 'Patio de Agua']
    },
    'Guanacaste': {
      'Liberia': ['Liberia', 'Cañas Dulces', 'Mayorga', 'Nacascolo', 'Curubandé'],
      'Nicoya': ['Nicoya', 'Mansión', 'San Antonio', 'Quebrada Honda', 'Sámara', 'Nosara', 'Belén de Nosarita'],
      'Santa Cruz': ['Santa Cruz', 'Bolsón', 'Veintisiete de Abril', 'Tempate', 'Cartagena', 'Cuajiniquil', 'Diriá', 'Cabo Velas', 'Tamarindo'],
      'Bagaces': ['Bagaces', 'La Fortuna', 'Mogote', 'Río Naranjo'],
      'Carrillo': ['Filadelfia', 'Palmira', 'Sardinal', 'Belén'],
      'Cañas': ['Cañas', 'Palmira', 'San Miguel', 'Bebedero', 'Porozal'],
      'Abangares': ['Las Juntas', 'Sierra', 'San Juan', 'Colorado'],
      'Tilarán': ['Tilarán', 'Quebrada Grande', 'Tronadora', 'Santa Rosa', 'Líbano', 'Tierras Morenas', 'Arenal', 'Cabeceras'],
      'Nandayure': ['Carmona', 'Santa Rita', 'Zapotal', 'San Pablo', 'Porvenir', 'Bejuco'],
      'La Cruz': ['La Cruz', 'Santa Cecilia', 'La Garita', 'Santa Elena'],
      'Hojancha': ['Hojancha', 'Monte Romo', 'Puerto Carrillo', 'Huacas', 'Matambú']
    },
    'Heredia': {
      'Heredia': ['Heredia', 'Mercedes', 'San Francisco', 'Ulloa', 'Varablanca'],
      'Barva': ['Barva', 'San Pedro', 'San Pablo', 'San Roque', 'Santa Lucía', 'San José de la Montaña'],
      'Santo Domingo': ['Santo Domingo', 'San Vicente', 'San Miguel', 'Paracito', 'Santo Tomás', 'Santa Rosa', 'Tures', 'Pará'],
      'Santa Bárbara': ['Santa Bárbara', 'San Pedro', 'San Juan', 'Jesús', 'Santo Domingo', 'Purabá'],
      'San Rafael': ['San Rafael', 'San Josecito', 'Santiago', 'Ángeles', 'Concepción'],
      'San Isidro': ['San Isidro', 'San José', 'Concepción', 'San Francisco'],
      'Belén': ['San Antonio', 'La Ribera', 'La Asunción'],
      'Flores': ['San Joaquín', 'Barrantes', 'Llorente'],
      'San Pablo': ['San Pablo', 'Rincón de Sabanilla'],
      'Sarapiquí': ['Puerto Viejo', 'La Virgen', 'Las Horquetas', 'Llanuras del Gaspar', 'Cureña']
    },
    'Limón': {
      'Limón': ['Limón', 'Valle La Estrella', 'Río Blanco', 'Matama'],
      'Pococí': ['Guápiles', 'Jiménez', 'Rita', 'Roxana', 'Cariari', 'Colorado', 'La Colonia'],
      'Siquirres': ['Siquirres', 'Pacuarito', 'Florida', 'Germania', 'El Cairo', 'Alegría', 'Reventazón'],
      'Talamanca': ['Bratsi', 'Sixaola', 'Cahuita', 'Telire'],
      'Matina': ['Matina', 'Batán', 'Carrandi'],
      'Guácimo': ['Guácimo', 'Mercedes', 'Pocora', 'Río Jiménez', 'Duacarí']
    },
    'Puntarenas': {
      'Puntarenas': ['Puntarenas', 'Pitahaya', 'Chomes', 'Lepanto', 'Paquera', 'Manzanillo', 'Guacimal', 'Barranca', 'Monte Verde', 'Isla del Coco', 'Cóbano', 'Chacarita', 'Chira', 'Acapulco', 'El Roble', 'Arancibia'],
      'Esparza': ['Espíritu Santo', 'San Juan Grande', 'Macacona', 'San Rafael', 'San Jerónimo', 'Caldera'],
      'Buenos Aires': ['Buenos Aires', 'Volcán', 'Potrero Grande', 'Boruca', 'Pilas', 'Colinas', 'Chánguena', 'Biolley', 'Brunka'],
      'Montes de Oro': ['Miramar', 'La Unión', 'San Isidro'],
      'Osa': ['Puerto Cortés', 'Palmar', 'Sierpe', 'Bahía Ballena', 'Piedras Blancas', 'Bahía Drake'],
      'Quepos': ['Quepos', 'Savegre', 'Naranjito'],
      'Golfito': ['Golfito', 'Puerto Jiménez', 'Guaycará', 'Pavón'],
      'Coto Brus': ['San Vito', 'Sabalito', 'Aguabuena', 'Limoncito', 'Pittier', 'Gutiérrez Braun'],
      'Parrita': ['Parrita'],
      'Corredores': ['Corredor', 'La Cuesta', 'Canoas', 'Laurel'],
      'Garabito': ['Jacó', 'Tárcoles']
    },
    'San José': {
      'San José': ['Carmen', 'Merced', 'Hospital', 'Catedral', 'Zapote', 'San Francisco de Dos Ríos', 'Uruca', 'Mata Redonda', 'Pavas', 'Hatillo', 'San Sebastián'],
      'Escazú': ['Escazú', 'San Antonio', 'San Rafael'],
      'Desamparados': ['Desamparados', 'San Miguel', 'San Juan de Dios', 'San Rafael Arriba', 'San Antonio', 'Frailes', 'Patarrá', 'San Cristóbal', 'Rosario', 'Damas', 'San Rafael Abajo', 'Gravilias', 'Los Guido'],
      'Puriscal': ['Santiago', 'Mercedes Sur', 'Barbacoas', 'Grifo Alto', 'San Rafael', 'Candelarita', 'Desamparaditos', 'San Antonio', 'Chires'],
      'Tarrazú': ['San Marcos', 'San Lorenzo', 'San Carlos'],
      'Aserrí': ['Aserrí', 'Tarbaca', 'Vuelta de Jorco', 'San Gabriel', 'Legua', 'Monterrey', 'Salitrillos'],
      'Mora': ['Colón', 'Guayabo', 'Tabarcia', 'Piedras Negras', 'Picagres', 'Jaris', 'Quitirrisí'],
      'Goicoechea': ['Guadalupe', 'San Francisco', 'Calle Blancos', 'Mata de Plátano', 'Ipís', 'Rancho Redondo', 'Purral'],
      'Santa Ana': ['Santa Ana', 'Salitral', 'Pozos', 'Uruca', 'Piedades', 'Brasil'],
      'Alajuelita': ['Alajuelita', 'San Josecito', 'San Antonio', 'Concepción', 'San Felipe'],
      'Vázquez de Coronado': ['San Isidro', 'San Rafael', 'Dulce Nombre de Jesús', 'Patalillo', 'Cascajal'],
      'Acosta': ['San Ignacio', 'Guaitil', 'Palmichal', 'Cangrejal', 'Sabanillas'],
      'Tibás': ['San Juan', 'Cinco Esquinas', 'Anselmo Llorente', 'León XIII', 'Colima'],
      'Moravia': ['San Vicente', 'San Jerónimo', 'La Trinidad'],
      'Montes de Oca': ['San Pedro', 'Sabanilla', 'Mercedes', 'San Rafael'],
      'Turrubares': ['San Pablo', 'San Pedro', 'San Juan de Mata', 'San Luis', 'Carara'],
      'Dota': ['Santa María', 'Jardín', 'Copey'],
      'Curridabat': ['Curridabat', 'Granadilla', 'Sánchez', 'Tirrases'],
      'Pérez Zeledón': ['San Isidro de El General', 'El General', 'Daniel Flores', 'Rivas', 'San Pedro', 'Platanares', 'Pejibaye', 'Cajón', 'Barú', 'Río Nuevo', 'Páramo', 'La Amistad'],
      'León Cortés Castro': ['San Pablo', 'San Andrés', 'Llano Bonito', 'San Isidro', 'Santa Cruz', 'San Antonio']
    }
  },
  municipalities: {
    'Alajuela': [
      "Municipalidad de Alajuela",
      "Municipalidad de San Ramón",
      "Municipalidad de Grecia",
      "Municipalidad de San Mateo",
      "Municipalidad de Atenas",
      "Municipalidad de Naranjo",
      "Municipalidad de Palmares",
      "Municipalidad de Poás",
      "Municipalidad de Orotina",
      "Municipalidad de San Carlos",
      "Municipalidad de Zarcero",
      "Municipalidad de Sarchí",
      "Municipalidad de Upala",
      "Municipalidad de Los Chiles",
      "Municipalidad de Guatuso",
      "Municipalidad de Río Cuarto"
    ],
    'Cartago': [
      "Municipalidad de Cartago",
      "Municipalidad de Paraíso",
      "Municipalidad de La Unión",
      "Municipalidad de Jiménez",
      "Municipalidad de Turrialba",
      "Municipalidad de Alvarado",
      "Municipalidad de Oreamuno",
      "Municipalidad de El Guarco"
    ],
    'Guanacaste': [
      "Municipalidad de Liberia",
      "Municipalidad de Nicoya",
      "Municipalidad de Santa Cruz",
      "Municipalidad de Bagaces",
      "Municipalidad de Carrillo",
      "Municipalidad de Cañas",
      "Municipalidad de Abangares",
      "Municipalidad de Tilarán",
      "Municipalidad de Nandayure",
      "Municipalidad de La Cruz",
      "Municipalidad de Hojancha"
    ],
    'Heredia': [
      "Municipalidad de Heredia",
      "Municipalidad de Barva",
      "Municipalidad de Santo Domingo",
      "Municipalidad de Santa Bárbara",
      "Municipalidad de San Rafael",
      "Municipalidad de San Isidro",
      "Municipalidad de Belén",
      "Municipalidad de Flores",
      "Municipalidad de San Pablo",
      "Municipalidad de Sarapiquí"
    ],
    'Limón': [
      "Municipalidad de Limón",
      "Municipalidad de Pococí",
      "Municipalidad de Siquirres",
      "Municipalidad de Talamanca",
      "Municipalidad de Matina",
      "Municipalidad de Guácimo"
    ],
    'Puntarenas': [
      "Municipalidad de Puntarenas",
      "Municipalidad de Esparza",
      "Municipalidad de Buenos Aires",
      "Municipalidad de Montes de Oro",
      "Municipalidad de Osa",
      "Municipalidad de Quepos",
      "Municipalidad de Golfito",
      "Municipalidad de Coto Brus",
      "Municipalidad de Parrita",
      "Municipalidad de Corredores",
      "Municipalidad de Garabito"
    ],
    'San José': [
      "Municipalidad de San José",
      "Municipalidad de Escazú",
      "Municipalidad de Desamparados",
      "Municipalidad de Puriscal",
      "Municipalidad de Tarrazú",
      "Municipalidad de Aserrí",
      "Municipalidad de Mora",
      "Municipalidad de Goicoechea",
      "Municipalidad de Santa Ana",
      "Municipalidad de Alajuelita",
      "Municipalidad de Vázquez de Coronado",
      "Municipalidad de Acosta",
      "Municipalidad de Tibás",
      "Municipalidad de Moravia",
      "Municipalidad de Montes de Oca",
      "Municipalidad de Turrubares",
      "Municipalidad de Dota",
      "Municipalidad de Curridabat",
      "Municipalidad de Pérez Zeledón",
      "Municipalidad de León Cortés Castro"
    ]
  }
};

interface ClientListProps {
  clients: Client[];
  publicClients: PublicClient[]; // Nueva propiedad para clientes públicos
  onUpdate: (client: Client) => Promise<void>;
  onDelete: (clientId: string) => Promise<void>;
  onAddNew: (client: Client) => Promise<void>;
  onUpdatePublicClient: (client: PublicClient) => Promise<void>; // Nuevo método para actualizar clientes públicos
  onAddNewPublicClient: (client: PublicClient) => Promise<void>; // Nuevo método para agregar nuevos clientes públicos
  onDeletePublicClient: (clientId: string) => Promise<void>; // Nuevo método para eliminar clientes públicos
}

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RecoleccionIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path d="M184.561 261.903c3.232 13.997-12.123 24.635-24.068 17.168l-40.736-25.455-50.867 81.402C55.606 356.273 70.96 384 96.012 384H148c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12H96.115c-75.334 0-121.302-83.048-81.408-146.88l50.822-81.388-40.725-25.448c-12.081-7.547-8.966-25.961 4.879-29.158l110.237-25.45c8.611-1.988 17.201 3.381 19.189 11.99l25.452 110.237zm98.561-182.915l41.289 66.076-40.74 25.457c-12.051 7.528-9 25.953 4.879 29.158l110.237 25.45c8.672 1.999 17.215-3.438 19.189-11.99l25.45-110.237c3.197-13.844-11.99-24.719-24.068-17.168l-40.687 25.424-41.263-66.082c-37.521-60.033-125.209-60.171-162.816 0l-17.963 28.766c-3.51 5.62-1.8 13.021 3.82 16.533l33.919 21.195c5.62 3.512 13.024 1.803 16.536-3.817l17.961-28.743c12.712-20.341 41.973-19.676 54.257-.022zM497.288 301.12l-27.515-44.065c-3.511-5.623-10.916-7.334-16.538-3.821l-33.861 21.159c-5.62 3.512-7.33 10.915-3.818 16.536l27.564 44.112c13.257 21.211-2.057 48.96-27.136 48.96H320V336.02c0-14.213-17.242-21.383-27.313-11.313l-80 79.981c-6.249 6.248-6.249 16.379 0 22.627l80 79.989C302.689 517.308 320 510.3 320 495.989V448h95.88c75.274 0 121.335-82.997 81.408-146.88z" />
  </SvgIcon>
);


// Componente para manejar el clic en el mapa
const MapClickHandler = ({ onMapClick }: { onMapClick: (lat: number, lng: number) => void }) => {
  const map = useMap();

  // Manejar el clic en el mapa
  map.on('click', (event) => {
    const { lat, lng } = event.latlng;
    // handleLocationSelect({ lat, lng }); // Llama a la función aquí
    onMapClick(lat, lng);
  });

  return null; // Este componente no renderiza nada
};

const ClientList: React.FC<ClientListProps> = ({ clients, publicClients, onUpdate, onDelete, onAddNew, onUpdatePublicClient, onAddNewPublicClient, onDeletePublicClient }) => {
  const [open, setOpen] = useState(false);
  const [openPublic, setOpenPublic] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [viewOpenPublic, setViewOpenPublic] = useState(false);
  const [currentClient, setCurrentClient] = useState<Client | null>(null);
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [isLocationSelectedPublic, setIsLocationSelectedPublic] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [cardFilteredClients, setCardFilteredClients] = useState<Client[]>(clients);
  const [cardFilteredClientsPublic, setCardFilteredClientsPublic] = useState<PublicClient[]>(publicClients);
  const [cardSearchTerm, setCardSearchTerm] = useState('');
  const [cardSearchTermPublic, setCardSearchTermPublic] = useState('');
  const [cardIsFilterDialogOpen, setCardIsFilterDialogOpen] = useState(false);
  const [cardIsFilterDialogOpenPublic, setCardIsFilterDialogOpenPublic] = useState(false);
  const [cardSelectedFilters, setCardSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [cardSelectedFiltersPublic, setCardSelectedFiltersPublic] = useState<{ [key: string]: string[] }>({});
  const [tableIsFilterDialogOpen, setTableIsFilterDialogOpen] = useState(false);
  const [tableIsFilterDialogOpenPublic, setTableIsFilterDialogOpenPublic] = useState(false);
  const [tableSelectedFilters, setTableSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [tableFilteredClients, setTableFilteredClients] = useState<Client[]>(clients);
  const [tableSearchTerm, setTableSearchTerm] = useState('');
  const [tableSearchTermPublic, setTableSearchTermPublic] = useState('');
  const [tableTipoComposteraFilter, setTableTipoComposteraFilter] = useState<string[]>([]);
  const [tableTipoDocumentoFilter, setTableTipoDocumentoFilter] = useState<string[]>([]);

  const [tableTipoClienteFilter, setTableTipoClienteFilter] = useState<string[]>([]);
  const [tableProvinciaFilter, setTableProvinciaFilter] = useState<string[]>([]);
  const [tableCantonFilter, setTableCantonFilter] = useState<string[]>([]);
  const [tableDistritoFilter, setTableDistritoFilter] = useState<string[]>([]);
  const [availableCantons, setAvailableCantons] = useState<string[]>([]);
  const [availableDistricts, setAvailableDistricts] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState<keyof Client>('name');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [visibleColumns, setVisibleColumns] = useState<Set<keyof Client>>(
    new Set<keyof Client>([
      'name',
      'phoneNumber',
      'email',
      'provincia',
      'canton',
      'distrito',
      'municipalidades',
      'senas',
      'nota',
      'tipo_entrega'
    ])
  );

  const [visiblePublicColumns, setVisiblePublicColumns] = useState<Set<keyof PublicClient>>(
    new Set<keyof PublicClient>([
      'name',
      'phoneNumber',
      'email',
      'provincia',
      'canton',
      'distrito',
      'municipalidades',
      'senas',
      'nota',
      'tipo_entrega',
      'status_create_public_client' // Añadir la nueva columna
    ])
  );

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isColumnSelectionDialogOpen, setIsColumnSelectionDialogOpen] = useState(false);

  const [provinces, setProvinces] = useState<string[]>([]);
  const [cantons, setCantons] = useState<string[]>([]);
  const [districts, setDistricts] = useState<string[]>([]);

  const [tabValue, setTabValue] = useState(0);

  const [mainTabValue, setMainTabValue] = useState(0);

  const [isHovered, setIsHovered] = useState(false);

  const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setMainTabValue(newValue);
  };

  // Estado para almacenar la ubicación seleccionada
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [googleMapsLink, setGoogleMapsLink] = useState<string>('');
  const [wazeLink, setWazeLink] = useState<string>('');

  // Asegúrate de definir mapCenter y decodedRouteCoordinates
  const mapCenter = { lat: 9.9281, lng: -84.0907 }; // Ejemplo de coordenadas
  const mapCenterPublic = { lat: 9.9281, lng: -84.0907 }; // Ejemplo de coordenadas
  const decodedRouteCoordinates: [number, number][] = []; // Asegúrate de que esto tenga un valor válido

  const [publicTableSelectedFilters, setPublicTableSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [publicTableFilteredClients, setPublicTableFilteredClients] = useState<PublicClient[]>(publicClients);

  // Define state for current public client
  const [currentPublicClient, setCurrentPublicClient] = useState<PublicClient | null>(null);

  // Definir los nuevos estados para el orden de PublicClient
  const [publicOrder, setPublicOrder] = useState<'asc' | 'desc'>('asc'); // Estado para la dirección del orden
  const [publicOrderBy, setPublicOrderBy] = useState<keyof PublicClient>('name'); // Estado para la propiedad por la que se ordena
  const [publicPage, setPublicPage] = useState(0); // Página actual
  const [publicRowsPerPage, setPublicRowsPerPage] = useState(5); // Filas por página

  const [newTabValue, setNewTabValue] = useState(0);

  const [selectedLocationPublic, setSelectedLocationPublic] = useState<{ lat: number; lng: number } | null>(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State to manage dialog visibility
  const [clientIdToDelete, setClientIdToDelete] = useState<string | null>(null); // State to store the client ID to delete

  const [deleteDialogOpenPublic, setDeleteDialogOpenPublic] = useState(false); // State to manage dialog visibility
  const [clientIdToDeletePublic, setClientIdToDeletePublic] = useState<string | null>(null); // State to store the client ID to delete

  // Define el estado para el diálogo de creación de cliente
  const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null); // Asegúrate de que Client esté definido
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // Nuevo estado para mensajes de éxito
  const notificationSound = new Audio(`${process.env.PUBLIC_URL}/audio/Notification.mp3`); // Update the path accordingly
  const navigate = useNavigate(); // Inicializa el hook de navegación
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pendingClients, setPendingClients] = useState<PublicClient[]>([]);
  const mapRef = useRef<LeafletMap>(null); // Create a ref with the correct type
  const [openLocationIcon, setOpenLocationIcon] = useState(false); // Estado para el diálogo de confirmación
  const [newLocation, setNewLocation] = useState<{ lat: number; lng: number } | null>(null); // Estado para la nueva ubicación

  // Define el icono personalizado usando un SVG
  const customIcon = L.divIcon({
    className: 'custom-icon',
    html: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 25 41" fill="none">
             <path d="M12.5 0C19.5 0 25 6 25 13.5C25 20.5 12.5 41 12.5 41C12.5 41 0 20.5 0 13.5C0 6 5.5 0 12.5 0Z" fill="#FF0000"/>
           </svg>`,
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
  });

  // Modify the Column Selection Dialog
  const columnOrder: (keyof Client)[] = [
    'name',
    'phoneNumber',
    'second_phoneNumber',
    'email',
    'tipo_compostera',
    'tipo_documento',
    'provincia',
    'distrito',
    'canton',
    'municipalidades',
    'wazeLink',
    'googleMapsLink',
    'senas',
    'nota',
    'tipo_entrega'
  ];

  const columnNames: { [key in keyof Client]: string } = {
    name: 'Nombre',
    phoneNumber: 'Teléfono',
    email: 'Correo Electrónico',
    googleMapsLink: 'Enlace Google Maps',
    wazeLink: 'Enlace Waze',
    id: 'ID',
    cedula_nacional: 'Cédula Nacional',
    second_phoneNumber: 'Teléfono Secundario',
    tipo_documento: 'Tipo de Documento',
    tipo_client: 'Tipo de Cliente',
    tipo_compostera: 'Tipo de Compostera',
    tipo_entrega: 'Tipo de Entrega',
    senas: 'Señas',
    nota: 'Nota',
    imageUrl: 'URL de Imagen',
    provincia: 'Provincia',
    canton: 'Cantón',
    distrito: 'Distrito',
    municipalidades: 'Municipalidad',
    cant_gato: 'Gato 0-6Kg',
    cant_perro_mini: 'Mini 0-6Kg',
    cant_perro_pequeno: 'Pequeño 6-10Kg',
    cant_perro_mediano: 'Mediano 10-25Kg',
    cant_perro_grande: 'Grande 25-45Kg',
    cant_perro_gigante: 'Gigante +45Kg',
    nota_admin: 'Nota Admin',
    codigo_referencia: 'Código de Referencia',
  };

  const publicColumnNames: { [key in keyof PublicClient]: string } = {
    name: 'Nombre',
    phoneNumber: 'Teléfono',
    email: 'Correo Electrónico',
    googleMapsLink: 'Enlace Google Maps',
    wazeLink: 'Enlace Waze',
    id: 'ID',
    cedula_nacional: 'Cédula Nacional',
    second_phoneNumber: 'Teléfono Secundario',
    tipo_documento: 'Tipo de Documento',
    tipo_client: 'Tipo de Cliente',
    tipo_compostera: 'Tipo de Compostera',
    tipo_entrega: 'Tipo de Entrega',
    senas: 'Señas',
    nota: 'Nota',
    imageUrl: 'URL de Imagen',
    provincia: 'Provincia',
    canton: 'Cantón',
    distrito: 'Distrito',
    municipalidades: 'Municipalidad',
    cant_gato: 'Gato 0-6Kg',
    cant_perro_mini: 'Mini 0-6Kg',
    cant_perro_pequeno: 'Pequeño 6-10Kg',
    cant_perro_mediano: 'Mediano 10-25Kg',
    cant_perro_grande: 'Grande 25-45Kg',
    cant_perro_gigante: 'Gigante +45Kg',
    status_create_public_client: 'Estado de Creación',
    codigo_referencia: 'Código de Referencia',
  };

  // Function to handle opening the menu
  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the menu
  const handleCloseNotification = () => {
    setAnchorEl(null);
  };

  const handleDeletePublicClient = (clientId: string) => {
    setClientIdToDeletePublic(clientId); // Set the client ID to delete
    setDeleteDialogOpenPublic(true); // Open the delete confirmation dialog
  };

  const confirmDeletePublicClient = async () => {
    if (clientIdToDeletePublic) {
      await onDeletePublicClient(clientIdToDeletePublic); // Call the delete function

      setPublicTableFilteredClients(prev => prev.filter(client => client.id !== clientIdToDeletePublic));

      setClientIdToDeletePublic(null); // Reset the client ID
      setDeleteDialogOpenPublic(false); // Close the dialog
    }
  };

  const handleDeleteClient = (clientId: string) => {
    setClientIdToDelete(clientId); // Set the client ID to delete
    setDeleteDialogOpen(true); // Open the delete confirmation dialog
  };

  const confirmDeleteClient = async () => {
    if (clientIdToDelete) {
      await onDelete(clientIdToDelete); // Call the delete function

      setTableFilteredClients(prev => prev.filter(client => client.id !== clientIdToDelete));

      setClientIdToDelete(null); // Reset the client ID
      setDeleteDialogOpen(false); // Close the dialog
    }
  };

  const handleSavePublicClient = async () => { // Nueva función para manejar la guardado de clientes públicos
    if (isPublicFormValid() && currentPublicClient) {
      if (currentPublicClient.id) {
        await onUpdatePublicClient(currentPublicClient); // Actualiza el cliente público
      } else {
        await onAddNewPublicClient(currentPublicClient); // Agrega un nuevo cliente público
      }
      setPublicTableFilteredClients(prev => {
        // Si es un nuevo cliente público, lo agregamos a la lista
        if (!currentPublicClient.id) {
          return [currentPublicClient, ...prev];
        }
        // Si es una actualización, buscamos y reemplazamos el cliente público actualizado
        return prev.map(client => (client.id === currentPublicClient.id ? currentPublicClient : client));
      });
      setOpenPublic(false);
      handleClosePublicClient(); // Cierra el diálogo
    }
  };

  const isPublicFormValid = () => { // Nueva función para validar el formulario de cliente público
    console.log('Validando formulario público:', currentPublicClient); // Verifica el estado de currentPublicClient
    console.log('Ubicación seleccionada:', isLocationSelectedPublic); // Verifica el estado de isLocationSelectedPublic

    return (
      currentPublicClient &&
      currentPublicClient.phoneNumber.trim() !== '' &&
      currentPublicClient.name.trim() !== '' &&
      currentPublicClient.email.trim() !== '' &&
      isLocationSelectedPublic // Asegúrate de que la ubicación esté seleccionada
    );
  };

  // Asegúrate de que los estados se actualicen correctamente en los manejadores de eventos
  const handleInputChange = (field: keyof Client, value: string) => {
    setCurrentClient(curr => {
      if (!curr) return null;
      return { ...curr, [field]: value }; // Actualiza el campo correspondiente
    });
  };

  const handlePublicInputChange = (field: keyof PublicClient, value: string) => {
    setCurrentPublicClient(curr => {
      if (!curr) return null;
      return { ...curr, [field]: value }; // Actualiza el campo correspondiente
    });
  };

  const applyPublicFilters = () => {
    let filtered = publicClients.filter(client =>
      client.name && client.name.toLowerCase().includes(tableSearchTermPublic.toLowerCase()) // Verificación añadida
    );

    Object.entries(publicTableSelectedFilters).forEach(([key, values]) => {
      if (values.length > 0) {
        filtered = filtered.filter(client => values.includes(client[key as keyof PublicClient] as string));
      }
    });

    setPublicTableFilteredClients(filtered);
  };

  const applyCardFilters = () => {
    let filtered = clients.filter(client =>
      client.name && client.name.toLowerCase().includes(cardSearchTerm.toLowerCase())
    );

    Object.entries(cardSelectedFilters).forEach(([key, values]) => {
      if (values.length > 0) {
        filtered = filtered.filter(client => values.includes(client[key as keyof Client] as string));
      }
    });

    setCardFilteredClients(filtered);
  };

  const applyCardFiltersPublic = () => {
    let filtered = publicClients.filter(client =>
      client.name && client.name.toLowerCase().includes(cardSearchTermPublic.toLowerCase()) // Verificación añadida
    );

    Object.entries(publicTableSelectedFilters).forEach(([key, values]) => {
      if (values.length > 0) {
        filtered = filtered.filter(client => values.includes(client[key as keyof PublicClient] as string));
      }
    });

    setCardFilteredClientsPublic(filtered);
  };

  const applyTableFilters = () => {
    let filtered = clients.filter(client =>
      Object.values(client).some(value =>
        value.toString().toLowerCase().includes(tableSearchTerm.toLowerCase())
      )
    );

    Object.entries(tableSelectedFilters).forEach(([key, values]) => {
      if (values.length > 0) {
        filtered = filtered.filter(client => values.includes(client[key as keyof Client] as string));
      }
    });

    setTableFilteredClients(filtered);
  };

  const handlePublicOpen = useCallback((client: PublicClient | null = null) => {
    setCurrentPublicClient(client || {
      name: '',
      phoneNumber: '',
      email: '',
      googleMapsLink: '',
      wazeLink: '',
      cedula_nacional: '',
      second_phoneNumber: '',
      tipo_documento: 'Nacional',
      tipo_client: 'Municipalidad',
      tipo_compostera: 'Recolección',
      tipo_entrega: 'Recolección',
      senas: '',
      nota: '',
      imageUrl: '',
      provincia: '',
      canton: '',
      distrito: '',
      municipalidades: '',
      cant_gato: '0',
      cant_perro_mini: '0',
      cant_perro_pequeno: '0',
      cant_perro_mediano: '0',
      cant_perro_grande: '0',
      cant_perro_gigante: '0',
      status_create_public_client: 'pending',
      codigo_referencia: '',
    });

    // Cargar los datos de canton, distrito y municipalidad si el cliente existe
    if (client) {
      setAvailableCantons(costaRicaData.cantons[client.provincia as keyof typeof costaRicaData.cantons] || []);
      const provinceDistricts = costaRicaData.districts[client.provincia as keyof typeof costaRicaData.districts];
      // Verificar que el cantón esté definido antes de cargar los distritos
      if (client.canton && provinceDistricts) {
        setAvailableDistricts(provinceDistricts[client.canton as keyof typeof provinceDistricts] || []);
        // setCurrentPublicClient(curr => curr ? { ...curr, distrito: client.distrito } : null); // Establecer el distrito
      } else {
        setAvailableDistricts([]); // Si no hay cantón, resetea los distritos
      }
    }

    setIsLocationSelectedPublic(!!client);

    if (client && client.googleMapsLink) {
      const coordinates = extractCoordinates(client.googleMapsLink);
      if (coordinates) {
        const [lat, lng] = coordinates.split(',').map(Number);
        setSelectedLocationPublic({ lat, lng }); // Establece la ubicación seleccionada
      }
    } else {
      setSelectedLocationPublic(null); // Si no hay enlace, no hay ubicación
    }

    setOpenPublic(true);
  }, []);

  useEffect(() => {
    // Aplicar filtros a clientes y clientes públicos
    applyTableFilters();
    applyCardFilters();

    applyPublicFilters();
    applyCardFiltersPublic();

    // Filtrar clientes públicos pendientes
    const filteredClients = publicClients.filter(client => client.status_create_public_client === 'pending');
    setPendingClients(filteredClients);

    // Restablecer la paginación al cambiar de pestaña
    if (newTabValue === 0) {
      setPublicPage(0); // Restablecer a la primera página al cambiar a la tabla de Client
    } else {
      setPage(0); // Restablecer a la primera página al cambiar a la tabla de PublicClient
    }
  }, [clients, cardSelectedFilters, tableSelectedFilters, cardSearchTerm, cardSearchTermPublic, tableSearchTerm, tableSearchTermPublic, publicTableSelectedFilters, publicClients, newTabValue, openLocationIcon]);
  const handleTableProvinceChange = (selectedProvinces: string[]) => {
    setTableProvinciaFilter(selectedProvinces);
    setTableCantonFilter([]);
    setTableDistritoFilter([]);

    if (selectedProvinces.length === 1) {
      const province = selectedProvinces[0];
      setAvailableCantons(costaRicaData.cantons[province as keyof typeof costaRicaData.cantons] || []);
    } else {
      setAvailableCantons([]);
    }
    setAvailableDistricts([]);
  };

  const handleTableCantonChange = (selectedCantons: string[]) => {
    setTableCantonFilter(selectedCantons);
    setTableDistritoFilter([]);

    if (tableProvinciaFilter.length === 1 && selectedCantons.length === 1) {
      const province = tableProvinciaFilter[0] as keyof typeof costaRicaData.districts;
      const canton = selectedCantons[0];
      const provinceDistricts = costaRicaData.districts[province];
      if (provinceDistricts && canton in provinceDistricts) {
        setAvailableDistricts(provinceDistricts[canton as keyof typeof provinceDistricts]);
      } else {
        setAvailableDistricts([]);
      }
    } else {
      setAvailableDistricts([]);
    }
  };

  const handleFullScreen = () => {
    if (mapRef.current) {
      // Verificar si el navegador soporta la API de pantalla completa
      if (document.fullscreenEnabled) {
        if (document.fullscreenElement) {
          document.exitFullscreen().catch((err: unknown) => {
            console.error("Error al salir de pantalla completa:", err);
          });
        } else {
          // Get the container element of the map
          const mapContainer = mapRef.current.getContainer(); // Get the container of the Leaflet map
          if (mapContainer) {
            mapContainer.requestFullscreen().catch((err: unknown) => {
              console.error("Error al entrar en pantalla completa:", err);
            });
          } else {
            console.error("mapRef.current no tiene un contenedor válido.");
          }
        }
      } else {
        console.warn("La API de pantalla completa no está soportada en este navegador.");
      }
    } else {
      console.error("mapRef no está definido o no apunta a un elemento válido.");
    }
  };

  const handleOpen = useCallback((client: Client | null = null) => {
    setCurrentClient(client || {
      name: '',
      phoneNumber: '',
      email: '',
      googleMapsLink: '',
      wazeLink: '',
      cedula_nacional: '',
      second_phoneNumber: '',
      tipo_documento: 'Nacional',
      tipo_client: 'Municipalidad',
      tipo_compostera: 'Recolección',
      tipo_entrega: 'Recolección',
      senas: '',
      nota: '',
      imageUrl: '',
      provincia: '',
      canton: '',
      distrito: '',
      municipalidades: '',
      cant_gato: '0',
      cant_perro_mini: '0',
      cant_perro_pequeno: '0',
      cant_perro_mediano: '0',
      cant_perro_grande: '0',
      cant_perro_gigante: '0',
      nota_admin: '',
      codigo_referencia: '',
    });

    // Cargar los datos de canton, distrito y municipalidad si el cliente existe
    if (client) {
      setCantons(costaRicaData.cantons[client.provincia as keyof typeof costaRicaData.cantons] || []);
      const provinceDistricts = costaRicaData.districts[client.provincia as keyof typeof costaRicaData.districts];
      setDistricts(provinceDistricts ? provinceDistricts[client.canton as keyof typeof provinceDistricts] : []);
    }

    setIsLocationSelected(!!client);

    if (client && client.googleMapsLink) {
      const coordinates = extractCoordinates(client.googleMapsLink);
      if (coordinates) {
        const [lat, lng] = coordinates.split(',').map(Number);
        setSelectedLocation({ lat, lng }); // Establece la ubicación seleccionada
      }
    } else {
      setSelectedLocation(null); // Si no hay enlace, no hay ubicación
    }

    setOpen(true);
  }, []);

  const handleCardOpenFilterDialog = () => {
    setCardIsFilterDialogOpen(true);
  };

  const handleCardCloseFilterDialog = () => {
    setCardIsFilterDialogOpen(false);
  };

  const handleCardOpenFilterDialogPublic = () => {
    setCardIsFilterDialogOpenPublic(true);
  };

  const handleCardCloseFilterDialogPublic = () => {
    setCardIsFilterDialogOpenPublic(false);
  };

  const handleTableOpenFilterDialog = () => {
    setTableIsFilterDialogOpen(true);
  };

  const handleTableCloseFilterDialog = () => {
    setTableIsFilterDialogOpen(false);
  };

  const handleTableOpenFilterDialogPublic = () => {
    setTableIsFilterDialogOpenPublic(true);
  };

  const handleTableCloseFilterDialogPublic = () => {
    setTableIsFilterDialogOpenPublic(false);
  };

  const handleClosePublicClient = () => {
    setOpenPublic(false);
    setCurrentPublicClient(null);
    setSelectedLocationPublic(null);
  };

  const handleCloseClient = () => {
    setOpen(false);
    setCurrentClient(null);
    setSelectedLocation(null);
  };

  const handleViewClosePublic = () => {
    setViewOpenPublic(false);
    setCurrentPublicClient(null);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentClient(null);
  };

  const handleViewOpen = (client: Client) => {
    setCurrentClient(client);
    setViewOpen(true);
  };

  const handleViewOpenPublic = (client: PublicClient) => {
    setCurrentPublicClient(client); // Asegúrate de que se pase el cliente correcto
    setViewOpenPublic(true); // Abre el diálogo después de establecer el cliente
  };

  const handleViewClose = () => {
    setViewOpen(false);
    setCurrentClient(null);
  };

  const handleSave = async () => {
    if (isFormValid() && currentClient) {
      if (currentClient.id) {
        await onUpdate(currentClient);
      } else {
        await onAddNew(currentClient);
      }
      setTableFilteredClients(prev => {
        // Si es un nuevo cliente, lo agregamos a la lista
        if (!currentClient.id) {
          return [currentClient, ...prev];
        }
        // Si es una actualización, buscamos y reemplazamos el cliente actualizado
        return prev.map(client => (client.id === currentClient.id ? currentClient : client));
      });
      setOpen(false);
      handleClose();
    }
  };

  const extractCoordinates = (url: string): string | undefined => {
    const regex = /[-]?\d+\.\d+/g;
    const matches = url.match(regex);
    if (matches && matches.length >= 2) {
      return `${matches[0]},${matches[1]}`;
    }
    return undefined;
  };

  const handleLocationSelect = (location: { lat: number; lng: number }) => {
    // Solo actualizar las coordenadas y los enlaces de Google Maps y Waze
    setCurrentClient(curr => {
      if (!curr) return null;

      return {
        ...curr,
        googleMapsLink: `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`,
        wazeLink: `https://www.waze.com/ul?ll=${location.lat},${location.lng}&navigate=yes`
      };
    });

    setIsLocationSelected(true);
  };


  // Nueva función para actualizar manualmente la ubicación
  const handleUpdateLocation = () => {
    if (currentClient && currentClient.googleMapsLink) {
      const coordinates = extractCoordinates(currentClient.googleMapsLink);
      if (coordinates) {
        const [lat, lng] = coordinates.split(',').map(Number);
        const location = findLocationInCostaRicaData({ lat, lng });
        setCurrentClient(curr => {
          if (!curr) return null;
          return {
            ...curr,
            provincia: location.provincia,
            canton: location.canton,
            distrito: location.distrito,
            // Mantenemos el valor actual de municipalidades en lugar de actualizarlo
            municipalidades: curr.municipalidades
          };
        });

        // Actualizamos los selectores
        if (location.provincia in costaRicaData.cantons) {
          setCantons(costaRicaData.cantons[location.provincia as keyof typeof costaRicaData.cantons]);
        }
        if (location.provincia in costaRicaData.districts) {
          const provinciaDistricts = costaRicaData.districts[location.provincia as keyof typeof costaRicaData.districts];
          if (provinciaDistricts && location.canton in provinciaDistricts) {
            setDistricts(provinciaDistricts[location.canton as keyof typeof provinciaDistricts]);
          }
        }
      }
    }
  };


  interface Boundary {
    minLat: number;
    maxLat: number;
    minLng: number;
    maxLng: number;
  }

  interface LocationData {
    provincia: string;
    canton: string;
    distrito: string;
  }

  const cantonBoundaries: { [key: string]: { [key: string]: Boundary } } = {
    'San José': {
      'San José': { minLat: 9.9, maxLat: 10.0, minLng: -84.1, maxLng: -84.0 },
      'Escazú': { minLat: 9.9, maxLat: 9.95, minLng: -84.2, maxLng: -84.1 },
      'Desamparados': { minLat: 9.85, maxLat: 9.95, minLng: -84.1, maxLng: -84.0 },
      'Puriscal': { minLat: 9.75, maxLat: 9.9, minLng: -84.4, maxLng: -84.2 },
      'Tarrazú': { minLat: 9.6, maxLat: 9.75, minLng: -84.1, maxLng: -83.9 },
      'Aserrí': { minLat: 9.8, maxLat: 9.9, minLng: -84.15, maxLng: -84.05 },
      'Mora': { minLat: 9.85, maxLat: 9.95, minLng: -84.3, maxLng: -84.2 },
      'Goicoechea': { minLat: 9.95, maxLat: 10.05, minLng: -84.05, maxLng: -83.95 },
      'Santa Ana': { minLat: 9.9, maxLat: 10.0, minLng: -84.25, maxLng: -84.15 },
      'Alajuelita': { minLat: 9.85, maxLat: 9.95, minLng: -84.15, maxLng: -84.05 },
      'Vázquez de Coronado': { minLat: 10.0, maxLat: 10.1, minLng: -84.0, maxLng: -83.9 },
      'Acosta': { minLat: 9.7, maxLat: 9.85, minLng: -84.3, maxLng: -84.1 },
      'Tibás': { minLat: 9.95, maxLat: 10.0, minLng: -84.1, maxLng: -84.05 },
      'Moravia': { minLat: 10.0, maxLat: 10.05, minLng: -84.05, maxLng: -83.95 },
      'Montes de Oca': { minLat: 9.95, maxLat: 10.0, minLng: -84.05, maxLng: -83.95 },
      'Turrubares': { minLat: 9.75, maxLat: 9.9, minLng: -84.6, maxLng: -84.4 },
      'Dota': { minLat: 9.55, maxLat: 9.7, minLng: -84.0, maxLng: -83.8 },
      'Curridabat': { minLat: 9.9, maxLat: 9.95, minLng: -84.05, maxLng: -83.95 },
      'Pérez Zeledón': { minLat: 9.2, maxLat: 9.5, minLng: -83.8, maxLng: -83.4 },
      'León Cortés Castro': { minLat: 9.65, maxLat: 9.8, minLng: -84.1, maxLng: -83.9 }
    },
    'Alajuela': {
      'Alajuela': { minLat: 10.0, maxLat: 10.1, minLng: -84.3, maxLng: -84.1 },
      'San Ramón': { minLat: 10.05, maxLat: 10.2, minLng: -84.6, maxLng: -84.4 },
      'Grecia': { minLat: 10.05, maxLat: 10.2, minLng: -84.35, maxLng: -84.2 },
      'San Mateo': { minLat: 9.9, maxLat: 10.0, minLng: -84.6, maxLng: -84.45 },
      'Atenas': { minLat: 9.95, maxLat: 10.05, minLng: -84.45, maxLng: -84.35 },
      'Naranjo': { minLat: 10.1, maxLat: 10.2, minLng: -84.45, maxLng: -84.35 },
      'Palmares': { minLat: 10.05, maxLat: 10.15, minLng: -84.5, maxLng: -84.4 },
      'Poás': { minLat: 10.15, maxLat: 10.25, minLng: -84.3, maxLng: -84.2 },
      'Orotina': { minLat: 9.9, maxLat: 10.0, minLng: -84.6, maxLng: -84.5 },
      'San Carlos': { minLat: 10.3, maxLat: 10.9, minLng: -84.6, maxLng: -84.0 },
      'Zarcero': { minLat: 10.15, maxLat: 10.25, minLng: -84.45, maxLng: -84.35 },
      'Sarchí': { minLat: 10.1, maxLat: 10.2, minLng: -84.4, maxLng: -84.3 },
      'Upala': { minLat: 10.8, maxLat: 11.0, minLng: -85.2, maxLng: -84.8 },
      'Los Chiles': { minLat: 10.8, maxLat: 11.1, minLng: -84.8, maxLng: -84.4 },
      'Guatuso': { minLat: 10.6, maxLat: 10.8, minLng: -84.9, maxLng: -84.6 },
      'Río Cuarto': { minLat: 10.3, maxLat: 10.5, minLng: -84.4, maxLng: -84.2 }
    },
    'Cartago': {
      'Cartago': { minLat: 9.8, maxLat: 9.9, minLng: -84.0, maxLng: -83.9 },
      'Paraíso': { minLat: 9.8, maxLat: 9.9, minLng: -83.9, maxLng: -83.8 },
      'La Unión': { minLat: 9.9, maxLat: 10.0, minLng: -84.0, maxLng: -83.9 },
      'Jiménez': { minLat: 9.7, maxLat: 9.8, minLng: -83.8, maxLng: -83.7 },
      'Turrialba': { minLat: 9.8, maxLat: 10.1, minLng: -83.7, maxLng: -83.5 },
      'Alvarado': { minLat: 9.9, maxLat: 10.0, minLng: -83.85, maxLng: -83.75 },
      'Oreamuno': { minLat: 9.9, maxLat: 10.0, minLng: -83.9, maxLng: -83.8 },
      'El Guarco': { minLat: 9.75, maxLat: 9.85, minLng: -84.0, maxLng: -83.9 }
    },
    'Heredia': {
      'Heredia': { minLat: 10.0, maxLat: 10.05, minLng: -84.15, maxLng: -84.1 },
      'Barva': { minLat: 10.05, maxLat: 10.1, minLng: -84.15, maxLng: -84.1 },
      'Santo Domingo': { minLat: 10.0, maxLat: 10.05, minLng: -84.1, maxLng: -84.05 },
      'Santa Bárbara': { minLat: 10.05, maxLat: 10.1, minLng: -84.2, maxLng: -84.15 },
      'San Rafael': { minLat: 10.05, maxLat: 10.1, minLng: -84.15, maxLng: -84.1 },
      'San Isidro': { minLat: 10.05, maxLat: 10.1, minLng: -84.1, maxLng: -84.05 },
      'Belén': { minLat: 9.95, maxLat: 10.0, minLng: -84.2, maxLng: -84.15 },
      'Flores': { minLat: 10.0, maxLat: 10.05, minLng: -84.2, maxLng: -84.15 },
      'San Pablo': { minLat: 10.0, maxLat: 10.05, minLng: -84.1, maxLng: -84.05 },
      'Sarapiquí': { minLat: 10.3, maxLat: 10.7, minLng: -84.2, maxLng: -83.8 }
    },
    'Guanacaste': {
      'Liberia': { minLat: 10.5, maxLat: 10.7, minLng: -85.5, maxLng: -85.3 },
      'Nicoya': { minLat: 10.0, maxLat: 10.2, minLng: -85.5, maxLng: -85.3 },
      'Santa Cruz': { minLat: 10.2, maxLat: 10.4, minLng: -85.7, maxLng: -85.5 },
      'Bagaces': { minLat: 10.4, maxLat: 10.6, minLng: -85.3, maxLng: -85.1 },
      'Carrillo': { minLat: 10.3, maxLat: 10.5, minLng: -85.6, maxLng: -85.4 },
      'Cañas': { minLat: 10.3, maxLat: 10.5, minLng: -85.1, maxLng: -84.9 },
      'Abangares': { minLat: 10.1, maxLat: 10.3, minLng: -85.0, maxLng: -84.8 },
      'Tilarán': { minLat: 10.3, maxLat: 10.5, minLng: -84.9, maxLng: -84.7 },
      'Nandayure': { minLat: 9.9, maxLat: 10.1, minLng: -85.3, maxLng: -85.1 },
      'La Cruz': { minLat: 10.9, maxLat: 11.1, minLng: -85.7, maxLng: -85.5 },
      'Hojancha': { minLat: 10.0, maxLat: 10.2, minLng: -85.4, maxLng: -85.2 }
    },
    'Puntarenas': {
      'Puntarenas': { minLat: 9.9, maxLat: 10.1, minLng: -84.9, maxLng: -84.7 },
      'Esparza': { minLat: 9.9, maxLat: 10.1, minLng: -84.7, maxLng: -84.5 },
      'Buenos Aires': { minLat: 9.0, maxLat: 9.2, minLng: -83.4, maxLng: -83.2 },
      'Montes de Oro': { minLat: 10.0, maxLat: 10.2, minLng: -84.6, maxLng: -84.4 },
      'Osa': { minLat: 8.8, maxLat: 9.0, minLng: -83.6, maxLng: -83.4 },
      'Quepos': { minLat: 9.4, maxLat: 9.6, minLng: -84.2, maxLng: -84.0 },
      'Golfito': { minLat: 8.6, maxLat: 8.8, minLng: -83.2, maxLng: -83.0 },
      'Coto Brus': { minLat: 8.9, maxLat: 9.1, minLng: -83.1, maxLng: -82.9 },
      'Parrita': { minLat: 9.5, maxLat: 9.7, minLng: -84.4, maxLng: -84.2 },
      'Corredores': { minLat: 8.5, maxLat: 8.7, minLng: -83.0, maxLng: -82.8 },
      'Garabito': { minLat: 9.6, maxLat: 9.8, minLng: -84.7, maxLng: -84.5 }
    },
    'Limón': {
      'Limón': { minLat: 9.9, maxLat: 10.1, minLng: -83.1, maxLng: -82.9 },
      'Pococí': { minLat: 10.3, maxLat: 10.5, minLng: -83.7, maxLng: -83.5 },
      'Siquirres': { minLat: 10.1, maxLat: 10.3, minLng: -83.5, maxLng: -83.3 },
      'Talamanca': { minLat: 9.5, maxLat: 9.7, minLng: -82.9, maxLng: -82.7 },
      'Matina': { minLat: 10.0, maxLat: 10.2, minLng: -83.3, maxLng: -83.1 },
      'Guácimo': { minLat: 10.2, maxLat: 10.4, minLng: -83.7, maxLng: -83.5 }
    }
  };

  const findLocationInCostaRicaData = (location: { lat: number; lng: number }): LocationData => {
    // Definimos límites más precisos para cada provincia
    const provinceBoundaries: { [key: string]: Boundary } = {
      'San José': { minLat: 9.5, maxLat: 10.2, minLng: -84.5, maxLng: -83.5 },
      'Alajuela': { minLat: 10.0, maxLat: 10.9, minLng: -84.5, maxLng: -83.8 },
      'Cartago': { minLat: 9.7, maxLat: 10.1, minLng: -84.0, maxLng: -83.5 },
      'Heredia': { minLat: 10.0, maxLat: 10.5, minLng: -84.2, maxLng: -83.8 },
      'Guanacaste': { minLat: 10.0, maxLat: 11.2, minLng: -85.8, maxLng: -84.8 },
      'Puntarenas': { minLat: 8.4, maxLat: 10.1, minLng: -85.0, maxLng: -83.0 },
      'Limón': { minLat: 9.0, maxLat: 10.3, minLng: -83.5, maxLng: -82.5 }
    };

    // Función para calcular la distancia entre dos puntos (Fórmula de Haversine)
    const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
      const R = 6371; // Radio de la Tierra en km
      const dLat = (lat2 - lat1) * Math.PI / 180;
      const dLon = (lon2 - lon1) * Math.PI / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distancia en km
    };

    // Encontrar la provincia
    let provincia = 'Desconocida';
    for (const [name, bounds] of Object.entries(provinceBoundaries)) {
      if (location.lat >= bounds.minLat && location.lat <= bounds.maxLat &&
        location.lng >= bounds.minLng && location.lng <= bounds.maxLng) {
        provincia = name;
        break;
      }
    }

    // Si no se encontró una provincia, retornar valores por defecto
    if (provincia === 'Desconocida') {
      return { provincia: 'Desconocida', canton: 'Desconocido', distrito: 'Desconocido' };
    }

    // Encontrar el cantón más cercano
    let canton = 'Desconocido';
    let minCantonDistance = Infinity;
    for (const [cantonName, bounds] of Object.entries(cantonBoundaries[provincia] || {})) {
      const cantonLat = (bounds.minLat + bounds.maxLat) / 2;
      const cantonLng = (bounds.minLng + bounds.maxLng) / 2;
      const distance = calculateDistance(location.lat, location.lng, cantonLat, cantonLng);
      if (distance < minCantonDistance) {
        minCantonDistance = distance;
        canton = cantonName;
      }
    }

    // Obtener los distritos del cantón
    const provinciaDistricts = costaRicaData.districts[provincia as keyof typeof costaRicaData.districts];
    const distritos = provinciaDistricts && canton in provinciaDistricts ? provinciaDistricts[canton as keyof typeof provinciaDistricts] : [];

    // Encontrar el distrito más cercano
    let distrito = 'Desconocido';
    let minDistritoDistance = Infinity;
    for (const d of distritos) {
      // Aquí usamos el centro del cantón como aproximación para el distrito
      const cantonBounds = cantonBoundaries[provincia][canton];
      const distritoLat = (cantonBounds.minLat + cantonBounds.maxLat) / 2;
      const distritoLng = (cantonBounds.minLng + cantonBounds.maxLng) / 2;
      const distance = calculateDistance(location.lat, location.lng, distritoLat, distritoLng);
      if (distance < minDistritoDistance) {
        minDistritoDistance = distance;
        distrito = d;
      }
    }

    return { provincia, canton, distrito };
  };

  const validateName = (name: string) => /^[A-Za-z0-9\s]{1,29}$/.test(name); // Letras, números y espacios, máximo 29 caracteres
  const validatePhone = (phone: string) => /^\d{1,13}$/.test(phone); // Solo números, máximo 13 dígitos
  const validateCedula = (cedula: string) => /^\d{1,13}$/.test(cedula); // Solo números, máximo 13 dígitos
  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Validación básica de correo electrónico
  const validateSenasNota = (input: string) => /^.{0,250}$/.test(input); // Permitir cualquier carácter, máximo 250 caracteres

  // Nueva función para manejar la creación del cliente
  const handleCreateClient = (client: Client) => {
    setSelectedClient(client); // Guarda el cliente seleccionado
    setCreateClientDialogOpen(true); // Abre el diálogo de creación
  };

  // Función para crear el cliente en la colección Client usando onAddNew
  const createClient = async (client: Client) => {
    const { id, ...clientData } = client; // Excluye el status_create_public_client
    try {
      await onAddNew(clientData); // Usa onAddNew para crear el cliente
      setCreateClientDialogOpen(false); // Cierra el diálogo
      // Aquí puedes agregar lógica adicional, como mostrar un mensaje de éxito
    } catch (error) {
      console.error("Error al crear el cliente:", error);
      // Maneja el error, por ejemplo, mostrando un mensaje de error
    }
  };

  const isFormValid = () => {
    console.log('Validando formulario público:', currentClient); // Verifica el estado de currentPublicClient
    console.log('Ubicación seleccionada:', isLocationSelected); // Verifica el estado de isLocationSelectedPublic

    return (
      currentClient &&
      currentClient.phoneNumber.trim() !== '' &&
      currentClient.name.trim() !== '' &&
      currentClient.email.trim() !== '' &&
      isLocationSelected
    );
  };

  const handlePublicRequestSort = (property: keyof PublicClient) => { // Nueva función para PublicClient
    const isAsc = publicOrderBy === property && publicOrder === 'asc'; // Verificar si el orden actual es ascendente
    setPublicOrder(isAsc ? 'desc' : 'asc'); // Cambiar la dirección del orden
    setPublicOrderBy(property); // Establecer la propiedad por la que se ordena
  };

  // Función para manejar el cambio de página
  const handlePublicChangePage = (event: unknown, newPage: number) => {
    setPublicPage(newPage); // Actualizar la página actual
  };

  // Función para manejar el cambio de filas por página
  const handlePublicChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublicRowsPerPage(parseInt(event.target.value, 10)); // Actualizar el número de filas por página
    setPublicPage(0); // Reiniciar a la primera página
  };

  const handleRequestSort = (property: keyof Client) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleOpenFilterDialog = () => {
    setIsFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setIsFilterDialogOpen(false);
  };

  const handleOpenColumnSelectionDialog = () => {
    setIsColumnSelectionDialogOpen(true);
  };

  const handleCloseColumnSelectionDialog = () => {
    setIsColumnSelectionDialogOpen(false);
  };

  const handleColumnToggle = (column: keyof Client) => {
    setVisibleColumns(prev => {
      const newSet = new Set(prev);
      if (newSet.has(column)) {
        newSet.delete(column);
      } else {
        newSet.add(column);
      }
      return newSet;
    });
  };

  // Nueva función para manejar el cambio de columnas visibles para PublicClient
  const handlePublicColumnToggle = (column: keyof PublicClient) => {
    setVisiblePublicColumns(prev => {
      const newSet = new Set(prev);
      if (newSet.has(column)) {
        newSet.delete(column);
      } else {
        newSet.add(column);
      }
      return newSet;
    });
  };

  const handleProvinceChange = (province: string) => {
    setCurrentClient(curr => {
      if (!curr) return null;

      const updatedClient = {
        ...curr,
        provincia: province,
        canton: '',
        distrito: '',
        // Borramos la municipalidad si hay un cambio de provincia
        municipalidades: curr.provincia !== province ? '' : curr.municipalidades
      };

      // Actualiza la lista de cantones según la provincia seleccionada
      setCantons(costaRicaData.cantons[province as keyof typeof costaRicaData.cantons] || []);
      setDistricts([]); // Resetea los distritos al cambiar provincia

      return updatedClient;
    });
  };

  const handlePublicProvinceChange = (province: string) => {
    setCurrentPublicClient(curr => {
      if (!curr) return null;

      const updatedClient = {
        ...curr,
        provincia: province,
        canton: '',
        distrito: '',
        // Borramos la municipalidad si hay un cambio de provincia
        municipalidades: curr.provincia !== province ? '' : curr.municipalidades
      };

      // Actualiza la lista de cantones según la provincia seleccionada
      setAvailableCantons(costaRicaData.cantons[province as keyof typeof costaRicaData.cantons] || []);
      setAvailableDistricts([]); // Resetea los distritos al cambiar provincia

      return updatedClient;
    });
  };

  const handleCantonChange = (canton: string) => {
    setCurrentClient(curr => curr ? { ...curr, canton: canton, distrito: '' } : null);

    if (currentClient?.provincia && canton) {
      const provinciaDistricts = costaRicaData.districts[currentClient.provincia as keyof typeof costaRicaData.districts];
      if (provinciaDistricts) {
        setDistricts(provinciaDistricts[canton as keyof typeof provinciaDistricts] || []);
      } else {
        setDistricts([]);
      }
    } else {
      setDistricts([]);
    }
  };

  const handlePublicCantonChange = (canton: string) => {
    setCurrentPublicClient(curr => curr ? { ...curr, canton: canton, distrito: '' } : null);

    if (currentPublicClient?.provincia && canton) {
      const provinciaDistricts = costaRicaData.districts[currentPublicClient.provincia as keyof typeof costaRicaData.districts];
      if (provinciaDistricts) {
        setAvailableDistricts(provinciaDistricts[canton as keyof typeof provinciaDistricts] || []);
      } else {
        setAvailableDistricts([]);
      }
    } else {
      setAvailableDistricts([]);
    }
  };

  const handleQuantityChangePublic = (field: string, increment: boolean) => { // New function for PublicClient
    setCurrentPublicClient(curr => {
      if (!curr) return null;
      const currentValue = parseInt(curr[field as keyof PublicClient] as string) || 0;
      const newValue = increment ? currentValue + 1 : Math.max(0, currentValue - 1);
      return { ...curr, [field]: newValue.toString() };
    });
  };

  const handleImageUploadPublic = (event: React.ChangeEvent<HTMLInputElement>) => { // New function for PublicClient
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentPublicClient(curr => curr ? { ...curr, imageUrl: reader.result as string } : null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleQuantityChange = (field: string, increment: boolean) => {
    setCurrentClient(curr => {
      if (!curr) return null;
      const currentValue = parseInt(curr[field as keyof Client] as string) || 0;
      const newValue = increment ? currentValue + 1 : Math.max(0, currentValue - 1);
      return { ...curr, [field]: newValue.toString() };
    });
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentClient(curr => curr ? { ...curr, imageUrl: reader.result as string } : null);
      };
      reader.readAsDataURL(file);
    }
  };

  const csvData = tableFilteredClients.map(client => {
    const filteredClient: { [K in keyof Client]?: string | undefined } = {};
    Array.from(visibleColumns).forEach(column => {
      filteredClient[column] = client[column];
    });
    return filteredClient;
  });

  const csvDataPublic = publicTableFilteredClients.map(client => {
    const filteredClient: { [K in keyof PublicClient]?: string | undefined } = {};
    Array.from(visiblePublicColumns).forEach(column => {
      filteredClient[column] = client[column];
    });
    return filteredClient;
  });

  const handleMapClick = (event: any) => {
    const { lat, lng } = event.latlng; // Asegúrate de que 'latlng' esté disponible
    const newGoogleMapsLink = `https://www.google.com/maps?q=${lat},${lng}`;
    const newWazeLink = `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;

    setIsLocationSelected(true);
    setSelectedLocation({ lat, lng });
    setGoogleMapsLink(newGoogleMapsLink);
    setWazeLink(newWazeLink);

    // Actualiza los enlaces en el estado del cliente actual
    setCurrentClient(curr => curr ? {
      ...curr,
      googleMapsLink: newGoogleMapsLink,
      wazeLink: newWazeLink
    } : null);
  };

  // const handlePublicMapClick = (event: any) => { // Nueva función para el diálogo de PublicClient
  //   const { lat, lng } = event.latlng; // Asegúrate de que 'latlng' esté disponible
  //   const newGoogleMapsLink = `https://www.google.com/maps?q=${lat},${lng}`;
  //   const newWazeLink = `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;

  //   setIsLocationSelectedPublic(true);
  //   setSelectedLocationPublic({ lat, lng });
  //   setGoogleMapsLink(newGoogleMapsLink);
  //   setWazeLink(newWazeLink);

  //   // Actualiza los enlaces en el estado del cliente actual
  //   setCurrentPublicClient(curr => curr ? {
  //     ...curr,
  //     googleMapsLink: newGoogleMapsLink,
  //     wazeLink: newWazeLink
  //   } : null);
  // };

  const handlePublicMapClick = (event: L.LeafletMouseEvent) => {
    const { lat, lng } = event.latlng; // Asegúrate de que 'latlng' esté disponible
    setNewLocation({ lat, lng }); // Establecer la nueva ubicación
    setIsLocationSelected(true);
    setOpenLocationIcon(true); // Abrir el diálogo de confirmación
  };

  const confirmLocationUpdate = () => {
    if (newLocation) {
      // Lógica para actualizar la ubicación
      const { lat, lng } = newLocation;
      const googleMapsLink = `https://www.google.com/maps?q=${lat},${lng}`;
      const wazeLink = `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;

      setCurrentPublicClient(curr => {
        if (!curr) return null;
        return {
          ...curr,
          googleMapsLink: googleMapsLink,
          wazeLink: wazeLink,
          // Actualizar otros campos según sea necesario
        };
      });

      setSelectedLocationPublic(newLocation); // Actualizar la ubicación seleccionada

      // Play notification sound
      notificationSound.play().catch(error => {
        console.error("Error playing sound:", error);
      });

      // Show notification
      setSuccessMessage("Ubicación actualizada exitosamente!"); // Set success message
    }

    setOpenLocationIcon(false); // Cerrar el diálogo
  };

  const handleUpdatePublicLocation = () => { // Nueva función para actualizar provincia, cantón y distrito
    if (currentPublicClient && currentPublicClient.googleMapsLink) {
      const coordinates = extractCoordinates(currentPublicClient.googleMapsLink);
      if (coordinates) {
        const [lat, lng] = coordinates.split(',').map(Number);
        const location = findLocationInCostaRicaData({ lat, lng });
        setCurrentPublicClient(curr => {
          if (!curr) return null;
          return {
            ...curr,
            provincia: location.provincia,
            canton: location.canton,
            distrito: location.distrito,
            // Mantenemos el valor actual de municipalidades en lugar de actualizarlo
            municipalidades: curr.municipalidades
          };
        });

        // Actualizamos los selectores
        if (location.provincia in costaRicaData.cantons) {
          setAvailableCantons(costaRicaData.cantons[location.provincia as keyof typeof costaRicaData.cantons]);
        }

        if (location.provincia in costaRicaData.districts) {
          const provinciaDistricts = costaRicaData.districts[location.provincia as keyof typeof costaRicaData.districts];
          if (provinciaDistricts && location.canton in provinciaDistricts) {
            setAvailableDistricts(provinciaDistricts[location.canton as keyof typeof provinciaDistricts]);
          }
        }
      }
    }
  };

  const updateLocationFromLink = (link: string) => {
    const regex = /[-]?\d+\.\d+/g; // Expresión regular para encontrar coordenadas
    const matches = link.match(regex);
    if (matches && matches.length >= 2) {
      const lat = parseFloat(matches[0]);
      const lng = parseFloat(matches[1]);
      setSelectedLocation({ lat, lng });

      // Actualiza el estado del cliente actual con las nuevas coordenadas
      setCurrentClient(curr => curr ? { ...curr, googleMapsLink: link } : null);
    }
  };

  function extractLocationFromLink(link: string): [number, number] | null {
    // Expresión regular para encontrar coordenadas en el formato de Google Maps
    const regexGoogleMaps = /q=(-?\d+\.\d+),(-?\d+\.\d+)/; // Para enlaces como "https://www.google.com/maps?q=9.844729968551437,-84.02324949368385"
    const regexAppLink = /@(-?\d+\.\d+),(-?\d+\.\d+)/; // Para enlaces como "https://maps.app.goo.gl/dtt7HkpWPKDeCAVK9"

    let match = link.match(regexGoogleMaps);
    if (match && match.length === 3) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return [latitude, longitude]; // Devuelve las coordenadas como un array
    }

    match = link.match(regexAppLink);
    if (match && match.length === 3) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return [latitude, longitude]; // Devuelve las coordenadas como un array
    }

    return null; // Devuelve null si no se encuentran coordenadas
  }

  async function getCoordinatesFromLink(link: string): Promise<[number, number] | null> {
    // Expresión regular para encontrar coordenadas en el formato de Google Maps
    const regexGoogleMaps = /q=(-?\d+\.\d+),(-?\d+\.\d+)/; // Para enlaces como "https://www.google.com/maps?q=9.844729968551437,-84.02324949368385"
    // const regexAppLink = /@(-?\d+\.\d+),(-?\d+\.\d+)/; // Para enlaces como "https://maps.app.goo.gl/dtt7HkpWPKDeCAVK9"

    let match = link.match(regexGoogleMaps);
    if (match && match.length === 3) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return [latitude, longitude]; // Devuelve las coordenadas como un array
    }

    return null;

    // match = link.match(regexAppLink);
    // if (match && match.length === 3) {
    //   const latitude = parseFloat(match[1]);
    //   const longitude = parseFloat(match[2]);
    //   return [latitude, longitude]; // Devuelve las coordenadas como un array
    // }

    // // Si no se encuentran coordenadas, intenta usar la API de LocationIQ
    // const apiKey = 'pk.0570662f532efe150d66ecdc99576807'; // Reemplaza con tu clave de API de LocationIQ
    // const geocodeUrl = `https://us1.locationiq.com/v1/search.php?key=${apiKey}&q=${encodeURIComponent(link)}&format=json`;

    // try {
    //   const response = await fetch(geocodeUrl);
    //   if (!response.ok) {
    //     throw new Error(`Error en la API: ${response.status} ${response.statusText}`);
    //   }
    //   const data = await response.json();

    //   if (data && data.length > 0) {
    //     const latitude = parseFloat(data[0].lat);
    //     const longitude = parseFloat(data[0].lon);
    //     return [latitude, longitude]; // Devuelve las coordenadas
    //   }
    // } catch (error) {
    //   console.error("No se pudieron obtener las coordenadas:", error);
    // }

    //return null; // Devuelve null si no se encuentran coordenadas





    // // Si no se encuentran coordenadas, intenta usar la API
    // const apiKey = '9417c21aeb1c4e0383d2471240d0282c'; // Reemplaza con tu clave de API de OpenCage
    // const geocodeUrl = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(link)}&key=${apiKey}`;

    // try {
    //   const response = await fetch(geocodeUrl);
    //   if (!response.ok) {
    //     throw new Error(`Error en la API: ${response.status} ${response.statusText}`);
    //   }
    //   const data = await response.json();

    //   if (data.results && data.results.length > 0) {
    //     const location = data.results[0].geometry;
    //     return [location.lat, location.lng]; // Devuelve las coordenadas
    //   }
    // } catch (error) {
    //   console.error("No se pudieron obtener las coordenadas:", error);
    // }

    // return null; // Devuelve null si no se encuentran coordenadas
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        {/* Tabs */}
        <Tabs
          value={newTabValue}
          onChange={(event, newValue) => setNewTabValue(newValue)}
          aria-label="client tabs"
          sx={{
            mb: 2,
            bgcolor: 'background.paper', // Fondo de las pestañas
            borderRadius: 2, // Bordes redondeados
            boxShadow: 1, // Sombra para un efecto elevado
            '& .MuiTab-root': {
              color: '#87CEEB', // Color de texto por defecto
              '&.Mui-selected': {
                color: 'white', // Color de texto cuando está seleccionado
                backgroundColor: '#5CACEE', // Fondo cuando está seleccionado
                borderRadius: 2, // Bordes redondeados para la pestaña seleccionada
              },
              '&:hover': {
                backgroundColor: 'rgba(92, 202, 238, 0.2)', // Efecto hover
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#5CACEE', // Color del indicador
            },
          }}
        >
          <Tab
            label="Clientes Existentes"
            icon={<CodeIcon />}
          />
          <Tab
            label="Clientes Públicos"
            icon={<EditIcon />}
          />
        </Tabs>

        {/* Notification Icon */}
        <IconButton
          onClick={handleClickNotification}
          sx={{ position: 'relative' }} // Cambia a 'relative' para evitar problemas de posicionamiento
        >
          <Badge badgeContent={pendingClients.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>

        {/* Notification Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseNotification}
          PaperProps={{
            sx: {
              maxHeight: '400px',
              overflow: 'auto',
              width: '300px', // Establece un ancho fijo para el menú
            },
          }}
        >
          {pendingClients.map(client => (
            <MenuItem key={client.id} onClick={handleCloseNotification}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Mostrar imágenes de mascotas */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
                  {Array.from({ length: parseInt(client.cant_gato, 10) || 0 }).map((_, index) => (
                    <img
                      key={`cat-${index}`}
                      src={`${process.env.PUBLIC_URL}/images/cant_gato.png`} // Ruta de la imagen del gato
                      alt="Gato"
                      style={{ width: '20px', height: '20px', marginRight: '2px' }} // Ajusta el tamaño y el margen
                    />
                  ))}
                  {Array.from({ length: parseInt(client.cant_perro_mini, 10) || 0 }).map((_, index) => (
                    <img
                      key={`dog-mini-${index}`}
                      src={`${process.env.PUBLIC_URL}/images/cant_perro_mini.png`} // Ruta de la imagen del perro mini
                      alt="Perro Mini"
                      style={{ width: '20px', height: '20px', marginRight: '2px' }} // Ajusta el tamaño y el margen
                    />
                  ))}
                  {Array.from({ length: parseInt(client.cant_perro_pequeno, 10) || 0 }).map((_, index) => (
                    <img
                      key={`dog-small-${index}`}
                      src={`${process.env.PUBLIC_URL}/images/cant_perro_pequeno.png`} // Ruta de la imagen del perro pequeño
                      alt="Perro Pequeño"
                      style={{ width: '20px', height: '20px', marginRight: '2px' }} // Ajusta el tamaño y el margen
                    />
                  ))}
                  {Array.from({ length: parseInt(client.cant_perro_mediano, 10) || 0 }).map((_, index) => (
                    <img
                      key={`dog-medium-${index}`}
                      src={`${process.env.PUBLIC_URL}/images/cant_perro_mediano.png`} // Ruta de la imagen del perro mediano
                      alt="Perro Mediano"
                      style={{ width: '20px', height: '20px', marginRight: '2px' }} // Ajusta el tamaño y el margen
                    />
                  ))}
                  {Array.from({ length: parseInt(client.cant_perro_grande, 10) || 0 }).map((_, index) => (
                    <img
                      key={`dog-large-${index}`}
                      src={`${process.env.PUBLIC_URL}/images/cant_perro_grande.png`} // Ruta de la imagen del perro grande
                      alt="Perro Grande"
                      style={{ width: '20px', height: '20px', marginRight: '2px' }} // Ajusta el tamaño y el margen
                    />
                  ))}
                  {Array.from({ length: parseInt(client.cant_perro_gigante, 10) || 0 }).map((_, index) => (
                    <img
                      key={`dog-giant-${index}`}
                      src={`${process.env.PUBLIC_URL}/images/cant_perro_gigante.png`} // Ruta de la imagen del perro gigante
                      alt="Perro Gigante"
                      style={{ width: '20px', height: '20px', marginRight: '2px' }} // Ajusta el tamaño y el margen
                    />
                  ))}
                </Box>

                {/* Información del cliente */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body1">{client.name}</Typography>
                  <Typography variant="body2" color="textSecondary">{client.provincia}, {client.canton}</Typography>
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {/* Tab Content */}
      {newTabValue === 0 && (
        <div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={() => handleOpen()}
              sx={{
                mb: 3, backgroundColor: '#87CEEB',
                '&:hover': {
                  backgroundColor: 'rgb(135, 206, 235)',
                },
                fontWeight: 'bold'
              }}
            >
              Agregar Cliente
            </Button>
            <FormControlLabel
              control={
                <Switch
                  checked={mainTabValue === 1}
                  onChange={() => setMainTabValue(mainTabValue === 0 ? 1 : 0)}
                  color="primary"
                  sx={{
                    '&.MuiSwitch-root': {
                      '& .MuiSwitch-thumb': {
                        backgroundColor: 'rgb(135, 206, 235) !important',
                      }
                    },
                  }}
                />
              }
              label={
                <span style={{ fontWeight: 'bold' }}>
                  {mainTabValue === 0 ? "Tabla de Clientes" : "Lista de Cartas"}
                </span>
              }
              sx={{ mb: 2, marginBottom: '21px' }}
            />
          </Box>

          {mainTabValue === 0 && (
            // Tabla de Clientes
            <Box>
              {/* Barra de búsqueda para la tabla */}
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Buscar..."
                  value={tableSearchTerm}
                  onChange={(e) => setTableSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                  }}
                  sx={{ alignItems: 'left' }}
                />
                <Box>
                  {/* Botones de acción para la tabla */}
                  <Tooltip title="Filtrar">
                    <IconButton onClick={handleTableOpenFilterDialog}>
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Seleccionar columnas">
                    <IconButton onClick={handleOpenColumnSelectionDialog}>
                      <ViewColumnIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Imprimir">
                    <IconButton onClick={handlePrint}>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                  <CSVLink data={csvData} filename="clientes.csv">
                    <Tooltip title="Descargar CSV">
                      <IconButton>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </CSVLink>
                </Box>
              </Box>

              {/* Chips de filtros seleccionados para la tabla */}
              <Box sx={{ mb: 2 }}>
                {Object.entries(tableSelectedFilters).map(([key, values]) => (
                  values.map(value => (
                    <Chip
                      key={`${key}-${value}`}
                      label={`${columnNames[key as keyof Client]}: ${value}`}
                      onDelete={() => {
                        const newFilters = { ...tableSelectedFilters };
                        newFilters[key] = newFilters[key].filter(v => v !== value);
                        setTableSelectedFilters(newFilters);
                        applyTableFilters();
                      }}
                      sx={{ mr: 1, backgroundColor: '#87CEEB', color: 'white' }}
                    />
                  ))
                ))}
              </Box>

              {/* Tabla de clientes */}
              <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="clients table">
                  <TableHead sx={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      {Array.from(visibleColumns).map((column) => (
                        <TableCell key={column}>
                          <TableSortLabel
                            active={orderBy === column}
                            direction={orderBy === column ? order : 'asc'}
                            onClick={() => handleRequestSort(column)}
                            sx={{ color: 'white', fontWeight: 'bold' }}
                          >
                            {columnNames[column]}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableFilteredClients
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((client) => (
                        <TableRow key={client.id}>
                          {Array.from(visibleColumns).map((column) => (
                            <TableCell key={column}>
                              {column === 'tipo_entrega' ? (
                                client[column] === 'Productos' ? (
                                  <ShoppingCartIcon sx={{ color: 'rgb(71, 219, 226)' }} />
                                ) : (
                                  <RecoleccionIcon sx={{ color: 'green' }} />
                                )
                              ) : (
                                client[column] as string
                              )}
                            </TableCell>
                          ))}
                          <TableCell>
                            <IconButton size="small" onClick={() => handleViewOpen(client)}>
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleOpen(client)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => client.id && handleDeleteClient(client.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tableFilteredClients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
              />
            </Box>
          )}

          {mainTabValue === 1 && (
            // Lista de Cartas
            <Box>
              {/* Barra de búsqueda para la lista de cartas */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Buscar..."
                  value={cardSearchTerm}
                  onChange={(e) => setCardSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                  }}
                  sx={{ alignItems: 'left' }}
                />
                {/* <IconButton onClick={handleCardOpenFilterDialog}>
                  <FilterIcon />
                </IconButton> */}
              </Box>

              {/* 
              <Box sx={{ mb: 2 }}>
                {Object.entries(cardSelectedFilters).map(([key, values]) => (
                  values.map(value => (
                    <Chip
                      key={`${key}-${value}`}
                      label={`${columnNames[key as keyof Client]}: ${value}`}
                      onDelete={() => {
                        const newFilters = { ...cardSelectedFilters };
                        newFilters[key] = newFilters[key].filter(v => v !== value);
                        applyCardFilters();
                        setCardSelectedFilters(newFilters);
                      }}
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))
                ))}
              </Box> */}

              {/* Lista de cartas */}
              <Grid container spacing={3}>
                {cardFilteredClients.map((client) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={client.id}>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 3,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        position: 'relative',
                        transition: 'box-shadow 0.3s',
                        minHeight: '329px', // Establece una altura mínima para todas las cartas
                        '&:hover': {
                          boxShadow: '0px 8px 30px rgba(135, 206, 235, 0.7)', // Sombra más intensa al hacer hover
                        }
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>{client.name}</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <PhoneIcon style={{ color: '#00b4d8', marginRight: '0.5rem' }} />
                        <Typography variant="body2">{client.phoneNumber}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <MailIcon style={{ color: '#00b4d8', marginRight: '0.5rem' }} />
                        <Typography variant="body2">{client.email}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <MapPinIcon style={{ color: '#00b4d8', marginRight: '0.5rem' }} />
                        <Typography variant="body2">{client.provincia}, {client.canton}</Typography>
                      </Box>

                      {/* Mostrar imágenes de mascotas */}
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, flexWrap: 'wrap', border: '1px solid #e0e0e0', borderRadius: 1, p: 1 }}>
                        {Array.from({ length: parseInt(client.cant_gato, 10) || 0 }).map((_, index) => (
                          <img
                            key={`cat-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_gato.png`}
                            alt="Gato"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_mini, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-mini-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_mini.png`}
                            alt="Perro Mini"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_pequeno, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-small-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_pequeno.png`}
                            alt="Perro Pequeño"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_mediano, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-medium-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_mediano.png`}
                            alt="Perro Mediano"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_grande, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-large-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_grande.png`}
                            alt="Perro Grande"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_gigante, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-giant-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_gigante.png`}
                            alt="Perro Gigante"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                      </Box>

                      {/* Botones de acción */}
                      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <IconButton size="small" onClick={() => handleViewOpen(client)} sx={{ color: '#00b4d8' }}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleOpen(client)} sx={{ color: '#00b4d8' }}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {/* Delete Confirmation Dialog */}
          <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}
            fullScreen={isMobile}
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
            PaperProps={{
              sx: {
                borderRadius: '8px',
              },
            }}>
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold'
            }}>
              Confirmar Eliminación
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, paddingTop: '29px !important' }}>
              <Box sx={{
                backgroundColor: 'red',
                borderRadius: '50%',
                padding: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 2
              }}>
                <CloseIcon sx={{ color: 'white', fontSize: 40 }} />
              </Box>
              <Typography variant="h6" align="center" sx={{ color: '#333', fontWeight: 'bold' }}>
                ¿Está seguro de que desea eliminar este cliente?
              </Typography>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                  mr: 2,
                }}
              >
                Cancelar
              </Button>
              <Button onClick={confirmDeleteClient}
                variant="contained"
                sx={{
                  backgroundColor: '#87CEEB',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgb(255, 0, 0)',
                  },
                  '&:disabled': {
                    backgroundColor: 'rgba(255, 0, 0, 0.5)',
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}>
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Card Filter Dialog */}
          <Dialog
            open={cardIsFilterDialogOpen}
            onClose={handleCardCloseFilterDialog}
            maxWidth="md"
            fullWidth
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
              },
            }}
          >
            <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
              Filtros de Lista de Tarjetas
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo Compostera</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['tipo_compostera'] || []}
                      onChange={(e) => {
                        setCardSelectedFilters({
                          ...cardSelectedFilters,
                          tipo_compostera: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo Compostera" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {['S', 'M', 'L', 'XL', 'Recolección'].map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                          <Checkbox checked={(cardSelectedFilters['tipo_compostera'] || []).indexOf(tipo) > -1} />
                          <ListItemText primary={tipo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo Documento</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['tipo_documento'] || []}
                      onChange={(e) => {
                        setCardSelectedFilters({
                          ...cardSelectedFilters,
                          tipo_documento: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo Documento" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {['Nacional', 'Jurídica', 'Dimex', 'Nite'].map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                          <Checkbox checked={(cardSelectedFilters['tipo_documento'] || []).indexOf(tipo) > -1} />
                          <ListItemText primary={tipo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo de Cliente</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['tipo_client'] || []}
                      onChange={(e) => {
                        setCardSelectedFilters({
                          ...cardSelectedFilters,
                          tipo_client: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo de Cliente" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      <MenuItem value="Municipalidad">
                        <Checkbox checked={(cardSelectedFilters['tipo_client'] || []).indexOf('Municipalidad') > -1} />
                        <ListItemText primary="Municipalidad" />
                      </MenuItem>
                      <MenuItem value="Regular">
                        <Checkbox checked={(cardSelectedFilters['tipo_client'] || []).indexOf('Regular') > -1} />
                        <ListItemText primary="Regular" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Provincia</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['provincia'] || []}
                      onChange={(e) => {
                        const selectedProvinces = e.target.value as string[];
                        setCardSelectedFilters({
                          ...cardSelectedFilters,
                          provincia: selectedProvinces,
                          canton: [],
                          distrito: [],
                          municipalidades: selectedProvinces.length === 1 ? [`Municipalidad de ${selectedProvinces[0]}`] : [] // Selecciona la municipalidad correspondiente
                        });
                        if (selectedProvinces.length === 1) {
                          setCantons(costaRicaData.cantons[selectedProvinces[0] as keyof typeof costaRicaData.cantons] || []);
                        } else {
                          setCantons([]);
                        }
                        setDistricts([]);
                      }}
                      input={<OutlinedInput label="Provincia" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {costaRicaData.provinces.map((province) => (
                        <MenuItem key={province} value={province}>
                          <Checkbox checked={(cardSelectedFilters['provincia'] || []).indexOf(province) > -1} />
                          <ListItemText primary={province} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Cantón</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['canton'] || []}
                      onChange={(e) => {
                        const selectedCantons = e.target.value as string[];
                        setCardSelectedFilters({
                          ...cardSelectedFilters,
                          canton: selectedCantons,
                          distrito: []
                        });
                        if (cardSelectedFilters['provincia']?.length === 1 && selectedCantons.length === 1) {
                          const province = cardSelectedFilters['provincia'][0] as keyof typeof costaRicaData.districts;
                          const canton = selectedCantons[0];
                          const provinceDistricts = costaRicaData.districts[province];
                          if (provinceDistricts && canton in provinceDistricts) {
                            setDistricts(provinceDistricts[canton as keyof typeof provinceDistricts]);
                          } else {
                            setDistricts([]);
                          }
                        } else {
                          setDistricts([]);
                        }
                      }}
                      input={<OutlinedInput label="Cantón" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      disabled={cardSelectedFilters['provincia']?.length !== 1}
                    >
                      {cantons.map((canton) => (
                        <MenuItem key={canton} value={canton}>
                          <Checkbox checked={(cardSelectedFilters['canton'] || []).indexOf(canton) > -1} />
                          <ListItemText primary={canton} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Distrito</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['distrito'] || []}
                      onChange={(e) => {
                        setCardSelectedFilters({
                          ...cardSelectedFilters,
                          distrito: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Distrito" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      disabled={cardSelectedFilters['canton']?.length !== 1}
                    >
                      {districts.map((district) => (
                        <MenuItem key={district} value={district}>
                          <Checkbox checked={(cardSelectedFilters['distrito'] || []).indexOf(district) > -1} />
                          <ListItemText primary={district} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button
                onClick={handleCardCloseFilterDialog}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Table Filter Dialog */}
          <Dialog
            open={tableIsFilterDialogOpen}
            onClose={handleTableCloseFilterDialog}
            maxWidth="md"
            fullWidth
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
          >
            <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
              Filtros de Tabla
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo Compostera</InputLabel>
                    <Select
                      multiple
                      value={tableSelectedFilters['tipo_compostera'] || []}
                      onChange={(e) => {
                        setTableSelectedFilters({
                          ...tableSelectedFilters,
                          tipo_compostera: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo Compostera" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {['S', 'M', 'L', 'XL', 'Recolección'].map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                          <Checkbox checked={(tableSelectedFilters['tipo_compostera'] || []).indexOf(tipo) > -1} />
                          <ListItemText primary={tipo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo Documento</InputLabel>
                    <Select
                      multiple
                      value={tableSelectedFilters['tipo_documento'] || []}
                      onChange={(e) => {
                        setTableSelectedFilters({
                          ...tableSelectedFilters,
                          tipo_documento: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo Documento" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {['Nacional', 'Jurídica', 'Dimex', 'Nite'].map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                          <Checkbox checked={(tableSelectedFilters['tipo_documento'] || []).indexOf(tipo) > -1} />
                          <ListItemText primary={tipo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo de Cliente</InputLabel>
                    <Select
                      multiple
                      value={tableSelectedFilters['tipo_client'] || []}
                      onChange={(e) => {
                        setTableSelectedFilters({
                          ...tableSelectedFilters,
                          tipo_client: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo de Cliente" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      <MenuItem value="Municipalidad">
                        <Checkbox checked={(tableSelectedFilters['tipo_client'] || []).indexOf('Municipalidad') > -1} />
                        <ListItemText primary="Municipalidad" />
                      </MenuItem>
                      <MenuItem value="Regular">
                        <Checkbox checked={(tableSelectedFilters['tipo_client'] || []).indexOf('Regular') > -1} />
                        <ListItemText primary="Regular" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" >
                    <InputLabel>Provincia</InputLabel>
                    <Select
                      multiple
                      value={tableSelectedFilters['provincia'] || []}
                      onChange={(e) => {
                        const selectedProvinces = e.target.value as string[];
                        setTableSelectedFilters({
                          ...tableSelectedFilters,
                          provincia: selectedProvinces,
                          canton: [],
                          distrito: [],
                          municipalidades: selectedProvinces.length === 1 ? [`Municipalidad de ${selectedProvinces[0]}`] : [] // Selecciona la municipalidad correspondiente
                        });
                        if (selectedProvinces.length === 1) {
                          setCantons(costaRicaData.cantons[selectedProvinces[0] as keyof typeof costaRicaData.cantons] || []);
                        } else {
                          setCantons([]);
                        }
                        setDistricts([]);
                      }}
                      input={<OutlinedInput label="Provincia" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {costaRicaData.provinces.map((province) => (
                        <MenuItem key={province} value={province}>
                          <Checkbox checked={(tableSelectedFilters['provincia'] || []).indexOf(province) > -1} />
                          <ListItemText primary={province} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Cantón</InputLabel>
                    <Select
                      multiple
                      value={tableSelectedFilters['canton'] || []}
                      onChange={(e) => {
                        const selectedCantons = e.target.value as string[];
                        setTableSelectedFilters({
                          ...tableSelectedFilters,
                          canton: selectedCantons,
                          distrito: []
                        });
                        if (tableSelectedFilters['provincia']?.length === 1 && selectedCantons.length === 1) {
                          const province = tableSelectedFilters['provincia'][0] as keyof typeof costaRicaData.districts;
                          const canton = selectedCantons[0];
                          const provinceDistricts = costaRicaData.districts[province];
                          if (provinceDistricts && canton in provinceDistricts) {
                            setAvailableDistricts(provinceDistricts[canton as keyof typeof provinceDistricts]);
                          } else {
                            setAvailableDistricts([]);
                          }
                        } else {
                          setAvailableDistricts([]);
                        }
                      }}
                      input={<OutlinedInput label="Cantón" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      disabled={tableSelectedFilters['provincia']?.length !== 1}
                    >
                      {availableCantons.map((canton) => (
                        <MenuItem key={canton} value={canton}>
                          <Checkbox checked={(tableSelectedFilters['canton'] || []).indexOf(canton) > -1} />
                          <ListItemText primary={canton} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Distrito</InputLabel>
                    <Select
                      multiple
                      value={tableSelectedFilters['distrito'] || []}
                      onChange={(e) => {
                        setTableSelectedFilters({
                          ...tableSelectedFilters,
                          distrito: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Distrito" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      disabled={tableSelectedFilters['canton']?.length !== 1}
                    >
                      {availableDistricts.map((district) => (
                        <MenuItem key={district} value={district}>
                          <Checkbox checked={(tableSelectedFilters['distrito'] || []).indexOf(district) > -1} />
                          <ListItemText primary={district} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button
                onClick={handleTableCloseFilterDialog}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Filter Dialog */}
          <Dialog
            open={isFilterDialogOpen}
            onClose={handleCloseFilterDialog}
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
              },
            }}

          >
            <DialogTitle>Filtros de Tabla</DialogTitle>
            <DialogContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipo Compostera</InputLabel>
                <Select
                  multiple
                  value={tableTipoComposteraFilter}
                  onChange={(e) => setTableTipoComposteraFilter(e.target.value as string[])}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {['S', 'M', 'L', 'XL', 'Recolección'].map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                      <Checkbox checked={tableTipoComposteraFilter.indexOf(tipo) > -1} />
                      <ListItemText primary={tipo} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipo Documento</InputLabel>
                <Select
                  multiple
                  value={tableTipoDocumentoFilter}
                  onChange={(e) => setTableTipoDocumentoFilter(e.target.value as string[])}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {['Nacional', 'Jurídica', 'Dimex', 'Nite'].map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                      <Checkbox checked={tableTipoDocumentoFilter.indexOf(tipo) > -1} />
                      <ListItemText primary={tipo} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Provincia</InputLabel>
                <Select
                  multiple
                  value={tableProvinciaFilter}
                  onChange={(e) => {
                    setTableProvinciaFilter(e.target.value as string[]);
                    handleProvinceChange(e.target.value as string);
                  }}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {provinces.map((province) => (
                    <MenuItem key={province} value={province}>
                      <Checkbox checked={tableProvinciaFilter.indexOf(province) > -1} />
                      <ListItemText primary={province} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Cantón</InputLabel>
                <Select
                  multiple
                  value={tableCantonFilter}
                  onChange={(e) => {
                    setTableCantonFilter(e.target.value as string[]);
                    handleCantonChange(e.target.value as string);
                  }}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {cantons.map((canton) => (
                    <MenuItem key={canton} value={canton}>
                      <Checkbox checked={tableCantonFilter.indexOf(canton) > -1} />
                      <ListItemText primary={canton} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Distrito</InputLabel>
                <Select
                  multiple
                  value={tableDistritoFilter}
                  onChange={(e) => setTableDistritoFilter(e.target.value as string[])}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {districts.map((district) => (
                    <MenuItem key={district} value={district}>
                      <Checkbox checked={tableDistritoFilter.indexOf(district) > -1} />
                      <ListItemText primary={district} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFilterDialog}>Cancelar</Button>
            </DialogActions>
          </Dialog>

          {/* Column Selection Dialog */}
          <Dialog
            open={isColumnSelectionDialogOpen}
            onClose={handleCloseColumnSelectionDialog}
            PaperProps={{
              sx: {
                width: '400px',
                maxHeight: '80vh',
              }
            }}
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
          >
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold'
            }}>
              Seleccionar Columnas
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2
              }}>
                {columnOrder.map((column) => (
                  <FormControlLabel
                    key={column}
                    control={
                      <Checkbox
                        checked={visibleColumns.has(column)}
                        onChange={() => handleColumnToggle(column)}
                        sx={{
                          color: '#87CEEB',
                          '&.Mui-checked': {
                            color: '#87CEEB',
                          },
                        }}
                      />
                    }
                    label={columnNames[column]}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: 'rgba(135, 206, 235, 0.08)',
                        borderRadius: '4px',
                      },
                    }}
                  />
                ))}
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
              <Button
                onClick={handleCloseColumnSelectionDialog}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Add/Edit Client Dialog */}
          <Dialog
            open={open}
            onClose={handleCloseClient}
            fullScreen={isMobile}
            maxWidth="lg"
            fullWidth
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
            PaperProps={{
              sx: {
                borderRadius: '8px',
              },
            }}
          >
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold'
            }}>
              {currentClient?.id ? 'Editar Cliente' : 'Agregar Nuevo Cliente'}
            </DialogTitle>
            <DialogContent dividers>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                aria-label="client tabs"
                sx={{
                  mb: 2,
                  bgcolor: 'background.paper', // Fondo de las pestañas
                  borderRadius: 2, // Bordes redondeados
                  boxShadow: 1, // Sombra para un efecto elevado
                  '& .MuiTab-root': {
                    color: '#87CEEB', // Color de texto por defecto
                    '&.Mui-selected': {
                      color: 'white', // Color de texto cuando está seleccionado
                      backgroundColor: '#5CACEE', // Fondo cuando está seleccionado
                      borderRadius: 2, // Bordes redondeados para la pestaña seleccionada
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(92, 202, 238, 0.2)', // Efecto hover
                    },
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#5CACEE', // Color del indicador
                  },
                }}
              >
                <Tab label="Datos Personales y Ubicación" />
                <Tab label="Mascotas e Imagen" />
              </Tabs>

              <Box sx={{ height: '608px', overflow: 'auto' }}>
                {tabValue === 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              autoFocus
                              margin="dense"
                              label="Nombre y Apellidos"
                              type="text"
                              fullWidth
                              value={currentClient?.name || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, name: e.target.value } : null)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              margin="dense"
                              label="Teléfono"
                              type="tel"
                              fullWidth
                              value={currentClient?.phoneNumber || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, phoneNumber: e.target.value } : null)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              margin="dense"
                              label="Teléfono Secundario"
                              type="tel"
                              fullWidth
                              value={currentClient?.second_phoneNumber || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, second_phoneNumber: e.target.value } : null)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              margin="dense"
                              label="Correo Electrónico"
                              type="email"
                              fullWidth
                              value={currentClient?.email || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, email: e.target.value } : null)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              margin="dense"
                              label="Cédula Nacional"
                              type="text"
                              fullWidth
                              value={currentClient?.cedula_nacional || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, cedula_nacional: e.target.value } : null)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Tipo de Documento</InputLabel>
                              <Select
                                value={currentClient?.tipo_documento || ''}
                                onChange={(e) => setCurrentClient(curr => curr ? { ...curr, tipo_documento: e.target.value as 'Nacional' | 'Jurídica' | 'Dimex' | 'Nite' } : null)}
                              >
                                <MenuItem value="Nacional">Nacional</MenuItem>
                                <MenuItem value="Jurídica">Jurídica</MenuItem>
                                <MenuItem value="Dimex">Dimex</MenuItem>
                                <MenuItem value="Nite">Nite</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Tipo de Cliente</InputLabel>
                              <Select
                                value={currentClient?.tipo_client || ''}
                                onChange={(e) => {
                                  const newTipoClient = e.target.value as 'Municipalidad' | 'Regular';
                                  setCurrentClient(curr => {
                                    if (curr) {
                                      const updatedClient = { ...curr, tipo_client: newTipoClient };
                                      // Si cambia de Municipalidad a Regular, borramos la municipalidad seleccionada
                                      if (newTipoClient === 'Regular') {
                                        updatedClient.municipalidades = '';
                                      }
                                      return updatedClient;
                                    }
                                    return null;
                                  });
                                }}
                              >
                                <MenuItem value="Municipalidad">Municipalidad</MenuItem>
                                <MenuItem value="Regular">Regular</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Tipo de Compostera</InputLabel>
                              <Select
                                value={currentClient?.tipo_compostera || ''}
                                onChange={(e) => setCurrentClient(curr => curr ? { ...curr, tipo_compostera: e.target.value as 'S' | 'M' | 'L' | 'XL' | 'Recolección' } : null)}
                              >
                                <MenuItem value="S">S</MenuItem>
                                <MenuItem value="M">M</MenuItem>
                                <MenuItem value="L">L</MenuItem>
                                <MenuItem value="XL">XL</MenuItem>
                                <MenuItem value="Recolección">Recolección</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Tipo de Entrega</InputLabel>
                              <Select
                                value={currentClient?.tipo_entrega || ''}
                                onChange={(e) => setCurrentClient(curr => curr ? { ...curr, tipo_entrega: e.target.value as 'Productos' | 'Recolección' } : null)}
                              >
                                <MenuItem value="Productos">
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ShoppingCartIcon sx={{ color: 'rgb(71, 219, 226)', mr: 1 }} />
                                    Productos
                                  </Box>
                                </MenuItem>
                                <MenuItem value="Recolección">
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <RecoleccionIcon sx={{ color: 'green', mr: 1 }} />
                                    Recolección
                                  </Box>
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Provincia</InputLabel>
                              <Select
                                value={currentClient?.provincia || ''}
                                onChange={(e) => handleProvinceChange(e.target.value as string)}
                              >
                                {costaRicaData.provinces.map((province) => (
                                  <MenuItem key={province} value={province}>{province}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Cantón</InputLabel>
                              <Select
                                value={currentClient?.canton || ''}
                                onChange={(e) => {
                                  const selectedCanton = e.target.value as string;
                                  setCurrentClient(curr => curr ? { ...curr, canton: selectedCanton, distrito: '' } : null);
                                  // Actualiza distritos según el cantón seleccionado
                                  setDistricts(costaRicaData.districts[currentClient?.provincia as keyof typeof costaRicaData.districts]?.[selectedCanton as keyof typeof costaRicaData.districts[keyof typeof costaRicaData.districts]] || []);
                                }}
                              >
                                {cantons.map((canton) => (
                                  <MenuItem key={canton} value={canton}>{canton}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Distrito</InputLabel>
                              <Select
                                value={currentClient?.distrito || ''}
                                onChange={(e) => setCurrentClient(curr => curr ? { ...curr, distrito: e.target.value } : null)}
                              >
                                {districts.map((district) => (
                                  <MenuItem key={district} value={district}>{district}</MenuItem>))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              margin="dense"
                              label="Señas"
                              type="text"
                              fullWidth
                              multiline
                              rows={2}
                              value={currentClient?.senas || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, senas: e.target.value } : null)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              margin="dense"
                              label="Nota"
                              type="text"
                              fullWidth
                              multiline
                              rows={2}
                              value={currentClient?.nota || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, nota: e.target.value } : null)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Enlace de Google Maps"
                              value={currentClient?.googleMapsLink || ''}
                              onChange={async (e) => { // Marcar la función como asíncrona
                                const newLink = e.target.value;
                                setCurrentClient(curr => curr ? { ...curr, googleMapsLink: newLink } : null);
                                updateLocationFromLink(newLink); // Actualiza la ubicación en el mapa
                                // Nueva lógica para centrar el mapa en la nueva ubicación
                                const location = await getCoordinatesFromLink(newLink); // Función para obtener las coordenadas del enlace
                                if (location) {
                                  setSelectedLocation({ lat: location[0], lng: location[1] }); // Actualiza la ubicación seleccionada
                                  mapRef.current?.setView({ lat: location[0], lng: location[1] }, 15); // Centra el mapa en la nueva ubicación
                                } else {
                                  console.error('No se pudieron obtener las coordenadas.');
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => window.open(currentClient?.googleMapsLink, '_blank')}
                                      disabled={!currentClient?.googleMapsLink}
                                      edge="end"
                                    >
                                      <MapIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Enlace de Waze"
                              value={currentClient?.wazeLink || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, wazeLink: e.target.value } : null)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => window.open(currentClient?.wazeLink, '_blank')}
                                      disabled={!currentClient?.wazeLink}
                                      edge="end"
                                    >
                                      <WazeIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              margin="dense"
                              label="Nota Admin"
                              type="text"
                              fullWidth
                              multiline
                              rows={2}
                              value={currentClient?.nota_admin || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, nota_admin: e.target.value } : null)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              margin="dense"
                              label="Código de Referencia"
                              type="text"
                              fullWidth
                              value={currentClient?.codigo_referencia || ''}
                              onChange={(e) => setCurrentClient(curr => curr ? { ...curr, codigo_referencia: e.target.value } : null)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box sx={{
                          height: 'calc(83% - 16px)',
                          mb: 2,
                          position: 'relative'
                        }}>
                          <IconButton
                            onClick={handleFullScreen}
                            sx={{
                              position: 'absolute',
                              top: 10,
                              right: 10,
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              zIndex: 1000, // Asegúrate de que el botón esté por encima de otros elementos
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                              },
                            }}
                          >
                            <FullscreenIcon />
                          </IconButton>
                          <MapContainer
                            ref={mapRef}
                            center={selectedLocation || mapCenter}
                            zoom={15}
                            style={{ height: '591px', width: '100%' }}
                            attributionControl={false}
                          >
                            <TileLayer
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {/* Agrega un componente de mapa que maneje el clic */}
                            <MapEventHandler onClick={handleMapClick} />
                            {/* Marcador para la ubicación seleccionada */}
                            {selectedLocation && (
                              <Marker position={selectedLocation} icon={customIcon}>
                                <Popup>
                                  <div>
                                    <strong>Ubicación seleccionada</strong>
                                    <br />
                                    <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">Google Maps</a>
                                    <br />
                                    <a href={wazeLink} target="_blank" rel="noopener noreferrer">Waze</a>
                                  </div>
                                </Popup>
                              </Marker>
                            )}
                          </MapContainer>
                          <Box sx={{
                            position: 'absolute',
                            top: 10,
                            left: 66,
                            backgroundColor: 'rgba(255,255,255,0.8)',
                            padding: '5px 10px',
                            borderRadius: '20px',
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                            zIndex: 1000 // Asegúrate de que este valor sea mayor que el del mapa
                          }}>
                            Selecciona la ubicación del cliente
                          </Box>
                          <IconButton
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={handleUpdateLocation}
                            sx={{
                              position: 'absolute',
                              bottom: 181,
                              left: 10,
                              backgroundColor: 'orange',
                              color: 'white',
                              animation: `${rotateAnimation} 4s linear infinite`,
                              '&:hover': {
                                backgroundColor: 'darkorange',
                                animation: 'none',
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: '2rem',
                              },
                              zIndex: 1000 // Asegúrate de que este valor sea mayor que el del mapa
                            }}
                          >
                            {isHovered ? <RefreshIcon /> : <UpdateIcon />}
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {tabValue === 1 && (
                  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row' }}> {/* Cambiado a 'row' para alinear horizontalmente */}
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', paddingRight: 2, mr: 2 }}> {/* Añadido margen derecho */}
                      <Grid container spacing={2} sx={{ mb: 2, flex: 1, mt: 2, ml: 2 }}> {/* Añadido margin top y left */}
                        {['cant_gato', 'cant_perro_mini', 'cant_perro_pequeno', 'cant_perro_mediano', 'cant_perro_grande', 'cant_perro_gigante'].map((field) => (
                          <Grid item xs={12} sm={6} md={4} key={field}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{
                                border: '2px solid #87CEEB', // Borde alrededor del Box
                                borderRadius: 2, // Bordes redondeados
                                transition: 'transform 0.3s ease', // Transición para el hover
                                '&:hover': {
                                  transform: 'scale(1.02)', // Escalar ligeramente al pasar el mouse
                                },
                                padding: 1, // Espaciado interno
                              }}
                            >
                              <Box display="flex" flexDirection="column" alignItems="center">
                                <Box sx={{ textAlign: 'center', mb: 1 }}> {/* Ajustar margen inferior */}
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/${field}.png`}
                                    alt={columnNames[field as keyof Client]}
                                    style={{ width: '70px', height: '70px' }} // Aumentar tamaño de la imagen
                                  />
                                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{columnNames[field as keyof Client]?.split(' ')[0]}</Typography>
                                  <Typography variant="body2">{columnNames[field as keyof Client]?.split(' ')[1]}</Typography>
                                </Box>
                              </Box>
                              <Box display="flex" flexDirection="column" alignItems="center" sx={{ ml: 1 }}> {/* Ajustar margen a la izquierda */}
                                <Typography variant="body2" sx={{ mb: 1 }}>Cantidad:</Typography> {/* Texto pequeño arriba */}
                                <Box display="flex" alignItems="center">
                                  <IconButton
                                    onClick={() => handleQuantityChange(field, false)}
                                    sx={{ color: '#87CEEB' }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                  <TextField
                                    value={currentClient?.[field as keyof Client] || '0'}
                                    onChange={(e) => setCurrentClient(curr => curr ? { ...curr, [field]: e.target.value } : null)}
                                    inputProps={{
                                      style: { textAlign: 'center' },
                                      min: 0,
                                    }}
                                    sx={{
                                      width: '50px',
                                      '& .MuiInputBase-input': {
                                        padding: '5px',
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                          borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: 'transparent',
                                        },
                                      },
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => handleQuantityChange(field, true)}
                                    sx={{ color: '#87CEEB' }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        ))}

                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        width: '35%', // Ajustado el tamaño del Box
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: 2,
                      }}
                    >
                      <Typography variant="h6" sx={{ mb: 2 }}>Imagen del cliente</Typography>
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dashed #87CEEB', borderRadius: 2, mb: 2 }}>
                        {currentClient?.imageUrl ? (
                          <img
                            src={currentClient.imageUrl}
                            alt="Client"
                            style={{
                              maxWidth: '100%',
                              maxHeight: '200px',
                              transition: 'transform 0.3s ease', // Transición para la imagen
                            }}
                            className="image-hover" // Clase para la animación
                          />
                        ) : (
                          <Typography variant="body1" color="textSecondary">Vista previa de la imagen</Typography>
                        )}
                      </Box>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          fullWidth
                          sx={{
                            backgroundColor: '#87CEEB',
                            '&:hover': {
                              backgroundColor: '#5CACEE',
                            },
                          }}
                        >
                          Subir Imagen
                        </Button>
                      </label>
                    </Box>
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button
                onClick={handleClose}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                  mr: 2,
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                disabled={!isFormValid()}
                sx={{
                  backgroundColor: '#87CEEB',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#5CACEE',
                  },
                  '&:disabled': {
                    backgroundColor: 'rgba(135, 206, 235, 0.5)',
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Guardar
              </Button>
            </DialogActions>
          </Dialog>

          {/* View Client Dialog */}
          <Dialog
            open={viewOpen}
            onClose={handleViewClose}
            fullScreen={isMobile}
            maxWidth="lg"
            fullWidth
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
            PaperProps={{
              sx: {
                borderRadius: '8px',
                height: 'calc(100% - 64px)',
                maxHeight: 'none',
              },
            }}
          >
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              py: 2,
            }}>
              Detalles del Cliente: {currentClient?.name}
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 0 }}>
              <Box sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ width: '30%', p: 3, borderRight: '1px solid #e0e0e0' }}>
                  <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Información Personal</Typography>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Teléfono</Typography>
                    <Typography>{currentClient?.phoneNumber}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Email</Typography>
                    <Typography>{currentClient?.email}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Cédula Nacional</Typography>
                    <Typography>{currentClient?.cedula_nacional}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Tipo de Documento</Typography>
                    <Typography>{currentClient?.tipo_documento}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Tipo de Cliente</Typography>
                    <Typography>{currentClient?.tipo_client}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Tipo de Compostera</Typography>
                    <Typography>{currentClient?.tipo_compostera}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">Tipo de Entrega</Typography>
                    {currentClient?.tipo_entrega === 'Productos' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ShoppingCartIcon sx={{ color: 'rgb(71, 219, 226)', mr: 1 }} />
                        <Typography>Productos</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <RecoleccionIcon sx={{ color: 'green', mr: 1 }} />
                        <Typography>Recolección</Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ width: '40%', p: 3, borderRight: '1px solid #e0e0e0' }}>
                  <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Ubicación</Typography>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Provincia</Typography>
                    <Typography>{currentClient?.provincia}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Cantón</Typography>
                    <Typography>{currentClient?.canton}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Distrito</Typography>
                    <Typography>{currentClient?.distrito}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Municipalidad</Typography>
                    <Typography>{currentClient?.municipalidades}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Dirección y señas para llegar</Typography>
                    <Typography>{currentClient?.senas}</Typography>
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" color="textSecondary">Nota del cliente</Typography>
                    <Typography>{currentClient?.nota}</Typography>
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" color="textSecondary">Nota Admin</Typography> {/* Nuevo campo */}
                    <Typography>{currentClient?.nota_admin}</Typography> {/* Muestra el valor de nota_admin */}
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" color="textSecondary">Código de Referencia</Typography>
                    <Typography>{currentClient?.codigo_referencia}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="contained"
                      startIcon={<MapIcon />}
                      onClick={() => window.open(currentClient?.googleMapsLink, '_blank')}
                      disabled={!currentClient?.googleMapsLink}
                      sx={{
                        backgroundColor: '#4285F4',
                        '&:hover': { backgroundColor: '#3367D6' },
                        flex: 1,
                      }}
                    >
                      Google Maps
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<WazeIcon />}
                      onClick={() => window.open(currentClient?.wazeLink, '_blank')}
                      disabled={!currentClient?.wazeLink}
                      sx={{
                        backgroundColor: '#33CCFF',
                        '&:hover': { backgroundColor: '#28A8E0' },
                        flex: 1,
                      }}
                    >
                      Waze
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ width: '30%', p: 3, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Imagen del cliente</Typography>
                  <Box sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px dashed #87CEEB',
                    borderRadius: 2,
                    mb: 3,
                  }}>
                    {currentClient?.imageUrl ? (
                      <img src={currentClient.imageUrl} alt="Client" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                    ) : (
                      <Typography variant="body1" color="textSecondary">Vista previa de la imagen</Typography>
                    )}
                  </Box>
                  <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Mascotas</Typography>
                  <Grid container spacing={2}>
                    {['cant_gato', 'cant_perro_mini', 'cant_perro_pequeno', 'cant_perro_mediano', 'cant_perro_grande', 'cant_perro_gigante']
                      .filter(field => parseInt(currentClient?.[field as keyof Client] as string, 10) > 0)
                      .map((field) => (
                        <Grid item xs={6} key={field}>
                          <Box display="flex" alignItems="center">
                            <img
                              src={`${process.env.PUBLIC_URL}/images/${field}.png`}
                              alt={columnNames[field as keyof Client]}
                              style={{ width: '40px', height: '40px', marginRight: '8px' }}
                            />
                            <Typography variant="body2">{columnNames[field as keyof Client]}: {currentClient?.[field as keyof Client]}</Typography>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
              <Button
                onClick={handleViewClose}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {/* Public Client Dialog */}
      {newTabValue === 1 && (
        <div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={() => handlePublicOpen()}
              sx={{
                mb: 3, backgroundColor: '#87CEEB',
                '&:hover': {
                  backgroundColor: 'rgb(135, 206, 235)',
                },
                fontWeight: 'bold'
              }}
            >
              Agregar Cliente Público
            </Button>
            <FormControlLabel
              control={
                <Switch
                  checked={mainTabValue === 1}
                  onChange={() => setMainTabValue(mainTabValue === 0 ? 1 : 0)}
                  color="primary"
                  sx={{
                    '&.MuiSwitch-root': {
                      '& .MuiSwitch-thumb': {
                        backgroundColor: 'rgb(135, 206, 235) !important',
                      }
                    },
                  }}
                />
              }
              label={
                <span style={{ fontWeight: 'bold' }}>
                  {mainTabValue === 0 ? "Tabla de Clientes" : "Lista de Cartas"}
                </span>
              }
              sx={{ mb: 2, marginBottom: '21px' }}
            />
          </Box>

          {mainTabValue === 0 && (
            // Tabla de Clientes
            <Box>
              {/* Barra de búsqueda para la tabla */}
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Buscar..."
                  value={tableSearchTermPublic}
                  onChange={(e) => setTableSearchTermPublic(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                  }}
                  sx={{ alignItems: 'left' }}
                />
                <Box>
                  {/* Botones de acción para la tabla */}
                  <Tooltip title="Filtrar">
                    <IconButton onClick={handleTableOpenFilterDialogPublic}>
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Seleccionar columnas">
                    <IconButton onClick={handleOpenColumnSelectionDialog}>
                      <ViewColumnIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Imprimir">
                    <IconButton onClick={handlePrint}>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                  <CSVLink data={csvDataPublic} filename="clientes.csv">
                    <Tooltip title="Descargar CSV">
                      <IconButton>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </CSVLink>
                </Box>
              </Box>

              {/* Chips de filtros seleccionados para la tabla */}
              <Box sx={{ mb: 2 }}>
                {Object.entries(publicTableSelectedFilters).map(([key, values]) => (
                  values.map(value => (
                    <Chip
                      key={`${key}-${value}`}
                      label={`${publicColumnNames[key as keyof PublicClient]}: ${value}`}
                      onDelete={() => {
                        const newFilters = { ...publicTableSelectedFilters }; // Asegúrate de usar publicTableSelectedFilters
                        newFilters[key] = newFilters[key].filter(v => v !== value); // Filtra el valor a eliminar
                        setPublicTableSelectedFilters(newFilters); // Actualiza el estado con los nuevos filtros
                        applyPublicFilters(); // Aplica los filtros actualizados
                      }}
                      sx={{ mr: 1, backgroundColor: '#87CEEB', color: 'white' }}
                    />
                  ))
                ))}
              </Box>

              {/* Tabla de clientes */}
              <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="clients table">
                  <TableHead sx={{ backgroundColor: '#87CEEB' }}>
                    <TableRow>
                      {Array.from(visiblePublicColumns).map((column) => (
                        <TableCell key={column}>
                          <TableSortLabel
                            active={orderBy === column}
                            direction={orderBy === column ? order : 'asc'}
                            onClick={() => handlePublicRequestSort(column)}
                            sx={{ color: 'white', fontWeight: 'bold' }}
                          >
                            {publicColumnNames[column]}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {publicTableFilteredClients
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((client) => (
                        <TableRow key={client.id}>
                          {Array.from(visiblePublicColumns).map((column) => (
                            <TableCell key={column}>
                              {column === 'tipo_entrega' ? (
                                client[column] === 'Productos' ? (
                                  <ShoppingCartIcon sx={{ color: 'rgb(71, 219, 226)' }} />
                                ) : (
                                  <RecoleccionIcon sx={{ color: 'green' }} />
                                )
                              ) : column === 'status_create_public_client' ? (
                                <>
                                  {client[column] === 'pending' ? (
                                    <AccessTimeIcon sx={{ color: '#87CEEB', marginRight: 1, marginBottom: '-7px' }} />
                                  ) : client[column] === 'completed' ? (
                                    <CheckCircleIcon sx={{ color: 'green', marginRight: 1, marginBottom: '-7px' }} />
                                  ) : (
                                    <CancelIcon sx={{ color: 'red', marginRight: 1, marginBottom: '-7px' }} />
                                  )}
                                  {client[column] === 'pending' ? 'Pendiente' : client[column] === 'completed' ? 'Completado' : 'Cancelado'}
                                </>
                              ) : (
                                client[column] as string
                              )}
                            </TableCell>
                          ))}
                          <TableCell>
                            <IconButton size="small" onClick={() => handleViewOpenPublic(client)}>
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handlePublicOpen(client)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => client.id && handleDeletePublicClient(client.id)}>
                              <DeleteIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleCreateClient({ ...client, nota_admin: '' })}>
                              <TransferIcon /> {/* Asegúrate de importar el icono adecuado */}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={publicTableFilteredClients.length}
                rowsPerPage={publicRowsPerPage}
                page={publicPage}
                onPageChange={handlePublicChangePage}
                onRowsPerPageChange={handlePublicChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
              />
            </Box>
          )}

          {mainTabValue === 1 && (
            // Lista de Cartas
            <Box>
              {/* Barra de búsqueda para la lista de cartas */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Buscar..."
                  value={cardSearchTermPublic}
                  onChange={(e) => setCardSearchTermPublic(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                  }}
                  sx={{ alignItems: 'left' }}
                />
              </Box>

              {/* Lista de cartas */}
              <Grid container spacing={3}>
                {cardFilteredClientsPublic.map((client) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={client.id}>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 3,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        position: 'relative',
                        transition: 'box-shadow 0.3s',
                        minHeight: '329px', // Establece una altura mínima para todas las cartas
                        '&:hover': {
                          boxShadow: '0px 8px 30px rgba(135, 206, 235, 0.7)', // Sombra más intensa al hacer hover
                        }
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>{client.name}</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <PhoneIcon style={{ color: '#00b4d8', marginRight: '0.5rem' }} />
                        <Typography variant="body2">{client.phoneNumber}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <MailIcon style={{ color: '#00b4d8', marginRight: '0.5rem' }} />
                        <Typography variant="body2">{client.email}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <MapPinIcon style={{ color: '#00b4d8', marginRight: '0.5rem' }} />
                        <Typography variant="body2">{client.provincia}, {client.canton}</Typography>
                      </Box>

                      {/* Mostrar imágenes de mascotas */}
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, flexWrap: 'wrap', border: '1px solid #e0e0e0', borderRadius: 1, p: 1 }}>
                        {Array.from({ length: parseInt(client.cant_gato, 10) || 0 }).map((_, index) => (
                          <img
                            key={`cat-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_gato.png`}
                            alt="Gato"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_mini, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-mini-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_mini.png`}
                            alt="Perro Mini"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_pequeno, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-small-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_pequeno.png`}
                            alt="Perro Pequeño"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_mediano, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-medium-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_mediano.png`}
                            alt="Perro Mediano"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_grande, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-large-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_grande.png`}
                            alt="Perro Grande"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                        {Array.from({ length: parseInt(client.cant_perro_gigante, 10) || 0 }).map((_, index) => (
                          <img
                            key={`dog-giant-${index}`}
                            src={`${process.env.PUBLIC_URL}/images/cant_perro_gigante.png`}
                            alt="Perro Gigante"
                            style={{ width: '30px', height: '30px', marginRight: '2px' }}
                          />
                        ))}
                      </Box>

                      {/* Botones de acción */}
                      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <IconButton size="small" onClick={() => handleViewOpenPublic(client)} sx={{ color: '#00b4d8' }}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => handlePublicOpen(client)} sx={{ color: '#00b4d8' }}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {/* Dialog para confirmar la creación del cliente */}
          <Dialog
            open={createClientDialogOpen}
            onClose={() => setCreateClientDialogOpen(false)}
            fullWidth
            maxWidth="sm"
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
            PaperProps={{
              sx: {
                borderRadius: '8px',
              },
            }}
          >
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold'
            }}>
              Confirmar Creación de Cliente
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, paddingTop: '29px !important' }}>
              <Box sx={{
                backgroundColor: 'green',
                borderRadius: '50%',
                padding: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 2
              }}>
                <CreateIcon sx={{ color: 'white', fontSize: 40 }} />
              </Box>
              <Typography variant="h6" align="center" sx={{ color: '#333', fontWeight: 'bold' }}>
                ¿Está seguro de que desea crear este cliente como definitivo?
              </Typography>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button onClick={() => setCreateClientDialogOpen(false)}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                  mr: 2,
                }}
              >
                Cancelar
              </Button>
              <Button onClick={() => {
                if (selectedClient) {
                  createClient(selectedClient); // Llama a la función para crear el cliente
                  const updatedClient: PublicClient = {
                    ...selectedClient,
                    status_create_public_client: 'completed', // Actualiza el estado
                  };
                  onUpdatePublicClient(updatedClient); // Pasa el cliente actualizado

                  // Play notification sound
                  notificationSound.play().catch(error => {
                    console.error("Error playing sound:", error);
                  });

                  // Cierra el diálogo y muestra el mensaje de éxito
                  setSuccessMessage("Cliente creado exitosamente!"); // Set success message
                  setTimeout(() => {
                    window.location.reload(); // Reload the page after a delay
                  }, 2000); // Ajusta el retraso según sea necesario
                }
              }} // Llama a la función para crear el cliente y actualizar el estado
                variant="contained"
                sx={{
                  backgroundColor: '#87CEEB',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}>
                Crear Cliente
              </Button>
            </DialogActions>
          </Dialog>

          {/* Delete Confirmation Dialog */}
          <Dialog open={deleteDialogOpenPublic} onClose={() => setDeleteDialogOpenPublic(false)}
            fullScreen={isMobile}
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
            PaperProps={{
              sx: {
                borderRadius: '8px',
              },
            }}>
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold'
            }}>
              Confirmar Eliminación</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, paddingTop: '29px !important' }}>
              <Box sx={{
                backgroundColor: 'red',
                borderRadius: '50%',
                padding: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 2
              }}>
                <CloseIcon sx={{ color: 'white', fontSize: 40 }} />
              </Box>
              <Typography variant="h6" align="center" sx={{ color: '#333', fontWeight: 'bold' }}>
                ¿Está seguro de que desea eliminar este cliente público?
              </Typography>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button onClick={() => setDeleteDialogOpenPublic(false)}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                  mr: 2,
                }}
              >
                Cancelar
              </Button>
              <Button onClick={confirmDeletePublicClient}
                variant="contained"
                sx={{
                  backgroundColor: '#87CEEB',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgb(255, 0, 0)',
                  },
                  '&:disabled': {
                    backgroundColor: 'rgba(255, 0, 0, 0.5)',
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}>
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Card Filter Dialog */}
          <Dialog
            open={cardIsFilterDialogOpenPublic}
            onClose={handleCardCloseFilterDialogPublic}
            maxWidth="md"
            fullWidth
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
              },
            }}
          >
            <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
              Filtros de Lista de Tarjetas
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo Compostera</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['tipo_compostera'] || []}
                      onChange={(e) => {
                        setCardSelectedFiltersPublic({
                          ...cardSelectedFiltersPublic,
                          tipo_compostera: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo Compostera" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {['S', 'M', 'L', 'XL', 'Recolección'].map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                          <Checkbox checked={(cardSelectedFiltersPublic['tipo_compostera'] || []).indexOf(tipo) > -1} />
                          <ListItemText primary={tipo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo Documento</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['tipo_documento'] || []}
                      onChange={(e) => {
                        setCardSelectedFiltersPublic({
                          ...cardSelectedFiltersPublic,
                          tipo_documento: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo Documento" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {['Nacional', 'Jurídica', 'Dimex', 'Nite'].map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                          <Checkbox checked={(cardSelectedFiltersPublic['tipo_documento'] || []).indexOf(tipo) > -1} />
                          <ListItemText primary={tipo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo de Cliente</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['tipo_client'] || []}
                      onChange={(e) => {
                        setCardSelectedFilters({
                          ...cardSelectedFiltersPublic,
                          tipo_client: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo de Cliente" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      <MenuItem value="Municipalidad">
                        <Checkbox checked={(cardSelectedFiltersPublic['tipo_client'] || []).indexOf('Municipalidad') > -1} />
                        <ListItemText primary="Municipalidad" />
                      </MenuItem>
                      <MenuItem value="Regular">
                        <Checkbox checked={(cardSelectedFiltersPublic['tipo_client'] || []).indexOf('Regular') > -1} />
                        <ListItemText primary="Regular" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Provincia</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['provincia'] || []}
                      onChange={(e) => {
                        const selectedProvinces = e.target.value as string[];
                        setCardSelectedFiltersPublic({
                          ...cardSelectedFiltersPublic,
                          provincia: selectedProvinces,
                          canton: [],
                          distrito: [],
                          municipalidades: selectedProvinces.length === 1 ? [`Municipalidad de ${selectedProvinces[0]}`] : [] // Selecciona la municipalidad correspondiente
                        });
                        if (selectedProvinces.length === 1) {
                          setAvailableCantons(costaRicaData.cantons[selectedProvinces[0] as keyof typeof costaRicaData.cantons] || []);
                        } else {
                          setAvailableCantons([]);
                        }
                        setAvailableDistricts([]);
                      }}
                      input={<OutlinedInput label="Provincia" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {costaRicaData.provinces.map((province) => (
                        <MenuItem key={province} value={province}>
                          <Checkbox checked={(cardSelectedFiltersPublic['provincia'] || []).indexOf(province) > -1} />
                          <ListItemText primary={province} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Cantón</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['canton'] || []}
                      onChange={(e) => {
                        const selectedCantons = e.target.value as string[];
                        setCardSelectedFiltersPublic({
                          ...cardSelectedFiltersPublic,
                          canton: selectedCantons,
                          distrito: []
                        });
                        if (cardSelectedFilters['provincia']?.length === 1 && selectedCantons.length === 1) {
                          const province = cardSelectedFilters['provincia'][0] as keyof typeof costaRicaData.districts;
                          const canton = selectedCantons[0];
                          const provinceDistricts = costaRicaData.districts[province];
                          if (provinceDistricts && canton in provinceDistricts) {
                            setAvailableDistricts(provinceDistricts[canton as keyof typeof provinceDistricts]);
                          } else {
                            setAvailableDistricts([]);
                          }
                        } else {
                          setAvailableDistricts([]);
                        }
                      }}
                      input={<OutlinedInput label="Cantón" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      disabled={cardSelectedFilters['provincia']?.length !== 1}
                    >
                      {cantons.map((canton) => (
                        <MenuItem key={canton} value={canton}>
                          <Checkbox checked={(cardSelectedFiltersPublic['canton'] || []).indexOf(canton) > -1} />
                          <ListItemText primary={canton} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Distrito</InputLabel>
                    <Select
                      multiple
                      value={cardSelectedFilters['distrito'] || []}
                      onChange={(e) => {
                        setCardSelectedFiltersPublic({
                          ...cardSelectedFiltersPublic,
                          distrito: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Distrito" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      disabled={cardSelectedFilters['canton']?.length !== 1}
                    >
                      {availableDistricts.map((district) => (
                        <MenuItem key={district} value={district}>
                          <Checkbox checked={(cardSelectedFiltersPublic['distrito'] || []).indexOf(district) > -1} />
                          <ListItemText primary={district} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button
                onClick={handleCardCloseFilterDialogPublic}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Table Filter Dialog */}
          <Dialog
            open={tableIsFilterDialogOpenPublic}
            onClose={handleTableCloseFilterDialogPublic}
            maxWidth="md"
            fullWidth
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
          >
            <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
              Filtros de Tabla
            </DialogTitle>
            <DialogContent sx={{ pt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo Compostera</InputLabel>
                    <Select
                      multiple
                      value={publicTableSelectedFilters['tipo_compostera'] || []}
                      onChange={(e) => {
                        setPublicTableSelectedFilters({
                          ...publicTableSelectedFilters,
                          tipo_compostera: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo Compostera" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {['S', 'M', 'L', 'XL', 'Recolección'].map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                          <Checkbox checked={(publicTableSelectedFilters['tipo_compostera'] || []).indexOf(tipo) > -1} />
                          <ListItemText primary={tipo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo Documento</InputLabel>
                    <Select
                      multiple
                      value={publicTableSelectedFilters['tipo_documento'] || []}
                      onChange={(e) => {
                        setPublicTableSelectedFilters({
                          ...publicTableSelectedFilters,
                          tipo_documento: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo Documento" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {['Nacional', 'Jurídica', 'Dimex', 'Nite'].map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                          <Checkbox checked={(publicTableSelectedFilters['tipo_documento'] || []).indexOf(tipo) > -1} />
                          <ListItemText primary={tipo} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel>Tipo de Cliente</InputLabel>
                    <Select
                      multiple
                      value={publicTableSelectedFilters['tipo_client'] || []}
                      onChange={(e) => {
                        setPublicTableSelectedFilters({
                          ...publicTableSelectedFilters,
                          tipo_client: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Tipo de Cliente" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      <MenuItem value="Municipalidad">
                        <Checkbox checked={(publicTableSelectedFilters['tipo_client'] || []).indexOf('Municipalidad') > -1} />
                        <ListItemText primary="Municipalidad" />
                      </MenuItem>
                      <MenuItem value="Regular">
                        <Checkbox checked={(publicTableSelectedFilters['tipo_client'] || []).indexOf('Regular') > -1} />
                        <ListItemText primary="Regular" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" >
                    <InputLabel>Provincia</InputLabel>
                    <Select
                      multiple
                      value={publicTableSelectedFilters['provincia'] || []}
                      onChange={(e) => {
                        const selectedProvinces = e.target.value as string[];
                        setPublicTableSelectedFilters({
                          ...publicTableSelectedFilters,
                          provincia: selectedProvinces,
                          canton: [],
                          distrito: [],
                          municipalidades: selectedProvinces.length === 1 ? [`Municipalidad de ${selectedProvinces[0]}`] : [] // Selecciona la municipalidad correspondiente
                        });
                        if (selectedProvinces.length === 1) {
                          setAvailableCantons(costaRicaData.cantons[selectedProvinces[0] as keyof typeof costaRicaData.cantons] || []);
                        } else {
                          setAvailableCantons([]);
                        }
                        setAvailableDistricts([]);
                      }}
                      input={<OutlinedInput label="Provincia" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {costaRicaData.provinces.map((province) => (
                        <MenuItem key={province} value={province}>
                          <Checkbox checked={(publicTableSelectedFilters['provincia'] || []).indexOf(province) > -1} />
                          <ListItemText primary={province} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Cantón</InputLabel>
                    <Select
                      multiple
                      value={publicTableSelectedFilters['canton'] || []}
                      onChange={(e) => {
                        const selectedCantons = e.target.value as string[];
                        setPublicTableSelectedFilters({
                          ...publicTableSelectedFilters,
                          canton: selectedCantons,
                          distrito: []
                        });
                        if (publicTableSelectedFilters['provincia']?.length === 1 && selectedCantons.length === 1) {
                          const province = publicTableSelectedFilters['provincia'][0] as keyof typeof costaRicaData.districts;
                          const canton = selectedCantons[0];
                          const provinceDistricts = costaRicaData.districts[province];
                          if (provinceDistricts && canton in provinceDistricts) {
                            setAvailableDistricts(provinceDistricts[canton as keyof typeof provinceDistricts]);
                          } else {
                            setAvailableDistricts([]);
                          }
                        } else {
                          setAvailableDistricts([]);
                        }
                      }}
                      input={<OutlinedInput label="Cantón" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      disabled={publicTableSelectedFilters['provincia']?.length !== 1}
                    >
                      {availableCantons.map((canton) => (
                        <MenuItem key={canton} value={canton}>
                          <Checkbox checked={(publicTableSelectedFilters['canton'] || []).indexOf(canton) > -1} />
                          <ListItemText primary={canton} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Distrito</InputLabel>
                    <Select
                      multiple
                      value={publicTableSelectedFilters['distrito'] || []}
                      onChange={(e) => {
                        setPublicTableSelectedFilters({
                          ...publicTableSelectedFilters,
                          distrito: e.target.value as string[]
                        });
                      }}
                      input={<OutlinedInput label="Distrito" />}
                      renderValue={(selected) => (selected as string[]).join(', ')}
                      disabled={publicTableSelectedFilters['canton']?.length !== 1}
                    >
                      {availableDistricts.map((district) => (
                        <MenuItem key={district} value={district}>
                          <Checkbox checked={(publicTableSelectedFilters['distrito'] || []).indexOf(district) > -1} />
                          <ListItemText primary={district} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button
                onClick={handleTableCloseFilterDialogPublic}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Filter Dialog */}
          <Dialog
            open={isFilterDialogOpen}
            onClose={handleCloseFilterDialog}
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(135, 206, 235, 0.1)',
              },
            }}

          >
            <DialogTitle>Filtros de Tabla</DialogTitle>
            <DialogContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipo Compostera</InputLabel>
                <Select
                  multiple
                  value={tableTipoComposteraFilter}
                  onChange={(e) => setTableTipoComposteraFilter(e.target.value as string[])}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {['S', 'M', 'L', 'XL', 'Recolección'].map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                      <Checkbox checked={tableTipoComposteraFilter.indexOf(tipo) > -1} />
                      <ListItemText primary={tipo} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipo Documento</InputLabel>
                <Select
                  multiple
                  value={tableTipoDocumentoFilter}
                  onChange={(e) => setTableTipoDocumentoFilter(e.target.value as string[])}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {['Nacional', 'Jurídica', 'Dimex', 'Nite'].map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                      <Checkbox checked={tableTipoDocumentoFilter.indexOf(tipo) > -1} />
                      <ListItemText primary={tipo} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Provincia</InputLabel>
                <Select
                  multiple
                  value={tableProvinciaFilter}
                  onChange={(e) => {
                    setTableProvinciaFilter(e.target.value as string[]);
                    handlePublicProvinceChange(e.target.value as string);
                  }}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {provinces.map((province) => (
                    <MenuItem key={province} value={province}>
                      <Checkbox checked={tableProvinciaFilter.indexOf(province) > -1} />
                      <ListItemText primary={province} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Cantón</InputLabel>
                <Select
                  multiple
                  value={tableCantonFilter}
                  onChange={(e) => {
                    setTableCantonFilter(e.target.value as string[]);
                    handlePublicCantonChange(e.target.value as string);
                  }}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {cantons.map((canton) => (
                    <MenuItem key={canton} value={canton}>
                      <Checkbox checked={tableCantonFilter.indexOf(canton) > -1} />
                      <ListItemText primary={canton} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Distrito</InputLabel>
                <Select
                  multiple
                  value={tableDistritoFilter}
                  onChange={(e) => setTableDistritoFilter(e.target.value as string[])}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(', ')}
                >
                  {availableDistricts.map((district) => (
                    <MenuItem key={district} value={district}>
                      <Checkbox checked={tableDistritoFilter.indexOf(district) > -1} />
                      <ListItemText primary={district} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFilterDialog}>Cancelar</Button>
            </DialogActions>
          </Dialog>

          {/* Column Selection Dialog */}
          <Dialog
            open={isColumnSelectionDialogOpen}
            onClose={handleCloseColumnSelectionDialog}
            PaperProps={{
              sx: {
                width: '400px',
                maxHeight: '80vh',
              }
            }}
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
          >
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold'
            }}>
              Seleccionar Columnas
            </DialogTitle>
            <DialogContent dividers>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2
              }}>
                {columnOrder.map((column) => (
                  <FormControlLabel
                    key={column}
                    control={
                      <Checkbox
                        checked={visiblePublicColumns.has(column as keyof PublicClient)}
                        onChange={() => handlePublicColumnToggle(column as keyof PublicClient)}
                        sx={{
                          color: '#87CEEB',
                          '&.Mui-checked': {
                            color: '#87CEEB',
                          },
                        }}
                      />
                    }
                    label={publicColumnNames[column as keyof typeof publicColumnNames]}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: 'rgba(135, 206, 235, 0.08)',
                        borderRadius: '4px',
                      },
                    }}
                  />
                ))}
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
              <Button
                onClick={handleCloseColumnSelectionDialog}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>

          {/* Add/Edit Client Dialog */}
          <Dialog
            open={openPublic}
            onClose={handleClosePublicClient}
            fullScreen={isMobile}
            maxWidth="lg"
            fullWidth
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
            PaperProps={{
              sx: {
                borderRadius: '8px',
              },
            }}
          >
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold',
              height: '77%'
            }}>
              {currentPublicClient?.id ? 'Editar Cliente Público' : 'Agregar Nuevo Cliente Público'}
            </DialogTitle>
            <DialogContent dividers>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
                aria-label="client tabs"
                sx={{
                  mb: 2,
                  bgcolor: 'background.paper', // Fondo de las pestañas
                  borderRadius: 2, // Bordes redondeados
                  boxShadow: 1, // Sombra para un efecto elevado
                  '& .MuiTab-root': {
                    color: '#87CEEB', // Color de texto por defecto
                    '&.Mui-selected': {
                      color: 'white', // Color de texto cuando está seleccionado
                      backgroundColor: '#5CACEE', // Fondo cuando está seleccionado
                      borderRadius: 2, // Bordes redondeados para la pestaña seleccionada
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(92, 202, 238, 0.2)', // Efecto hover
                    },
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#5CACEE', // Color del indicador
                  },
                }}
              >
                <Tab label="Datos Personales y Ubicación" />
                <Tab label="Mascotas e Imagen" />
              </Tabs>

              <Box sx={{ height: '603px', overflow: 'auto' }}>
                {tabValue === 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                      <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              autoFocus
                              margin="dense"
                              label="Nombre y Apellidos"
                              type="text"
                              fullWidth
                              error={!!currentPublicClient?.name && !validateName(currentPublicClient.name)} // Validación de nombre
                              helperText={!!currentPublicClient?.name && !validateName(currentPublicClient.name) ? 'El nombre solo debe contener letras y números, y tener un máximo de 29 caracteres.' : ''}
                              value={currentPublicClient?.name || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setCurrentPublicClient(curr => curr ? { ...curr, name: value } : null);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              margin="dense"
                              label="Teléfono"
                              type="tel"
                              fullWidth
                              error={!!currentPublicClient?.phoneNumber && !validatePhone(currentPublicClient.phoneNumber)} // Validación de teléfono
                              helperText={!!currentPublicClient?.phoneNumber && !validatePhone(currentPublicClient.phoneNumber) ? 'El teléfono debe ser numérico y tener hasta 13 dígitos.' : ''}
                              value={currentPublicClient?.phoneNumber || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setCurrentPublicClient(curr => curr ? { ...curr, phoneNumber: value } : null);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              margin="dense"
                              label="Teléfono Secundario"
                              type="tel"
                              fullWidth
                              error={!!currentPublicClient?.second_phoneNumber && !validatePhone(currentPublicClient.second_phoneNumber)} // Validación de teléfono secundario
                              helperText={!!currentPublicClient?.second_phoneNumber && !validatePhone(currentPublicClient.second_phoneNumber) ? 'El teléfono debe ser numérico y tener hasta 13 dígitos.' : ''}
                              value={currentPublicClient?.second_phoneNumber || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setCurrentPublicClient(curr => curr ? { ...curr, second_phoneNumber: value } : null);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              margin="dense"
                              label="Correo Electrónico"
                              type="email"
                              fullWidth
                              error={!!currentPublicClient?.email && !validateEmail(currentPublicClient.email)} // Validación de correo electrónico
                              helperText={!!currentPublicClient?.email && !validateEmail(currentPublicClient.email) ? 'Ingrese un correo electrónico válido.' : ''}
                              value={currentPublicClient?.email || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setCurrentPublicClient(curr => curr ? { ...curr, email: value } : null);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              margin="dense"
                              label="Cédula Nacional"
                              type="text"
                              fullWidth
                              error={!!currentPublicClient?.cedula_nacional && !validateCedula(currentPublicClient.cedula_nacional)} // Validación de cédula
                              helperText={!!currentPublicClient?.cedula_nacional && !validateCedula(currentPublicClient.cedula_nacional) ? 'La cédula debe ser numérica y tener hasta 13 dígitos.' : ''}
                              value={currentPublicClient?.cedula_nacional || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setCurrentPublicClient(curr => curr ? { ...curr, cedula_nacional: value } : null);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Tipo de Documento</InputLabel>
                              <Select
                                value={currentPublicClient?.tipo_documento || ''}
                                onChange={(e) => setCurrentPublicClient(curr => curr ? { ...curr, tipo_documento: e.target.value as 'Nacional' | 'Jurídica' | 'Dimex' | 'Nite' } : null)}
                              >
                                <MenuItem value="Nacional">Nacional</MenuItem>
                                <MenuItem value="Jurídica">Jurídica</MenuItem>
                                <MenuItem value="Dimex">Dimex</MenuItem>
                                <MenuItem value="Nite">Nite</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Tipo de Cliente</InputLabel>
                              <Select
                                value={currentPublicClient?.tipo_client || ''}
                                onChange={(e) => {
                                  const newTipoClient = e.target.value as 'Municipalidad' | 'Regular';
                                  setCurrentPublicClient(curr => {
                                    if (curr) {
                                      const updatedClient = { ...curr, tipo_client: newTipoClient };
                                      // Si cambia de Municipalidad a Regular, borramos la municipalidad seleccionada
                                      if (newTipoClient === 'Regular') {
                                        updatedClient.municipalidades = '';
                                      }
                                      return updatedClient;
                                    }
                                    return null;
                                  });
                                }}
                              >
                                <MenuItem value="Municipalidad">Municipalidad</MenuItem>
                                <MenuItem value="Regular">Regular</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Tipo de Compostera</InputLabel>
                              <Select
                                value={currentPublicClient?.tipo_compostera || ''}
                                onChange={(e) => setCurrentPublicClient(curr => curr ? { ...curr, tipo_compostera: e.target.value as 'S' | 'M' | 'L' | 'XL' | 'Recolección' } : null)}
                              >
                                <MenuItem value="S">S</MenuItem>
                                <MenuItem value="M">M</MenuItem>
                                <MenuItem value="L">L</MenuItem>
                                <MenuItem value="XL">XL</MenuItem>
                                <MenuItem value="Recolección">Recolección</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Tipo de Entrega</InputLabel>
                              <Select
                                value={currentPublicClient?.tipo_entrega || ''}
                                onChange={(e) => setCurrentPublicClient(curr => curr ? { ...curr, tipo_entrega: e.target.value as 'Productos' | 'Recolección' } : null)}
                              >
                                <MenuItem value="Productos">
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ShoppingCartIcon sx={{ color: 'rgb(71, 219, 226)', mr: 1 }} />
                                    Productos
                                  </Box>
                                </MenuItem>
                                <MenuItem value="Recolección">
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <RecoleccionIcon sx={{ color: 'green', mr: 1 }} />
                                    Recolección
                                  </Box>
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Provincia</InputLabel>
                              <Select
                                value={currentPublicClient?.provincia || ''}
                                onChange={(e) => handlePublicProvinceChange(e.target.value as string)}
                              >
                                {costaRicaData.provinces.map((province) => (
                                  <MenuItem key={province} value={province}>{province}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Cantón</InputLabel>
                              <Select
                                value={currentPublicClient?.canton || ''}
                                onChange={(e) => {
                                  const selectedCanton = e.target.value as string;
                                  setCurrentPublicClient(curr => curr ? { ...curr, canton: selectedCanton, distrito: '' } : null);
                                  // Actualiza distritos según el cantón seleccionado
                                  setAvailableDistricts(costaRicaData.districts[currentPublicClient?.provincia as keyof typeof costaRicaData.districts]?.[selectedCanton as keyof typeof costaRicaData.districts[keyof typeof costaRicaData.districts]] || []);
                                }}
                              >
                                {availableCantons.map((canton) => (
                                  <MenuItem key={canton} value={canton}>{canton}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Distrito</InputLabel>
                              <Select
                                value={currentPublicClient?.distrito || ''}
                                onChange={(e) => setCurrentPublicClient(curr => curr ? { ...curr, distrito: e.target.value } : null)}
                              >
                                {availableDistricts.map((district) => (
                                  <MenuItem key={district} value={district}>{district}</MenuItem>))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              margin="dense"
                              label="Señas"
                              type="text"
                              fullWidth
                              multiline
                              rows={2}
                              error={!!currentPublicClient?.senas && !validateSenasNota(currentPublicClient.senas)} // Validación de señas
                              helperText={!!currentPublicClient?.senas && !validateSenasNota(currentPublicClient.senas) ? 'Las señas pueden tener hasta 250 caracteres.' : ''}
                              value={currentPublicClient?.senas || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setCurrentPublicClient(curr => curr ? { ...curr, senas: value } : null);
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              margin="dense"
                              label="Nota"
                              type="text"
                              fullWidth
                              multiline
                              rows={2}
                              error={!!currentPublicClient?.nota && !validateSenasNota(currentPublicClient.nota)} // Validación de nota
                              helperText={!!currentPublicClient?.nota && !validateSenasNota(currentPublicClient.nota) ? 'La nota puede tener hasta 250 caracteres.' : ''}
                              value={currentPublicClient?.nota || ''}
                              onChange={(e) => {
                                const value = e.target.value;
                                setCurrentPublicClient(curr => curr ? { ...curr, nota: value } : null);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Enlace de Google Maps"
                              value={currentPublicClient?.googleMapsLink || ''}
                              onChange={async (e) => { // Marcar la función como asíncrona
                                const newLink = e.target.value;
                                setCurrentPublicClient(curr => curr ? { ...curr, googleMapsLink: newLink } : null);

                                // Obtener latitud y longitud del enlace usando la función de geocodificación
                                const location = await getCoordinatesFromLink(newLink); // Función para obtener las coordenadas del enlace
                                if (location) {
                                  setSelectedLocationPublic({ lat: location[0], lng: location[1] }); // Actualiza la ubicación seleccionada
                                } else {
                                  setSelectedLocationPublic(null); // Resetea si el enlace no es válido
                                  console.error('No se pudieron obtener las coordenadas.');
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => window.open(currentPublicClient?.googleMapsLink, '_blank')}
                                      disabled={!currentPublicClient?.googleMapsLink}
                                      edge="end"
                                    >
                                      <MapIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Enlace de Waze"
                              value={currentPublicClient?.wazeLink || ''}
                              onChange={(e) => setCurrentPublicClient(curr => curr ? { ...curr, wazeLink: e.target.value } : null)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => window.open(currentPublicClient?.wazeLink, '_blank')}
                                      disabled={!currentPublicClient?.wazeLink}
                                      edge="end"
                                    >
                                      <WazeIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="dense">
                              <InputLabel>Estado de Creación</InputLabel>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                  value={currentPublicClient?.status_create_public_client || 'pending'}
                                  onChange={(e) => setCurrentPublicClient(curr => curr ? { ...curr, status_create_public_client: e.target.value as 'pending' | 'completed' | 'cancelled' } : null)}
                                  sx={{ flexGrow: 1 }} // Asegura que el Select ocupe el espacio disponible
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        transform: 'translateY(-100%)', // Mueve el menú hacia arriba
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem value="pending">
                                    <AccessTimeIcon sx={{ color: '#87CEEB', marginRight: '13px ', marginBottom: '-7px' }} />
                                    Pendiente
                                  </MenuItem>
                                  <MenuItem value="completed">
                                    <CheckCircleIcon sx={{ color: 'green', marginRight: '13px', marginBottom: '-7px' }} />
                                    Completado
                                  </MenuItem>
                                  <MenuItem value="cancelled">
                                    <CancelIcon sx={{ color: 'red', marginRight: '13px', marginBottom: '-7px' }} />
                                    Cancelado
                                  </MenuItem>
                                </Select>
                              </Box>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              margin="dense"
                              label="Código de Referencia"
                              type="text"
                              fullWidth
                              value={currentPublicClient?.codigo_referencia || ''}
                              onChange={(e) => setCurrentPublicClient(curr => curr ? { ...curr, codigo_referencia: e.target.value } : null)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box sx={{
                          height: 'calc(100% - 16px)',
                          mb: 2,
                          position: 'relative'
                        }}>
                          {/* <IconButton
                            onClick={handleFullScreen}
                            sx={{
                              position: 'absolute',
                              top: 10,
                              right: 10,
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              zIndex: 1000, // Asegúrate de que el botón esté por encima de otros elementos
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                              },
                            }}
                          >
                            <FullscreenIcon />
                          </IconButton> */}
                          <MapContainer
                            ref={mapRef}
                            center={selectedLocationPublic || mapCenterPublic}
                            zoom={15}
                            style={{ height: '591px', width: '100%' }}
                            attributionControl={false}
                          >
                            <TileLayer
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {/* Agrega un componente de mapa que maneje el clic */}
                            <MapEventHandler onClick={handlePublicMapClick} />
                            {/* Marcador para la ubicación seleccionada */}
                            {selectedLocationPublic && (
                              <Marker position={selectedLocationPublic} icon={customIcon}>
                                <Popup>
                                  <div>
                                    <strong>Ubicación seleccionada</strong>
                                    <br />
                                    <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">Google Maps</a>
                                    <br />
                                    <a href={wazeLink} target="_blank" rel="noopener noreferrer">Waze</a>
                                  </div>
                                </Popup>
                              </Marker>
                            )}
                          </MapContainer>
                          <Box sx={{
                            position: 'absolute',
                            top: 10,
                            left: 66,
                            backgroundColor: 'rgba(255,255,255,0.8)',
                            padding: '5px 10px',
                            borderRadius: '20px',
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                            zIndex: 1000 // Asegúrate de que este valor sea mayor que el del mapa
                          }}>
                            Selecciona la ubicación del cliente
                          </Box>
                          <IconButton
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={handleUpdatePublicLocation}
                            sx={{
                              position: 'absolute',
                              bottom: 292,
                              left: 10,
                              backgroundColor: 'orange',
                              color: 'white',
                              animation: `${rotateAnimation} 4s linear infinite`,
                              '&:hover': {
                                backgroundColor: 'darkorange',
                                animation: 'none',
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: '2rem',
                              },
                              zIndex: 1000 // Asegúrate de que este valor sea mayor que el del mapa
                            }}
                          >
                            {isHovered ? <RefreshIcon /> : <UpdateIcon />}
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {tabValue === 1 && (
                  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', paddingRight: 2, mr: 2 }}> {/* Añadido margen derecho */}
                      <Grid container spacing={2} sx={{ mb: 2, flex: 1, mt: 2, ml: 2 }}> {/* Añadido margin top y left */}
                        {['cant_gato', 'cant_perro_mini', 'cant_perro_pequeno', 'cant_perro_mediano', 'cant_perro_grande', 'cant_perro_gigante'].map((field) => (
                          <Grid item xs={12} sm={6} md={4} key={field}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{
                                border: '1px solid #87CEEB', // Borde alrededor del Box
                                borderRadius: 2, // Bordes redondeados
                                transition: 'transform 0.3s ease', // Transición para el hover
                                '&:hover': {
                                  transform: 'scale(1.02)', // Escalar ligeramente al pasar el mouse
                                },
                                padding: 1, // Espaciado interno
                              }}
                            >
                              <Box display="flex" flexDirection="column" alignItems="center">
                                <Box sx={{ textAlign: 'center', mb: 1 }}> {/* Ajustar margen inferior */}
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/${field}.png`}
                                    alt={publicColumnNames[field as keyof PublicClient]}
                                    style={{
                                      width: '70px',
                                      height: '70px',
                                      transition: 'transform 0.3s ease', // Añadir transición
                                    }}
                                    className="image-hover" // Clase para la animación
                                  />
                                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{publicColumnNames[field as keyof PublicClient]?.split(' ')[0]}</Typography>
                                  <Typography variant="body2">{publicColumnNames[field as keyof PublicClient]?.split(' ')[1]}</Typography>
                                </Box>
                              </Box>
                              <Box display="flex" flexDirection="column" alignItems="center" sx={{ ml: 1 }}> {/* Ajustar margen a la izquierda */}
                                <Typography variant="body2" sx={{ mb: 1 }}>Cantidad:</Typography> {/* Texto pequeño arriba */}
                                <Box display="flex" alignItems="center">
                                  <IconButton
                                    onClick={() => handleQuantityChangePublic(field, false)}
                                    sx={{ color: '#87CEEB' }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                  <TextField
                                    value={currentPublicClient?.[field as keyof PublicClient] || '0'}
                                    onChange={(e) => setCurrentPublicClient(curr => curr ? { ...curr, [field]: e.target.value } : null)}
                                    inputProps={{
                                      style: { textAlign: 'center' },
                                      min: 0,
                                    }}
                                    sx={{
                                      width: '50px',
                                      '& .MuiInputBase-input': {
                                        padding: '5px',
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: 'transparent',
                                        },
                                        '&:hover fieldset': {
                                          borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: 'transparent',
                                        },
                                      },
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => handleQuantityChangePublic(field, true)}
                                    sx={{ color: '#87CEEB' }}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        width: '35%', // Ajustado el tamaño del Box
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: 2,
                      }}
                    >
                      <Typography variant="h6" sx={{ mb: 2 }}>Imagen del cliente</Typography>
                      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dashed #87CEEB', borderRadius: 2, mb: 2 }}>
                        {currentPublicClient?.imageUrl ? (
                          <img src={currentPublicClient.imageUrl} alt="Client" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        ) : (
                          <Typography variant="body1" color="textSecondary">Vista previa de la imagen</Typography>
                        )}
                      </Box>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={handleImageUploadPublic}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          fullWidth
                          sx={{
                            backgroundColor: '#87CEEB',
                            '&:hover': {
                              backgroundColor: '#5CACEE',
                            },
                          }}
                        >
                          Subir Imagen
                        </Button>
                      </label>
                    </Box>
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'center',
              px: 3,
              py: 2,
              backgroundColor: 'rgba(135, 206, 235, 0.1)'
            }}>
              <Button
                onClick={handleClosePublicClient}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                  mr: 2,
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleSavePublicClient}
                variant="contained"
                disabled={!isPublicFormValid()}
                sx={{
                  backgroundColor: '#87CEEB',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#5CACEE',
                  },
                  '&:disabled': {
                    backgroundColor: 'rgba(135, 206, 235, 0.5)',
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Guardar
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openLocationIcon}
            onClose={() => setOpenLocationIcon(false)}
          >
            <DialogTitle sx={{ backgroundColor: '#87CEEB', color: 'white', fontWeight: 'bold' }}>
              Confirmar Ubicación
            </DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                <LocationOnIcon sx={{ color: '#87CEEB', fontSize: 40, marginRight: 2 }} />
                <DialogContentText sx={{
                  margin: 0,
                  fontSize: '1.2rem',
                  color: '#333',
                  fontWeight: '500',
                }}>
                  ¿Estás seguro de actualizar la ubicación?
                </DialogContentText>
              </Box>
              <Divider sx={{ margin: '16px 0' }} />
              <Typography variant="body2" sx={{ textAlign: 'center', color: '#666' }}>
                Esta acción actualizará tu ubicación actual. Asegúrate de que sea correcta.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
              <Button onClick={() => setOpenLocationIcon(false)} variant="outlined" sx={{
                color: '#87CEEB',
                borderColor: '#87CEEB',
                '&:hover': {
                  backgroundColor: 'rgba(135, 206, 235, 0.1)',
                  borderColor: '#5CACEE',
                },
                fontWeight: 'bold',
                px: 4,
                py: 1,
              }}>
                Cancelar
              </Button>
              <Button onClick={confirmLocationUpdate} variant="contained" sx={{
                backgroundColor: '#87CEEB',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#5CACEE',
                },
                fontWeight: 'bold',
                px: 4,
                py: 1,
              }}>
                Actualizar
              </Button>
            </DialogActions>
          </Dialog>

          {/* View Client Dialog */}
          <Dialog
            open={viewOpenPublic}
            onClose={handleViewClosePublic}
            fullScreen={isMobile}
            maxWidth="lg"
            fullWidth
            BackdropProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
            PaperProps={{
              sx: {
                borderRadius: '8px',
                height: 'calc(100% - 64px)',
                maxHeight: 'none',
              },
            }}
          >
            <DialogTitle sx={{
              backgroundColor: '#87CEEB',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              py: 2,
            }}>
              Detalles del Cliente: {currentPublicClient?.name}
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 0 }}>
              <Box sx={{ display: 'flex', height: '100%' }}>
                <Box sx={{ width: '30%', p: 3, borderRight: '1px solid #e0e0e0' }}>
                  <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Información Personal</Typography>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Teléfono</Typography>
                    <Typography>{currentPublicClient?.phoneNumber}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Email</Typography>
                    <Typography>{currentPublicClient?.email}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Cédula Nacional</Typography>
                    <Typography>{currentPublicClient?.cedula_nacional}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Tipo de Documento</Typography>
                    <Typography>{currentPublicClient?.tipo_documento}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Tipo de Cliente</Typography>
                    <Typography>{currentPublicClient?.tipo_client}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Tipo de Compostera</Typography>
                    <Typography>{currentPublicClient?.tipo_compostera}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">Tipo de Entrega</Typography>
                    {currentPublicClient?.tipo_entrega === 'Productos' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ShoppingCartIcon sx={{ color: 'rgb(71, 219, 226)', mr: 1 }} />
                        <Typography>Productos</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <RecoleccionIcon sx={{ color: 'green', mr: 1 }} />
                        <Typography>Recolección</Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ width: '40%', p: 3, borderRight: '1px solid #e0e0e0' }}>
                  <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Ubicación</Typography>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Provincia</Typography>
                    <Typography>{currentPublicClient?.provincia}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Cantón</Typography>
                    <Typography>{currentPublicClient?.canton}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Distrito</Typography>
                    <Typography>{currentPublicClient?.distrito}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Municipalidad</Typography>
                    <Typography>{currentPublicClient?.municipalidades}</Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Dirección y señas para llegar</Typography>
                    <Typography>{currentPublicClient?.senas}</Typography>
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" color="textSecondary">Nota del cliente</Typography>
                    <Typography>{currentPublicClient?.nota}</Typography>
                  </Box>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" color="textSecondary">Código de Referencia</Typography>
                    <Typography>{currentPublicClient?.codigo_referencia}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="contained"
                      startIcon={<MapIcon />}
                      onClick={() => window.open(currentPublicClient?.googleMapsLink, '_blank')}
                      disabled={!currentPublicClient?.googleMapsLink}
                      sx={{
                        backgroundColor: '#4285F4',
                        '&:hover': { backgroundColor: '#3367D6' },
                        flex: 1,
                      }}
                    >
                      Google Maps
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<WazeIcon />}
                      onClick={() => window.open(currentPublicClient?.wazeLink, '_blank')}
                      disabled={!currentPublicClient?.wazeLink}
                      sx={{
                        backgroundColor: '#33CCFF',
                        '&:hover': { backgroundColor: '#28A8E0' },
                        flex: 1,
                      }}
                    >
                      Waze
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ width: '30%', p: 3, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Imagen del cliente</Typography>
                  <Box sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px dashed #87CEEB',
                    borderRadius: 2,
                    mb: 3,
                  }}>
                    {currentPublicClient?.imageUrl ? (
                      <img src={currentPublicClient.imageUrl} alt="Client" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                    ) : (
                      <Typography variant="body1" color="textSecondary">Vista previa de la imagen</Typography>
                    )}
                  </Box>
                  <Typography variant="h6" gutterBottom sx={{ color: '#87CEEB', fontWeight: 'bold' }}>Mascotas</Typography>
                  <Grid container spacing={2}>
                    {['cant_gato', 'cant_perro_mini', 'cant_perro_pequeno', 'cant_perro_mediano', 'cant_perro_grande', 'cant_perro_gigante']
                      .filter(field => parseInt(currentPublicClient?.[field as keyof PublicClient] as string, 10) > 0)
                      .map((field) => (
                        <Grid item xs={6} key={field}>
                          <Box display="flex" alignItems="center">
                            <img
                              src={`${process.env.PUBLIC_URL}/images/${field}.png`}
                              alt={publicColumnNames[field as keyof PublicClient]}
                              style={{ width: '40px', height: '40px', marginRight: '8px' }}
                            />
                            <Typography variant="body2">{publicColumnNames[field as keyof PublicClient]}: {currentPublicClient?.[field as keyof PublicClient]}</Typography>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', px: 3, py: 2, backgroundColor: 'rgba(135, 206, 235, 0.1)' }}>
              <Button
                onClick={handleViewClose}
                variant="outlined"
                sx={{
                  color: '#87CEEB',
                  borderColor: '#87CEEB',
                  '&:hover': {
                    backgroundColor: 'rgba(135, 206, 235, 0.1)',
                    borderColor: '#5CACEE',
                  },
                  fontWeight: 'bold',
                  px: 4,
                  py: 1,
                }}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>

        </div>
      )}

      {/* Success Snackbar */}
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage(null)}>
        <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%', backgroundColor: 'green', color: 'white' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box >
  );
};

export default ClientList;