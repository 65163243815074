import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import DriverDashboard from './components/DriverDashboard';
import PublicClientInterface from './components/PublicClientInterface'; // Importa el nuevo componente
import PublicClientForm from './components/PublicClientForm'; // Importa el nuevo componente
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, Box, Snackbar, Alert } from '@mui/material';
import { collection, addDoc, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore'; // Asegúrate de importar estas funciones
import { PublicClient } from './models';

const theme = createTheme();

const App: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openPublicClientInterface, setOpenPublicClientInterface] = useState(false);
  const [openPublicClientForm, setOpenPublicClientForm] = useState(false);
  const [currentPublicClient, setCurrentPublicClient] = useState<any>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false); // Nuevo estado para manejar el inicio de sesión

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setIsLoggingIn(true); // Activa el estado de carga al iniciar sesión
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      } else {
        setUserRole(null);
      }
      setLoading(false);
      setIsLoggingIn(false); // Desactiva el estado de carga después de procesar el inicio de sesión
    });

    return () => unsubscribe();
  }, []);

  const handleClearError = () => {
    setError(null);
  };

  const handleSavePublicClient = async (client: PublicClient) => {
    if (!client || !client.name || !client.email) {
      setError("El nombre y el correo son obligatorios.");
      return;
    }

    try {
      // Agregar un nuevo cliente público a la colección
      await addDoc(collection(db, 'publicClients'), client);
      setOpenPublicClientInterface(false); // Cierra el diálogo
    } catch (error) {
      const errorMessage = error instanceof Error
        ? `Error al guardar el cliente público: ${error.message}`
        : "Error desconocido al guardar el cliente público.";
      console.error("Error al agregar el documento: ", error); // Log detallado del error
      setError(errorMessage); // Manejo de errores más específico
    }
  };

  const onUpdatePublicClient = async (client: PublicClient) => {
    if (client.id) {
      try {
        const clientRef = doc(db, 'publicClients', client.id); // Referencia al cliente en Firestore
        const clientData = { ...client }; // Copia de seguridad de los datos del cliente
        delete clientData.id; // Elimina el ID del cliente de los datos a actualizar
        await updateDoc(clientRef, clientData); // Actualiza el cliente en Firestore
        console.log("Cliente público actualizado con ID: ", client.id);
      } catch (error) {
        console.error("Error al actualizar el cliente público:", error);
        setError("Error al actualizar el cliente público."); // Manejo de errores
      }
    }
  };

  if (loading || isLoggingIn) { // Muestra el loading si está cargando o iniciando sesión
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/portal" element={user ? (userRole === 'admin' ? <AdminDashboard /> : <DriverDashboard />) : <Login />} />
          <Route path="/driver-dashboard" element={user && userRole === 'driver' ? <DriverDashboard /> : <Navigate to="/portal" />} />
          <Route path="/public-client" element={
            <PublicClientInterface
              open={openPublicClientInterface}
              onClose={() => setOpenPublicClientInterface(false)}
              handleSavePublicClient={handleSavePublicClient}
            />}
          />
          <Route path="/" element={
            <PublicClientForm
              open={openPublicClientForm}
              onClose={() => setOpenPublicClientForm(false)}
              handleSavePublicClient={handleSavePublicClient}
            />}
          />
        </Routes>
      </Router>

      {/* Error Snackbar */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClearError}>
        <Alert onClose={handleClearError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;