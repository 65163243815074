import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { User, Client } from '../models';
import { 
  Typography, 
  AppBar, 
  Toolbar, 
  Container,
  Card,
  CardContent,
  CardActions,
  Grid,
  IconButton,
  Box
} from '@mui/material';
import { Navigation, Map } from '@mui/icons-material';

const DriverDashboard: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUser({ id: userDoc.id, ...userDoc.data() } as User);
        }
      }

      const clientsQuery = query(collection(db, 'clients'));
      const clientsSnapshot = await getDocs(clientsQuery);
      const clientsList: Client[] = clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Client));
      setClients(clientsList);
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">
            Dashboard de Transportista
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" sx={{ flexGrow: 1, py: 3 }}>
        {user && (
          <Typography variant="h6" gutterBottom>Bienvenido, {user.name}</Typography>
        )}
        <Typography variant="h6" gutterBottom>Rutas de Entrega</Typography>
        <Grid container spacing={2}>
          {clients.map((client) => (
            <Grid item xs={12} sm={6} md={4} key={client.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{client.name}</Typography>
                </CardContent>
                <CardActions>
                  <IconButton color="primary" onClick={() => window.open(client.wazeLink, '_blank')}>
                    <Navigation />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => window.open(client.googleMapsLink, '_blank')}>
                    <Map />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default DriverDashboard;