import React, { useState, useEffect, Dispatch } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Tabs,
    Tab,
    Box,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Snackbar,
    Alert,
    Typography,
    Divider,
    InputAdornment,
    Container,
    keyframes,
    Checkbox,
    FormControlLabel,
    DialogContentText,
    CircularProgress,
} from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { CloudUpload as CloudUploadIcon, Add as AddIcon, Remove as RemoveIcon, EmojiEmotions as EmojiEmotionsIcon } from '@mui/icons-material';
import { Client, PublicClient } from '../models';
import L, { LatLng } from 'leaflet';
import {
    Update as UpdateIcon,
    Refresh as RefreshIcon,
    Map as MapIcon,
} from '@mui/icons-material';
import WazeIcon from '@mui/icons-material/Directions';
import { FullscreenIcon } from 'lucide-react';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// Importa el ícono de notificación
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// Extender la interfaz HTMLElement para incluir propiedades de pantalla completa
interface FullscreenHTMLElement extends HTMLElement {
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
    msRequestFullscreen?: () => Promise<void>;
}

// Datos estáticos para Costa Rica
const costaRicaData = {
    provinces: ['Alajuela', 'Cartago', 'Guanacaste', 'Heredia', 'Limón', 'Puntarenas', 'San José'],
    cantons: {
        'Alajuela': ['Alajuela', 'San Ramón', 'Grecia', 'San Mateo', 'Atenas', 'Naranjo', 'Palmares', 'Poás', 'Orotina', 'San Carlos', 'Zarcero', 'Sarchí', 'Upala', 'Los Chiles', 'Guatuso', 'Río Cuarto'],
        'Cartago': ['Cartago', 'Paraíso', 'La Unión', 'Jiménez', 'Turrialba', 'Alvarado', 'Oreamuno', 'El Guarco'],
        'Guanacaste': ['Liberia', 'Nicoya', 'Santa Cruz', 'Bagaces', 'Carrillo', 'Cañas', 'Abangares', 'Tilarán', 'Nandayure', 'La Cruz', 'Hojancha'],
        'Heredia': ['Heredia', 'Barva', 'Santo Domingo', 'Santa Bárbara', 'San Rafael', 'San Isidro', 'Belén', 'Flores', 'San Pablo', 'Sarapiquí'],
        'Limón': ['Limón', 'Pococí', 'Siquirres', 'Talamanca', 'Matina', 'Guácimo'],
        'Puntarenas': ['Puntarenas', 'Esparza', 'Buenos Aires', 'Montes de Oro', 'Osa', 'Quepos', 'Golfito', 'Coto Brus', 'Parrita', 'Corredores', 'Garabito'],
        'San José': ['San José', 'Escazú', 'Desamparados', 'Puriscal', 'Tarrazú', 'Aserrí', 'Mora', 'Goicoechea', 'Santa Ana', 'Alajuelita', 'Vázquez de Coronado', 'Acosta', 'Tibás', 'Moravia', 'Montes de Oca', 'Turrubares', 'Dota', 'Curridabat', 'Pérez Zeledón', 'León Cortés Castro']
    },
    districts: {
        'Alajuela': {
            'Alajuela': ['Alajuela', 'San José', 'Carrizal', 'San Antonio', 'Guácima', 'San Isidro', 'Sabanilla', 'San Rafael', 'Río Segundo', 'Desamparados', 'Turrúcares', 'Tambor', 'Garita', 'Sarapiquí'],
            'San Ramón': ['San Ramón', 'Santiago', 'San Juan', 'Piedades Norte', 'Piedades Sur', 'San Rafael', 'San Isidro', 'Ángeles', 'Alfaro', 'Volio', 'Concepción', 'Zapotal', 'Peñas Blancas', 'San Lorenzo'],
            'Grecia': ['Grecia', 'San Isidro', 'San José', 'San Roque', 'Tacares', 'Río Cuarto', 'Puente de Piedra', 'Bolívar'],
            'San Mateo': ['San Mateo', 'Desmonte', 'Jesús María', 'Labrador'],
            'Atenas': ['Atenas', 'Jesús', 'Mercedes', 'San Isidro', 'Concepción', 'San José', 'Santa Eulalia', 'Escobal'],
            'Naranjo': ['Naranjo', 'San Miguel', 'San José', 'Cirrí Sur', 'San Jerónimo', 'San Juan', 'El Rosario', 'Palmitos'],
            'Palmares': ['Palmares', 'Zaragoza', 'Buenos Aires', 'Santiago', 'Candelaria', 'Esquipulas', 'La Granja'],
            'Poás': ['San Pedro', 'San Juan', 'San Rafael', 'Carrillos', 'Sabana Redonda'],
            'Orotina': ['Orotina', 'El Mastate', 'Hacienda Vieja', 'Coyolar', 'La Ceiba'],
            'San Carlos': ['Quesada', 'Florencia', 'Buenavista', 'Aguas Zarcas', 'Venecia', 'Pital', 'La Fortuna', 'La Tigra', 'La Palmera', 'Venado', 'Cutris', 'Monterrey', 'Pocosol'],
            'Zarcero': ['Zarcero', 'Laguna', 'Tapesco', 'Guadalupe', 'Palmira', 'Zapote', 'Brisas'],
            'Sarchí': ['Sarchí Norte', 'Sarchí Sur', 'Toro Amarillo', 'San Pedro', 'Rodríguez'],
            'Upala': ['Upala', 'Aguas Claras', 'San José o Pizote', 'Bijagua', 'Delicias', 'Dos Ríos', 'Yolillal', 'Canalete'],
            'Los Chiles': ['Los Chiles', 'Caño Negro', 'El Amparo', 'San Jorge'],
            'Guatuso': ['San Rafael', 'Buenavista', 'Cote', 'Katira'],
            'Río Cuarto': ['Río Cuarto', 'Santa Rita', 'Santa Isabel']
        },
        'Cartago': {
            'Cartago': ['Oriental', 'Occidental', 'Carmen', 'San Nicolás', 'Aguacaliente', 'Guadalupe', 'Corralillo', 'Tierra Blanca', 'Dulce Nombre', 'Llano Grande', 'Quebradilla'],
            'Paraíso': ['Paraíso', 'Santiago', 'Orosi', 'Cachí', 'Llanos de Santa Lucía'],
            'La Unión': ['Tres Ríos', 'San Diego', 'San Juan', 'San Rafael', 'Concepción', 'Dulce Nombre', 'San Ramón', 'Río Azul'],
            'Jiménez': ['Juan Viñas', 'Tucurrique', 'Pejibaye'],
            'Turrialba': ['Turrialba', 'La Suiza', 'Peralta', 'Santa Cruz', 'Santa Teresita', 'Pavones', 'Tuis', 'Tayutic', 'Santa Rosa', 'Tres Equis', 'La Isabel', 'Chirripó'],
            'Alvarado': ['Pacayas', 'Cervantes', 'Capellades'],
            'Oreamuno': ['San Rafael', 'Cot', 'Potrero Cerrado', 'Cipreses', 'Santa Rosa'],
            'El Guarco': ['El Tejar', 'San Isidro', 'Tobosi', 'Patio de Agua']
        },
        'Guanacaste': {
            'Liberia': ['Liberia', 'Cañas Dulces', 'Mayorga', 'Nacascolo', 'Curubandé'],
            'Nicoya': ['Nicoya', 'Mansión', 'San Antonio', 'Quebrada Honda', 'Sámara', 'Nosara', 'Belén de Nosarita'],
            'Santa Cruz': ['Santa Cruz', 'Bolsón', 'Veintisiete de Abril', 'Tempate', 'Cartagena', 'Cuajiniquil', 'Diriá', 'Cabo Velas', 'Tamarindo'],
            'Bagaces': ['Bagaces', 'La Fortuna', 'Mogote', 'Río Naranjo'],
            'Carrillo': ['Filadelfia', 'Palmira', 'Sardinal', 'Belén'],
            'Cañas': ['Cañas', 'Palmira', 'San Miguel', 'Bebedero', 'Porozal'],
            'Abangares': ['Las Juntas', 'Sierra', 'San Juan', 'Colorado'],
            'Tilarán': ['Tilarán', 'Quebrada Grande', 'Tronadora', 'Santa Rosa', 'Líbano', 'Tierras Morenas', 'Arenal', 'Cabeceras'],
            'Nandayure': ['Carmona', 'Santa Rita', 'Zapotal', 'San Pablo', 'Porvenir', 'Bejuco'],
            'La Cruz': ['La Cruz', 'Santa Cecilia', 'La Garita', 'Santa Elena'],
            'Hojancha': ['Hojancha', 'Monte Romo', 'Puerto Carrillo', 'Huacas', 'Matambú']
        },
        'Heredia': {
            'Heredia': ['Heredia', 'Mercedes', 'San Francisco', 'Ulloa', 'Varablanca'],
            'Barva': ['Barva', 'San Pedro', 'San Pablo', 'San Roque', 'Santa Lucía', 'San José de la Montaña'],
            'Santo Domingo': ['Santo Domingo', 'San Vicente', 'San Miguel', 'Paracito', 'Santo Tomás', 'Santa Rosa', 'Tures', 'Pará'],
            'Santa Bárbara': ['Santa Bárbara', 'San Pedro', 'San Juan', 'Jesús', 'Santo Domingo', 'Purabá'],
            'San Rafael': ['San Rafael', 'San Josecito', 'Santiago', 'Ángeles', 'Concepción'],
            'San Isidro': ['San Isidro', 'San José', 'Concepción', 'San Francisco'],
            'Belén': ['San Antonio', 'La Ribera', 'La Asunción'],
            'Flores': ['San Joaquín', 'Barrantes', 'Llorente'],
            'San Pablo': ['San Pablo', 'Rincón de Sabanilla'],
            'Sarapiquí': ['Puerto Viejo', 'La Virgen', 'Las Horquetas', 'Llanuras del Gaspar', 'Cureña']
        },
        'Limón': {
            'Limón': ['Limón', 'Valle La Estrella', 'Río Blanco', 'Matama'],
            'Pococí': ['Guápiles', 'Jiménez', 'Rita', 'Roxana', 'Cariari', 'Colorado', 'La Colonia'],
            'Siquirres': ['Siquirres', 'Pacuarito', 'Florida', 'Germania', 'El Cairo', 'Alegría', 'Reventazón'],
            'Talamanca': ['Bratsi', 'Sixaola', 'Cahuita', 'Telire'],
            'Matina': ['Matina', 'Batán', 'Carrandi'],
            'Guácimo': ['Guácimo', 'Mercedes', 'Pocora', 'Río Jiménez', 'Duacarí']
        },
        'Puntarenas': {
            'Puntarenas': ['Puntarenas', 'Pitahaya', 'Chomes', 'Lepanto', 'Paquera', 'Manzanillo', 'Guacimal', 'Barranca', 'Monte Verde', 'Isla del Coco', 'Cóbano', 'Chacarita', 'Chira', 'Acapulco', 'El Roble', 'Arancibia'],
            'Esparza': ['Espíritu Santo', 'San Juan Grande', 'Macacona', 'San Rafael', 'San Jerónimo', 'Caldera'],
            'Buenos Aires': ['Buenos Aires', 'Volcán', 'Potrero Grande', 'Boruca', 'Pilas', 'Colinas', 'Chánguena', 'Biolley', 'Brunka'],
            'Montes de Oro': ['Miramar', 'La Unión', 'San Isidro'],
            'Osa': ['Puerto Cortés', 'Palmar', 'Sierpe', 'Bahía Ballena', 'Piedras Blancas', 'Bahía Drake'],
            'Quepos': ['Quepos', 'Savegre', 'Naranjito'],
            'Golfito': ['Golfito', 'Puerto Jiménez', 'Guaycará', 'Pavón'],
            'Coto Brus': ['San Vito', 'Sabalito', 'Aguabuena', 'Limoncito', 'Pittier', 'Gutiérrez Braun'],
            'Parrita': ['Parrita'],
            'Corredores': ['Corredor', 'La Cuesta', 'Canoas', 'Laurel'],
            'Garabito': ['Jacó', 'Tárcoles']
        },
        'San José': {
            'San José': ['Carmen', 'Merced', 'Hospital', 'Catedral', 'Zapote', 'San Francisco de Dos Ríos', 'Uruca', 'Mata Redonda', 'Pavas', 'Hatillo', 'San Sebastián'],
            'Escazú': ['Escazú', 'San Antonio', 'San Rafael'],
            'Desamparados': ['Desamparados', 'San Miguel', 'San Juan de Dios', 'San Rafael Arriba', 'San Antonio', 'Frailes', 'Patarrá', 'San Cristóbal', 'Rosario', 'Damas', 'San Rafael Abajo', 'Gravilias', 'Los Guido'],
            'Puriscal': ['Santiago', 'Mercedes Sur', 'Barbacoas', 'Grifo Alto', 'San Rafael', 'Candelarita', 'Desamparaditos', 'San Antonio', 'Chires'],
            'Tarrazú': ['San Marcos', 'San Lorenzo', 'San Carlos'],
            'Aserrí': ['Aserrí', 'Tarbaca', 'Vuelta de Jorco', 'San Gabriel', 'Legua', 'Monterrey', 'Salitrillos'],
            'Mora': ['Colón', 'Guayabo', 'Tabarcia', 'Piedras Negras', 'Picagres', 'Jaris', 'Quitirrisí'],
            'Goicoechea': ['Guadalupe', 'San Francisco', 'Calle Blancos', 'Mata de Plátano', 'Ipís', 'Rancho Redondo', 'Purral'],
            'Santa Ana': ['Santa Ana', 'Salitral', 'Pozos', 'Uruca', 'Piedades', 'Brasil'],
            'Alajuelita': ['Alajuelita', 'San Josecito', 'San Antonio', 'Concepción', 'San Felipe'],
            'Vázquez de Coronado': ['San Isidro', 'San Rafael', 'Dulce Nombre de Jesús', 'Patalillo', 'Cascajal'],
            'Acosta': ['San Ignacio', 'Guaitil', 'Palmichal', 'Cangrejal', 'Sabanillas'],
            'Tibás': ['San Juan', 'Cinco Esquinas', 'Anselmo Llorente', 'León XIII', 'Colima'],
            'Moravia': ['San Vicente', 'San Jerónimo', 'La Trinidad'],
            'Montes de Oca': ['San Pedro', 'Sabanilla', 'Mercedes', 'San Rafael'],
            'Turrubares': ['San Pablo', 'San Pedro', 'San Juan de Mata', 'San Luis', 'Carara'],
            'Dota': ['Santa María', 'Jardín', 'Copey'],
            'Curridabat': ['Curridabat', 'Granadilla', 'Sánchez', 'Tirrases'],
            'Pérez Zeledón': ['San Isidro de El General', 'El General', 'Daniel Flores', 'Rivas', 'San Pedro', 'Platanares', 'Pejibaye', 'Cajón', 'Barú', 'Río Nuevo', 'Páramo', 'La Amistad'],
            'León Cortés Castro': ['San Pablo', 'San Andrés', 'Llano Bonito', 'San Isidro', 'Santa Cruz', 'San Antonio']
        }
    },
    municipalities: {
        'Alajuela': [
            "Municipalidad de Alajuela",
            "Municipalidad de San Ramón",
            "Municipalidad de Grecia",
            "Municipalidad de San Mateo",
            "Municipalidad de Atenas",
            "Municipalidad de Naranjo",
            "Municipalidad de Palmares",
            "Municipalidad de Poás",
            "Municipalidad de Orotina",
            "Municipalidad de San Carlos",
            "Municipalidad de Zarcero",
            "Municipalidad de Sarchí",
            "Municipalidad de Upala",
            "Municipalidad de Los Chiles",
            "Municipalidad de Guatuso",
            "Municipalidad de Río Cuarto"
        ],
        'Cartago': [
            "Municipalidad de Cartago",
            "Municipalidad de Paraíso",
            "Municipalidad de La Unión",
            "Municipalidad de Jiménez",
            "Municipalidad de Turrialba",
            "Municipalidad de Alvarado",
            "Municipalidad de Oreamuno",
            "Municipalidad de El Guarco"
        ],
        'Guanacaste': [
            "Municipalidad de Liberia",
            "Municipalidad de Nicoya",
            "Municipalidad de Santa Cruz",
            "Municipalidad de Bagaces",
            "Municipalidad de Carrillo",
            "Municipalidad de Cañas",
            "Municipalidad de Abangares",
            "Municipalidad de Tilarán",
            "Municipalidad de Nandayure",
            "Municipalidad de La Cruz",
            "Municipalidad de Hojancha"
        ],
        'Heredia': [
            "Municipalidad de Heredia",
            "Municipalidad de Barva",
            "Municipalidad de Santo Domingo",
            "Municipalidad de Santa Bárbara",
            "Municipalidad de San Rafael",
            "Municipalidad de San Isidro",
            "Municipalidad de Belén",
            "Municipalidad de Flores",
            "Municipalidad de San Pablo",
            "Municipalidad de Sarapiquí"
        ],
        'Limón': [
            "Municipalidad de Limón",
            "Municipalidad de Pococí",
            "Municipalidad de Siquirres",
            "Municipalidad de Talamanca",
            "Municipalidad de Matina",
            "Municipalidad de Guácimo"
        ],
        'Puntarenas': [
            "Municipalidad de Puntarenas",
            "Municipalidad de Esparza",
            "Municipalidad de Buenos Aires",
            "Municipalidad de Montes de Oro",
            "Municipalidad de Osa",
            "Municipalidad de Quepos",
            "Municipalidad de Golfito",
            "Municipalidad de Coto Brus",
            "Municipalidad de Parrita",
            "Municipalidad de Corredores",
            "Municipalidad de Garabito"
        ],
        'San José': [
            "Municipalidad de San José",
            "Municipalidad de Escazú",
            "Municipalidad de Desamparados",
            "Municipalidad de Puriscal",
            "Municipalidad de Tarrazú",
            "Municipalidad de Aserrí",
            "Municipalidad de Mora",
            "Municipalidad de Goicoechea",
            "Municipalidad de Santa Ana",
            "Municipalidad de Alajuelita",
            "Municipalidad de Vázquez de Coronado",
            "Municipalidad de Acosta",
            "Municipalidad de Tibás",
            "Municipalidad de Moravia",
            "Municipalidad de Montes de Oca",
            "Municipalidad de Turrubares",
            "Municipalidad de Dota",
            "Municipalidad de Curridabat",
            "Municipalidad de Pérez Zeledón",
            "Municipalidad de León Cortés Castro"
        ]
    }
};

interface Boundary {
    minLat: number;
    maxLat: number;
    minLng: number;
    maxLng: number;
}

interface LocationData {
    provincia: string;
    canton: string;
    distrito: string;
}

const cantonBoundaries: { [key: string]: { [key: string]: Boundary } } = {
    'San José': {
        'San José': { minLat: 9.9, maxLat: 10.0, minLng: -84.1, maxLng: -84.0 },
        'Escazú': { minLat: 9.9, maxLat: 9.95, minLng: -84.2, maxLng: -84.1 },
        'Desamparados': { minLat: 9.85, maxLat: 9.95, minLng: -84.1, maxLng: -84.0 },
        'Puriscal': { minLat: 9.75, maxLat: 9.9, minLng: -84.4, maxLng: -84.2 },
        'Tarrazú': { minLat: 9.6, maxLat: 9.75, minLng: -84.1, maxLng: -83.9 },
        'Aserrí': { minLat: 9.8, maxLat: 9.9, minLng: -84.15, maxLng: -84.05 },
        'Mora': { minLat: 9.85, maxLat: 9.95, minLng: -84.3, maxLng: -84.2 },
        'Goicoechea': { minLat: 9.95, maxLat: 10.05, minLng: -84.05, maxLng: -83.95 },
        'Santa Ana': { minLat: 9.9, maxLat: 10.0, minLng: -84.25, maxLng: -84.15 },
        'Alajuelita': { minLat: 9.85, maxLat: 9.95, minLng: -84.15, maxLng: -84.05 },
        'Vázquez de Coronado': { minLat: 10.0, maxLat: 10.1, minLng: -84.0, maxLng: -83.9 },
        'Acosta': { minLat: 9.7, maxLat: 9.85, minLng: -84.3, maxLng: -84.1 },
        'Tibás': { minLat: 9.95, maxLat: 10.0, minLng: -84.1, maxLng: -84.05 },
        'Moravia': { minLat: 10.0, maxLat: 10.05, minLng: -84.05, maxLng: -83.95 },
        'Montes de Oca': { minLat: 9.95, maxLat: 10.0, minLng: -84.05, maxLng: -83.95 },
        'Turrubares': { minLat: 9.75, maxLat: 9.9, minLng: -84.6, maxLng: -84.4 },
        'Dota': { minLat: 9.55, maxLat: 9.7, minLng: -84.0, maxLng: -83.8 },
        'Curridabat': { minLat: 9.9, maxLat: 9.95, minLng: -84.05, maxLng: -83.95 },
        'Pérez Zeledón': { minLat: 9.2, maxLat: 9.5, minLng: -83.8, maxLng: -83.4 },
        'León Cortés Castro': { minLat: 9.65, maxLat: 9.8, minLng: -84.1, maxLng: -83.9 }
    },
    'Alajuela': {
        'Alajuela': { minLat: 10.0, maxLat: 10.1, minLng: -84.3, maxLng: -84.1 },
        'San Ramón': { minLat: 10.05, maxLat: 10.2, minLng: -84.6, maxLng: -84.4 },
        'Grecia': { minLat: 10.05, maxLat: 10.2, minLng: -84.35, maxLng: -84.2 },
        'San Mateo': { minLat: 9.9, maxLat: 10.0, minLng: -84.6, maxLng: -84.45 },
        'Atenas': { minLat: 9.95, maxLat: 10.05, minLng: -84.45, maxLng: -84.35 },
        'Naranjo': { minLat: 10.1, maxLat: 10.2, minLng: -84.45, maxLng: -84.35 },
        'Palmares': { minLat: 10.05, maxLat: 10.15, minLng: -84.5, maxLng: -84.4 },
        'Poás': { minLat: 10.15, maxLat: 10.25, minLng: -84.3, maxLng: -84.2 },
        'Orotina': { minLat: 9.9, maxLat: 10.0, minLng: -84.6, maxLng: -84.5 },
        'San Carlos': { minLat: 10.3, maxLat: 10.9, minLng: -84.6, maxLng: -84.0 },
        'Zarcero': { minLat: 10.15, maxLat: 10.25, minLng: -84.45, maxLng: -84.35 },
        'Sarchí': { minLat: 10.1, maxLat: 10.2, minLng: -84.4, maxLng: -84.3 },
        'Upala': { minLat: 10.8, maxLat: 11.0, minLng: -85.2, maxLng: -84.8 },
        'Los Chiles': { minLat: 10.8, maxLat: 11.1, minLng: -84.8, maxLng: -84.4 },
        'Guatuso': { minLat: 10.6, maxLat: 10.8, minLng: -84.9, maxLng: -84.6 },
        'Río Cuarto': { minLat: 10.3, maxLat: 10.5, minLng: -84.4, maxLng: -84.2 }
    },
    'Cartago': {
        'Cartago': { minLat: 9.8, maxLat: 9.9, minLng: -84.0, maxLng: -83.9 },
        'Paraíso': { minLat: 9.8, maxLat: 9.9, minLng: -83.9, maxLng: -83.8 },
        'La Unión': { minLat: 9.9, maxLat: 10.0, minLng: -84.0, maxLng: -83.9 },
        'Jiménez': { minLat: 9.7, maxLat: 9.8, minLng: -83.8, maxLng: -83.7 },
        'Turrialba': { minLat: 9.8, maxLat: 10.1, minLng: -83.7, maxLng: -83.5 },
        'Alvarado': { minLat: 9.9, maxLat: 10.0, minLng: -83.85, maxLng: -83.75 },
        'Oreamuno': { minLat: 9.9, maxLat: 10.0, minLng: -83.9, maxLng: -83.8 },
        'El Guarco': { minLat: 9.75, maxLat: 9.85, minLng: -84.0, maxLng: -83.9 }
    },
    'Heredia': {
        'Heredia': { minLat: 10.0, maxLat: 10.05, minLng: -84.15, maxLng: -84.1 },
        'Barva': { minLat: 10.05, maxLat: 10.1, minLng: -84.15, maxLng: -84.1 },
        'Santo Domingo': { minLat: 10.0, maxLat: 10.05, minLng: -84.1, maxLng: -84.05 },
        'Santa Bárbara': { minLat: 10.05, maxLat: 10.1, minLng: -84.2, maxLng: -84.15 },
        'San Rafael': { minLat: 10.05, maxLat: 10.1, minLng: -84.15, maxLng: -84.1 },
        'San Isidro': { minLat: 10.05, maxLat: 10.1, minLng: -84.1, maxLng: -84.05 },
        'Belén': { minLat: 9.95, maxLat: 10.0, minLng: -84.2, maxLng: -84.15 },
        'Flores': { minLat: 10.0, maxLat: 10.05, minLng: -84.2, maxLng: -84.15 },
        'San Pablo': { minLat: 10.0, maxLat: 10.05, minLng: -84.1, maxLng: -84.05 },
        'Sarapiquí': { minLat: 10.3, maxLat: 10.7, minLng: -84.2, maxLng: -83.8 }
    },
    'Guanacaste': {
        'Liberia': { minLat: 10.5, maxLat: 10.7, minLng: -85.5, maxLng: -85.3 },
        'Nicoya': { minLat: 10.0, maxLat: 10.2, minLng: -85.5, maxLng: -85.3 },
        'Santa Cruz': { minLat: 10.2, maxLat: 10.4, minLng: -85.7, maxLng: -85.5 },
        'Bagaces': { minLat: 10.4, maxLat: 10.6, minLng: -85.3, maxLng: -85.1 },
        'Carrillo': { minLat: 10.3, maxLat: 10.5, minLng: -85.6, maxLng: -85.4 },
        'Cañas': { minLat: 10.3, maxLat: 10.5, minLng: -85.1, maxLng: -84.9 },
        'Abangares': { minLat: 10.1, maxLat: 10.3, minLng: -85.0, maxLng: -84.8 },
        'Tilarán': { minLat: 10.3, maxLat: 10.5, minLng: -84.9, maxLng: -84.7 },
        'Nandayure': { minLat: 9.9, maxLat: 10.1, minLng: -85.3, maxLng: -85.1 },
        'La Cruz': { minLat: 10.9, maxLat: 11.1, minLng: -85.7, maxLng: -85.5 },
        'Hojancha': { minLat: 10.0, maxLat: 10.2, minLng: -85.4, maxLng: -85.2 }
    },
    'Puntarenas': {
        'Puntarenas': { minLat: 9.9, maxLat: 10.1, minLng: -84.9, maxLng: -84.7 },
        'Esparza': { minLat: 9.9, maxLat: 10.1, minLng: -84.7, maxLng: -84.5 },
        'Buenos Aires': { minLat: 9.0, maxLat: 9.2, minLng: -83.4, maxLng: -83.2 },
        'Montes de Oro': { minLat: 10.0, maxLat: 10.2, minLng: -84.6, maxLng: -84.4 },
        'Osa': { minLat: 8.8, maxLat: 9.0, minLng: -83.6, maxLng: -83.4 },
        'Quepos': { minLat: 9.4, maxLat: 9.6, minLng: -84.2, maxLng: -84.0 },
        'Golfito': { minLat: 8.6, maxLat: 8.8, minLng: -83.2, maxLng: -83.0 },
        'Coto Brus': { minLat: 8.9, maxLat: 9.1, minLng: -83.1, maxLng: -82.9 },
        'Parrita': { minLat: 9.5, maxLat: 9.7, minLng: -84.4, maxLng: -84.2 },
        'Corredores': { minLat: 8.5, maxLat: 8.7, minLng: -83.0, maxLng: -82.8 },
        'Garabito': { minLat: 9.6, maxLat: 9.8, minLng: -84.7, maxLng: -84.5 }
    },
    'Limón': {
        'Limón': { minLat: 9.9, maxLat: 10.1, minLng: -83.1, maxLng: -82.9 },
        'Pococí': { minLat: 10.3, maxLat: 10.5, minLng: -83.7, maxLng: -83.5 },
        'Siquirres': { minLat: 10.1, maxLat: 10.3, minLng: -83.5, maxLng: -83.3 },
        'Talamanca': { minLat: 9.5, maxLat: 9.7, minLng: -82.9, maxLng: -82.7 },
        'Matina': { minLat: 10.0, maxLat: 10.2, minLng: -83.3, maxLng: -83.1 },
        'Guácimo': { minLat: 10.2, maxLat: 10.4, minLng: -83.7, maxLng: -83.5 }
    }
};

const findLocationInCostaRicaData = (location: { lat: number; lng: number }): LocationData => {
    // Definimos límites más precisos para cada provincia
    const provinceBoundaries: { [key: string]: Boundary } = {
        'San José': { minLat: 9.5, maxLat: 10.2, minLng: -84.5, maxLng: -83.5 },
        'Alajuela': { minLat: 10.0, maxLat: 10.9, minLng: -84.5, maxLng: -83.8 },
        'Cartago': { minLat: 9.7, maxLat: 10.1, minLng: -84.0, maxLng: -83.5 },
        'Heredia': { minLat: 10.0, maxLat: 10.5, minLng: -84.2, maxLng: -83.8 },
        'Guanacaste': { minLat: 10.0, maxLat: 11.2, minLng: -85.8, maxLng: -84.8 },
        'Puntarenas': { minLat: 8.4, maxLat: 10.1, minLng: -85.0, maxLng: -83.0 },
        'Limón': { minLat: 9.0, maxLat: 10.3, minLng: -83.5, maxLng: -82.5 }
    };

    // Función para calcular la distancia entre dos puntos (Fórmula de Haversine)
    const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
        const R = 6371; // Radio de la Tierra en km
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distancia en km
    };

    // Encontrar la provincia
    let provincia = 'Desconocida';
    for (const [name, bounds] of Object.entries(provinceBoundaries)) {
        if (location.lat >= bounds.minLat && location.lat <= bounds.maxLat &&
            location.lng >= bounds.minLng && location.lng <= bounds.maxLng) {
            provincia = name;
            break;
        }
    }

    // Si no se encontró una provincia, retornar valores por defecto
    if (provincia === 'Desconocida') {
        return { provincia: 'Desconocida', canton: 'Desconocido', distrito: 'Desconocido' };
    }

    // Encontrar el cantón más cercano
    let canton = 'Desconocido';
    let minCantonDistance = Infinity;
    for (const [cantonName, bounds] of Object.entries(cantonBoundaries[provincia] || {})) {
        const cantonLat = (bounds.minLat + bounds.maxLat) / 2;
        const cantonLng = (bounds.minLng + bounds.maxLng) / 2;
        const distance = calculateDistance(location.lat, location.lng, cantonLat, cantonLng);
        if (distance < minCantonDistance) {
            minCantonDistance = distance;
            canton = cantonName;
        }
    }

    // Obtener los distritos del cantón
    const provinciaDistricts = costaRicaData.districts[provincia as keyof typeof costaRicaData.districts];
    const distritos = provinciaDistricts && canton in provinciaDistricts ? provinciaDistricts[canton as keyof typeof provinciaDistricts] : [];

    // Encontrar el distrito más cercano
    let distrito = 'Desconocido';
    let minDistritoDistance = Infinity;
    for (const d of distritos) {
        // Aquí usamos el centro del cantón como aproximación para el distrito
        const cantonBounds = cantonBoundaries[provincia][canton];
        const distritoLat = (cantonBounds.minLat + cantonBounds.maxLat) / 2;
        const distritoLng = (cantonBounds.minLng + cantonBounds.maxLng) / 2;
        const distance = calculateDistance(location.lat, location.lng, distritoLat, distritoLng);
        if (distance < minDistritoDistance) {
            minDistritoDistance = distance;
            distrito = d;
        }
    }

    return { provincia, canton, distrito };
};

type Province = keyof typeof costaRicaData.districts;
type Canton = keyof typeof costaRicaData.districts[Province];

const MapEventHandler = ({ onClick }: { onClick: (e: L.LeafletMouseEvent) => void }) => {
    const map = useMap();

    // Agregar el evento de clic al mapa
    React.useEffect(() => {
        const handleClick = (e: L.LeafletMouseEvent) => {
            // console.log("Clic en el mapa", e); // Verifica si se está llamando
            onClick(e);
        };

        map.on('click', handleClick);

        // Limpiar el evento cuando el componente se desmonte
        return () => {
            map.off('click', handleClick);
        };
    }, [map, onClick]);

    return null; // Este componente no necesita renderizar nada
};

interface PublicClientDialogProps {
    open: boolean;
    onClose: () => void;
    handleSavePublicClient: (client: PublicClient) => Promise<void>;
}

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const PublicClientDialog: React.FC<PublicClientDialogProps> = ({
    open,
    onClose,
    handleSavePublicClient // Corrected prop name
}) => {
    const [availableDistricts, setAvailableDistricts] = useState<string[]>([]);
    const [availableCantons, setAvailableCantons] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null); // Nuevo estado para mensajes de éxito
    const [isHovered, setIsHovered] = useState(false);
    const [currentPublicClient, setCurrentPublicClient] = useState<PublicClient | null>(null);
    const [availableMunicipalities, setAvailableMunicipalities] = useState<string[]>([]);
    const [locationShared, setLocationShared] = useState(false); // Estado para manejar si la ubicación fue compartida
    const [thankYouMessage, setThankYouMessage] = useState(''); // Estado para el mensaje de agradecimiento
    const [clickedButton, setClickedButton] = useState<string | null>(null);
    const [isClicked, setIsClicked] = useState(false); // Estado para controlar el clic
    const [isClickedImg, setIsClickedImg] = useState(false); // Estado para controlar el clic
    const [buttonColor, setButtonColor] = useState('#F3F9FB'); // Estado para el color del botón
    const [buttonColorGuardado, setButtonColorGuardado] = useState('#F3F9FB'); // Estado para el color del botón
    const [buttonColorMap, setButtonColorMap] = useState('#F5F5F5'); // Estado para el color del botón
    const [buttonColorImg, setButtonColorImg] = useState('#F5F5F5'); // Estado para el color del botón
    const [buttonColorCerraLastOne, setButtonColorrCerraLastOne] = useState('#F3F9FB'); // Estado para el color del botón
    const [buttonColorEnviar, setButtonColorEnviar] = useState('#27BEC8'); // Estado para el color del botón
    const [focus, setFocus] = useState(false); // Agregar estado para manejar el foco
    const [focusCanton, setFocusCanton] = useState(false); // Agregar estado para manejar el foco
    const [focusDistrito, setFocusDistrito] = useState(false); // Agregar estado para manejar el foco

    const publicColumnNames: { [key in keyof PublicClient]: string } = {
        name: 'Nombre',
        phoneNumber: 'Teléfono',
        email: 'Correo Electrónico',
        googleMapsLink: 'Enlace Google Maps',
        wazeLink: 'Enlace Waze',
        id: 'ID',
        cedula_nacional: 'Cédula Nacional',
        second_phoneNumber: 'Teléfono Secundario',
        tipo_documento: 'Tipo de Documento',
        tipo_client: 'Tipo de Cliente',
        tipo_compostera: 'Tipo de Compostera',
        tipo_entrega: 'Tipo de Entrega',
        senas: 'Señas',
        nota: 'Nota',
        imageUrl: 'URL de Imagen',
        provincia: 'Provincia',
        canton: 'Cantón',
        distrito: 'Distrito',
        municipalidades: 'Municipalidad',
        cant_gato: 'Gato: 0 a 10 Kg',
        cant_perro_mini: 'Mini: 0 a 6 Kg',
        cant_perro_pequeno: 'Pequeño: 6 a 10 Kg',
        cant_perro_mediano: 'Mediano: 10 a 25 Kg',
        cant_perro_grande: 'Grande: 25 a 45Kg',
        cant_perro_gigante: 'Gigante: +45Kg',
        status_create_public_client: 'Estado de Creación',
        codigo_referencia: 'Código de Referencia',
        soy_cliente: 'false',
    };

    useEffect(() => {
        // Cargar el cliente público actual al montar el componente
        const clientToLoad: PublicClient | null = {
            googleMapsLink: '',
            name: '',
            phoneNumber: '',
            email: '',
            wazeLink: '',
            cedula_nacional: '',
            second_phoneNumber: '',
            tipo_documento: 'Nacional',
            tipo_client: 'Municipalidad',
            tipo_compostera: 'Recolección',
            tipo_entrega: 'Recolección',
            senas: '',
            nota: '',
            imageUrl: '',
            provincia: '',
            canton: '',
            distrito: '',
            municipalidades: '',
            cant_gato: '0',
            cant_perro_mini: '0',
            cant_perro_pequeno: '0',
            cant_perro_mediano: '0',
            cant_perro_grande: '0',
            cant_perro_gigante: '0',
            status_create_public_client: 'pending',
            codigo_referencia: '',
            soy_cliente: 'false',
        };

        setCurrentPublicClient(clientToLoad); // Establecer el cliente actual

        // Actualizar distritos disponibles si hay un cliente público
        if (clientToLoad.provincia) {
            const districts = Object.keys(costaRicaData.districts[clientToLoad.provincia as keyof typeof costaRicaData.districts] || []);
            setAvailableDistricts(districts); // Set available districts to an array of district names
        } else {
            setAvailableDistricts([]); // Limpiar distritos si no hay provincia
        }
    }, []); // Dependencias vacías para que se ejecute solo al montar

    const validateName = (name: string) => /^[A-Za-z0-9\s]{1,29}$/.test(name);
    const validatePhone = (phone: string) => /^\d{1,13}$/.test(phone);
    const validateCedula = (cedula: string) => /^\d{1,13}$/.test(cedula);
    const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validateSenasNota = (input: string) => /^.{0,250}$/.test(input);
    const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number } | null>(null);
    const notificationSound = new Audio(`${process.env.PUBLIC_URL}/audio/Notification.mp3`); // Update the path accordingly
    const [termsAccepted, setTermsAccepted] = useState(false);

    // Define el estado para el nuevo diálogo
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

    // Add state for the confirmation dialog
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openLocationDialog, setOpenLocationDialog] = useState(false);
    const [openLocationIcon, setOpenLocationIcon] = useState(false);

    const [newLocation, setNewLocation] = useState<{ lat: number; lng: number } | null>(null);

    // Function to handle client creation
    const handleCreateClient = async () => {
        setOpenConfirmationDialog(true); // Open confirmation dialog
    };

    // Function to confirm client creation
    const confirmCreateClient = async () => {
        handleSavePublicClient(currentPublicClient!).then(() => {
            // Mostrar mensaje de éxito
            setSuccessMessage("Cliente creado exitosamente!"); // Set success message
        }).catch((error) => {
            console.error("Error al crear el cliente:", error);
            setError("Error al crear el cliente."); // Manejo de errores
        });
        // Show success dialog
        setOpenSuccessDialog(true); // Open the success dialog

        // Play notification sound
        // notificationSound.play().catch(error => {
        //     console.error("Error playing sound:", error);
        // });
    };

    // Function to update location from Google Maps or Waze link
    const updateLocationFromLink = (link: string) => {
        const googleMapsRegex = /@?(-?\d+\.\d+),(-?\d+\.\d+)/; // Regex to match lat, lng in Google Maps links
        const wazeRegex = /ll=(-?\d+\.\d+),(-?\d+\.\d+)/; // Regex to match lat, lng in Waze links

        let match = link.match(googleMapsRegex) || link.match(wazeRegex);
        if (match) {
            const lat = parseFloat(match[1]);
            const lng = parseFloat(match[2]);
            setCurrentPublicClient((curr: PublicClient | null) => {
                if (!curr) return null;
                return {
                    ...curr,
                    location: [lat, lng], // Update the location
                };
            });
        }
    };

    const isPublicFormValid = () => {
        return (
            currentPublicClient &&
            currentPublicClient.phoneNumber.trim() !== '' &&
            currentPublicClient.name.trim() !== '' &&
            currentPublicClient.email.trim() !== '' &&
            selectedLocation
        );
    };

    // Function to handle input changes
    const handlePublicInputChange = (field: keyof PublicClient, value: string) => {
        setCurrentPublicClient((curr: PublicClient | null) => {
            if (!curr) return null;
            return { ...curr, [field]: value }; // Update the field
        });
    };

    // Nueva función para manejar el clic y actualizar la ubicación
    const handleLocationClick = () => {
        if (!newLocation) {
            getLocation(); // Llama a la función existente para obtener la ubicación
        }
        setOpenLocationDialog(true); // Abre el diálogo al hacer clic en el ícono
    };


    // Función para obtener la ubicación del usuario
    const getLocation = () => {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true, // Intenta obtener la ubicación más precisa
                timeout: 10000, // Tiempo de espera de 10 segundos
                maximumAge: 0 // No usar una ubicación en caché
            };

            // Variable para manejar el timeout
            const timeoutId = setTimeout(() => {
                setThankYouMessage('¡La solicitud de geolocalización ha expirado!'); // Mensaje si la solicitud expira
                // console.error("Geolocation request timed out.");
            }, options.timeout);

            // const watchId = navigator.geolocation.getCurrentPosition(
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    clearTimeout(timeoutId); // Limpiar el timeout si se obtiene la ubicación
                    const { latitude, longitude } = position.coords;
                    const googleMapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;
                    const wazeLink = `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;

                    setSelectedLocation({ lat: latitude, lng: longitude }); // Actualiza la ubicación seleccionada
                    setCurrentPublicClient(curr => {
                        if (!curr) return null;
                        return {
                            ...curr,
                            googleMapsLink: googleMapsLink,
                            wazeLink: wazeLink,
                        };
                    });

                    const location = findLocationInCostaRicaData({ lat: latitude, lng: longitude });

                    // Validar si la ubicación está en Costa Rica
                    if (location.provincia != 'Desconocida') {
                        setCurrentPublicClient(curr => {
                            if (!curr) return null;
                            return {
                                ...curr,
                                provincia: location.provincia,
                                canton: location.canton,
                                distrito: location.distrito,
                                municipalidades: location.provincia && costaRicaData.municipalities[location.provincia as keyof typeof costaRicaData.municipalities]
                                    ? costaRicaData.municipalities[location.provincia as keyof typeof costaRicaData.municipalities].find(municipality => municipality.includes(location.canton)) || ''
                                    : '',
                            };
                        });
                    }

                    // Mostrar mensaje de agradecimiento y cambiar el estado
                    setThankYouMessage('¡Gracias por compartir tu ubicación!');
                    setLocationShared(true);

                    // Detener la observación después de obtener la ubicación
                    // navigator.geolocation.clearWatch(watchId);
                },
                (error) => {
                    clearTimeout(timeoutId); // Limpiar el timeout en caso de error
                    // Manejo de errores
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setThankYouMessage('¡Permiso de geolocalización denegado!'); // Mensaje si se niega el permiso
                            break;
                        case error.POSITION_UNAVAILABLE:
                            setThankYouMessage('¡Ubicación no disponible!'); // Mensaje si la ubicación no está disponible
                            break;
                        case error.TIMEOUT:
                            setThankYouMessage('¡La solicitud de geolocalización ha expirado!'); // Mensaje si la solicitud expira
                            break;
                        case 0:
                            setThankYouMessage('¡Error desconocido al obtener la ubicación!'); // Mensaje para errores desconocidos
                            break;
                    }
                    console.error("Error obtaining location:", error);
                },
                options // Usar las opciones definidas
            );
        } else {
            setThankYouMessage('¡Este navegador no soporta la geolocalización!');
            console.error("Geolocation is not supported by this browser.");
        }
    };

    // Function to handle map click
    const handleMapClick = (event: L.LeafletMouseEvent) => {
        const { lat, lng } = event.latlng; // Asegúrate de que 'latlng' esté disponible
        setNewLocation({ lat, lng }); // Set the new location
        setOpenLocationIcon(true); // Open the confirmation dialog

        const location = findLocationInCostaRicaData({ lat: lat, lng: lng });

        // Validar si la ubicación está en Costa Rica
        if (location.provincia != 'Desconocida') {
            setCurrentPublicClient(curr => {
                if (!curr) return null;
                return {
                    ...curr,
                    provincia: location.provincia,
                    canton: location.canton,
                    distrito: location.distrito,
                    municipalidades: location.provincia && costaRicaData.municipalities[location.provincia as keyof typeof costaRicaData.municipalities]
                        ? costaRicaData.municipalities[location.provincia as keyof typeof costaRicaData.municipalities].find(municipality => municipality.includes(location.canton)) || ''
                        : '',
                };
            });
        }
    };

    // Function to confirm location update
    const confirmLocationUpdate = () => {
        if (newLocation) {
            // Cambiar el color del botón al hacer clic
            setButtonColor('#D7E6EB');
            setTimeout(() => {
                setButtonColor('#F3F9FB'); // Restaurar el color original después de un tiempo
            }, 200); // Cambia el tiempo según sea necesario


            // Play notification sound
            notificationSound.play().catch(error => {
                console.error("Error playing sound:", error);
            });

            // Show notification
            setSuccessMessage("Ubicación actualizada exitosamente!"); // Set success message

            const { lat, lng } = newLocation;
            const googleMapsLink = `https://www.google.com/maps?q=${lat},${lng}`;
            const wazeLink = `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;

            setCurrentPublicClient(curr => {
                if (!curr) return null;
                return {
                    ...curr,
                    googleMapsLink: googleMapsLink,
                    wazeLink: wazeLink,
                    // Update other fields as necessary
                };
            });

            setSelectedLocation(newLocation); // Update the selected location
            // Nueva lógica para actualizar manualmente la ubicación
            const location = findLocationInCostaRicaData({ lat, lng });
            setCurrentPublicClient(curr => {
                if (!curr) return null;
                return {
                    ...curr,
                    provincia: location.provincia,
                    canton: location.canton,
                    distrito: location.distrito,
                    // Mantenemos el valor actual de municipalidades en lugar de actualizarlo
                    municipalidades: curr.municipalidades
                };
            });

            // Actualizamos los selectores
            if (location.provincia in costaRicaData.districts) {
                const provinciaDistricts = costaRicaData.districts[location.provincia as keyof typeof costaRicaData.districts];
                if (location.canton in provinciaDistricts) {
                    setAvailableDistricts(provinciaDistricts[location.canton as keyof typeof provinciaDistricts] || []);
                } else {
                    setAvailableDistricts([]); // Si el cantón no se encuentra, limpiar los distritos disponibles
                }
            }
        }

        setOpenLocationIcon(false); // Close the dialog
    };

    // const handleMapClick = (event: any) => {
    //     const { lat, lng } = event.latlng; // Asegúrate de que 'latlng' esté disponible
    //     const newGoogleMapsLink = `https://www.google.com/maps?q=${lat},${lng}`;
    //     const newWazeLink = `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;

    //     setSelectedLocation({ lat, lng }); // Actualiza la ubicación seleccionada
    //     setCurrentPublicClient(curr => {
    //         if (!curr) return null;
    //         return {
    //             ...curr,
    //             googleMapsLink: newGoogleMapsLink,
    //             wazeLink: newWazeLink,
    //             // Aquí puedes agregar más campos si es necesario
    //         };
    //     });

    //     // Nueva lógica para actualizar manualmente la ubicación
    //     const location = findLocationInCostaRicaData({ lat, lng });
    //     setCurrentPublicClient(curr => {
    //         if (!curr) return null;
    //         return {
    //             ...curr,
    //             provincia: location.provincia,
    //             canton: location.canton,
    //             distrito: location.distrito,
    //             // Mantenemos el valor actual de municipalidades en lugar de actualizarlo
    //             municipalidades: curr.municipalidades
    //         };
    //     });

    //     // Actualizamos los selectores
    //     if (location.provincia in costaRicaData.districts) {
    //         const provinciaDistricts = costaRicaData.districts[location.provincia as keyof typeof costaRicaData.districts];
    //         if (location.canton in provinciaDistricts) {
    //             setAvailableDistricts(provinciaDistricts[location.canton as keyof typeof provinciaDistricts] || []);
    //         } else {
    //             setAvailableDistricts([]); // Si el cantón no se encuentra, limpiar los distritos disponibles
    //         }
    //     }
    // };

    const handleQuantityChangePublic = (field: string, increment: boolean) => { // Nueva función para PublicClient
        setCurrentPublicClient((curr: PublicClient | null) => {
            if (!curr) return null;
            const currentValue = parseInt(curr[field as keyof PublicClient] as string) || 0;
            const newValue = increment ? currentValue + 1 : Math.max(0, currentValue - 1);
            return { ...curr, [field]: newValue.toString() };
        });
    };

    const handleImageUploadPublic = (event: React.ChangeEvent<HTMLInputElement>) => { // Nueva función para PublicClient
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, imageUrl: reader.result as string } : null);
            };
            reader.readAsDataURL(file);
        }
    };

    // // Función para manejar la selección de ubicación
    // const handleLocationSelect = (location: { lat: number; lng: number }) => {
    //     setCurrentPublicClient((curr: PublicClient | null) => {
    //         if (!curr) return null;

    //         return {
    //             ...curr,
    //             googleMapsLink: `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`,
    //             wazeLink: `https://www.waze.com/ul?ll=${location.lat},${location.lng}&navigate=yes`
    //         };
    //     });

    //     setIsLocationSelected(true);
    // };


    const handleClearError = () => {
        setError(null);
    };

    const isFormValid = () => {
        return (
            currentPublicClient &&
            validateName(currentPublicClient.name) &&
            validatePhone(currentPublicClient.phoneNumber) &&
            validateEmail(currentPublicClient.email) &&
            validateCedula(currentPublicClient.cedula_nacional) &&
            currentPublicClient.provincia &&
            currentPublicClient.canton &&
            currentPublicClient.distrito &&
            currentPublicClient.senas.trim() !== '' // Nueva validación para la dirección
        );
    };

    return (
        <Box sx={{
            width: '100%', // Asegúrate de que ocupe todo el ancho
            backgroundColor: '#F3F9FB', // Aplica el color de fondo aquí
            overflow: 'hidden' // Agregado para evitar el scroll horizontal
        }}>
            <Container sx={{
                fontFamily: 'Roboto, sans-serif !important',
                display: 'flex', // Use flexbox for centering
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically
                minHeight: '100vh', // Ensure it takes full height of the viewport
                overflow: 'hidden' // Agregado para evitar el scroll horizontal
            }}>

                <Box sx={{
                    width: { xs: '100%', sm: '358px' }, // Responsive width
                    justifySelf: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Center content horizontally
                    height: '100%',
                }}>
                    <div style={{
                        background: '#27BEC8',
                        padding: '29px 0px 0px',
                        width: '113%',
                        height: '109px',
                    }}>
                        <img src={`${process.env.PUBLIC_URL}/images/logoOllin.png`} alt="Logo Ollin" style={{
                            width: '202px',
                            height: '44px',
                            marginRight: '0px',
                            marginTop: '4px',
                            marginLeft: '33px',
                        }} />
                    </div>
                    <Box sx={{
                        width: '358px !important',
                        justifySelf: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="h4" sx={{
                                alignItems: 'center',
                                color: 'black',
                                padding: '12px 20px',
                                justifyContent: 'center',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: 'bold',
                                fontSize: 'larger',
                                width: '89%',
                                marginTop: '22px',
                            }}>
                                ¡Te damos la bienvenida <br />
                                al Servicio de Recolección <br />
                                de Residuos Popostaje <br />
                                Express! 🚚🪣💩
                            </Typography>
                            <Typography variant="h4" sx={{
                                alignItems: 'center',
                                color: 'black',
                                justifyContent: 'center',
                                fontFamily: 'Roboto, sans-serif',
                                fontSize: 'medium',
                                padding: '12px 20px',
                            }}>
                                Completá este formulario para ser parte del cambio.
                            </Typography>
                            <Typography variant="h4" sx={{
                                alignItems: 'center',
                                color: 'black',
                                padding: '12px 20px',
                                justifyContent: 'center',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: 'bold',
                                fontSize: 'larger',
                                marginTop: '13px',
                            }}>
                                Certificados por:
                            </Typography>
                            <Typography variant="h4" sx={{
                                background: 'white',
                                padding: '12px 20px',
                                borderRadius: '8px',
                            }}>
                                <img src={`${process.env.PUBLIC_URL}/images/logosPartners.png`} alt="Logo Partners" style={{ width: '100%', height: '100%', marginBottom: '-22px' }} />
                            </Typography>
                            <Typography variant="h4" sx={{
                                alignItems: 'center',
                                color: 'black',
                                padding: '12px 20px',
                                justifyContent: 'center',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: 'bold',
                                fontSize: 'larger',
                                marginTop: '22px',
                            }}>
                                Tus datos:
                            </Typography>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                    ¿Sos parte de Ollín Compostaje?
                                </Typography>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={currentPublicClient?.soy_cliente === 'Sí, soy cliente'}
                                                onChange={(e) => {
                                                    const value = e.target.checked ? 'Sí, soy cliente' : '';
                                                    setCurrentPublicClient(curr => curr ? { ...curr, soy_cliente: value } : null);
                                                }}
                                                color="primary" // Cambia el color a uno que se ajuste a tu paleta
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#27BEC8', // Color del checkbox cuando está seleccionado
                                                    },
                                                }}
                                            />
                                        }
                                        label="Sí, soy cliente"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={currentPublicClient?.soy_cliente === 'Soy nuevo'}
                                                onChange={(e) => {
                                                    const value = e.target.checked ? 'Soy nuevo' : '';
                                                    setCurrentPublicClient(curr => curr ? { ...curr, soy_cliente: value } : null);
                                                }}
                                                color="primary" // Cambia el color a uno que se ajuste a tu paleta
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#27BEC8', // Color del checkbox cuando está seleccionado
                                                    },
                                                }}
                                            />
                                        }
                                        label="Soy nuevo"
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                    Nombre y Apellidos
                                </Typography>
                                <TextField
                                    margin="dense"
                                    label="Tu respuesta"
                                    type="text"
                                    fullWidth
                                    variant="standard" // Cambiado a "standard" para mostrar solo la línea inferior
                                    InputLabelProps={{
                                        sx: {
                                            fontFamily: 'Roboto, sans-serif',
                                            // Cambiar el color del label al estar enfocado
                                            '&.Mui-focused': {
                                                color: '#27BEC8', // Color del label al enfocar
                                            },
                                            // Cambiar el color del label en caso de error
                                            '&.Mui-error': {
                                                color: 'orange', // Color del label en caso de error
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'Roboto, sans-serif',
                                            // borderBottom: 'solid 2px #27BEC8',
                                            // clipPath: 'border-box',
                                        },
                                        // Cambiar el color del borde al estar enfocado
                                        '& .MuiInputBase-root.Mui-focused:before': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        '& .MuiInputBase-root.Mui-focused:after': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        // Cambiar el color del borde si hay error
                                        '& .MuiInputBase-root.Mui-error:before': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                        '& .MuiInputBase-root.Mui-error:after': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                    }}
                                    error={!!currentPublicClient?.name && !validateName(currentPublicClient.name)}
                                    helperText={!!currentPublicClient?.name && !validateName(currentPublicClient.name) ? 'El nombre solo debe contener letras y números, y tener un máximo de 29 caracteres.' : ''}
                                    value={currentPublicClient?.name || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCurrentPublicClient(curr => curr ? { ...curr, name: value } : null);
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                        Número de Cédula
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#AAAAAA', marginBottom: 1, fontSize: '0.875rem' }}> {/* Tamaño de fuente ajustado */}
                                        Sin guiones ni espacios
                                    </Typography>
                                </Box>
                                <TextField
                                    margin="dense"
                                    label="Tu respuesta"
                                    type="text"
                                    fullWidth
                                    variant="standard" // Cambiado a "standard" para mostrar solo la línea inferior
                                    InputLabelProps={{
                                        sx: {
                                            fontFamily: 'Roboto, sans-serif',
                                            // Cambiar el color del label al estar enfocado
                                            '&.Mui-focused': {
                                                color: '#27BEC8', // Color del label al enfocar
                                            },
                                            // Cambiar el color del label en caso de error
                                            '&.Mui-error': {
                                                color: 'orange', // Color del label en caso de error
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'Roboto, sans-serif',
                                        },
                                        // Cambiar el color del borde al estar enfocado
                                        '& .MuiInputBase-root.Mui-focused:before': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        '& .MuiInputBase-root.Mui-focused:after': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        // Cambiar el color del borde si hay error
                                        '& .MuiInputBase-root.Mui-error:before': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                        '& .MuiInputBase-root.Mui-error:after': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                    }}
                                    error={!!currentPublicClient?.cedula_nacional && !validateCedula(currentPublicClient.cedula_nacional)}
                                    helperText={!!currentPublicClient?.cedula_nacional && !validateCedula(currentPublicClient.cedula_nacional) ? 'La cédula debe ser numérica y tener hasta 13 dígitos.' : ''}
                                    value={currentPublicClient?.cedula_nacional || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCurrentPublicClient(curr => curr ? { ...curr, cedula_nacional: value } : null);
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                    Correo Electrónico
                                </Typography>
                                <TextField
                                    margin="dense"
                                    label="Tu respuesta"
                                    type="email"
                                    fullWidth
                                    variant="standard" // Cambiado a "standard" para mostrar solo la línea inferior
                                    InputLabelProps={{
                                        sx: {
                                            fontFamily: 'Roboto, sans-serif',
                                            // Cambiar el color del label al estar enfocado
                                            '&.Mui-focused': {
                                                color: '#27BEC8', // Color del label al enfocar
                                            },
                                            // Cambiar el color del label en caso de error
                                            '&.Mui-error': {
                                                color: 'orange', // Color del label en caso de error
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'Roboto, sans-serif',
                                        },
                                        // Cambiar el color del borde al estar enfocado
                                        '& .MuiInputBase-root.Mui-focused:before': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        '& .MuiInputBase-root.Mui-focused:after': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        // Cambiar el color del borde si hay error
                                        '& .MuiInputBase-root.Mui-error:before': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                        '& .MuiInputBase-root.Mui-error:after': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                    }}
                                    error={!!currentPublicClient?.email && !validateEmail(currentPublicClient.email)}
                                    helperText={!!currentPublicClient?.email && !validateEmail(currentPublicClient.email) ? 'Ingrese un correo electrónico válido.' : ''}
                                    value={currentPublicClient?.email || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCurrentPublicClient(curr => curr ? { ...curr, email: value } : null);
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                    Teléfono de contacto principal
                                </Typography>
                                <TextField
                                    margin="dense"
                                    label="Tu respuesta"
                                    type="tel"
                                    fullWidth
                                    variant="standard" // Cambiado a "standard" para mostrar solo la línea inferior
                                    InputLabelProps={{
                                        sx: {
                                            fontFamily: 'Roboto, sans-serif',
                                            // Cambiar el color del label al estar enfocado
                                            '&.Mui-focused': {
                                                color: '#27BEC8', // Color del label al enfocar
                                            },
                                            // Cambiar el color del label en caso de error
                                            '&.Mui-error': {
                                                color: 'orange', // Color del label en caso de error
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'Roboto, sans-serif',
                                        },
                                        // Cambiar el color del borde al estar enfocado
                                        '& .MuiInputBase-root.Mui-focused:before': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        '& .MuiInputBase-root.Mui-focused:after': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        // Cambiar el color del borde si hay error
                                        '& .MuiInputBase-root.Mui-error:before': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                        '& .MuiInputBase-root.Mui-error:after': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                    }}
                                    error={!!currentPublicClient?.phoneNumber && !validatePhone(currentPublicClient.phoneNumber)}
                                    helperText={!!currentPublicClient?.phoneNumber && !validatePhone(currentPublicClient.phoneNumber) ? 'El teléfono debe ser numérico y tener hasta 13 dígitos.' : ''}
                                    value={currentPublicClient?.phoneNumber || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCurrentPublicClient(curr => curr ? { ...curr, phoneNumber: value } : null);
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                    Teléfono de contacto adicional
                                </Typography>
                                <TextField
                                    margin="dense"
                                    label="Tu respuesta"
                                    type="tel"
                                    fullWidth
                                    variant="standard" // Cambiado a "standard" para mostrar solo la línea inferior
                                    InputLabelProps={{
                                        sx: {
                                            fontFamily: 'Roboto, sans-serif',
                                            // Cambiar el color del label al estar enfocado
                                            '&.Mui-focused': {
                                                color: '#27BEC8', // Color del label al enfocar
                                            },
                                            // Cambiar el color del label en caso de error
                                            '&.Mui-error': {
                                                color: 'orange', // Color del label en caso de error
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'Roboto, sans-serif',
                                        },
                                        // Cambiar el color del borde al estar enfocado
                                        '& .MuiInputBase-root.Mui-focused:before': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        '& .MuiInputBase-root.Mui-focused:after': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        // Cambiar el color del borde si hay error
                                        '& .MuiInputBase-root.Mui-error:before': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                        '& .MuiInputBase-root.Mui-error:after': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                    }}
                                    error={!!currentPublicClient?.second_phoneNumber && !validatePhone(currentPublicClient.second_phoneNumber)}
                                    helperText={!!currentPublicClient?.second_phoneNumber && !validatePhone(currentPublicClient.second_phoneNumber) ? 'El teléfono secundario debe ser numérico y tener hasta 13 dígitos.' : ''}
                                    value={currentPublicClient?.second_phoneNumber || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCurrentPublicClient(curr => curr ? { ...curr, second_phoneNumber: value } : null);
                                    }}
                                />
                            </Box>

                            <Typography variant="h4" sx={{
                                alignItems: 'center',
                                color: 'black',
                                padding: '12px 20px',
                                justifyContent: 'center',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: 'bold',
                                fontSize: 'larger',
                                marginTop: '22px',
                            }}>
                                ¿Dónde vivís?
                            </Typography>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                    Provincia
                                </Typography>
                                <FormControl fullWidth margin="dense" sx={{
                                    '& .MuiInputLabel-root': {
                                        fontFamily: 'Roboto, sans-serif', // Tipo de letra para el label
                                    },
                                }}>
                                    <InputLabel
                                        sx={{ fontFamily: 'Roboto, sans-serif', marginTop: '-7px' }}
                                        style={{ display: currentPublicClient?.provincia || focus ? 'none' : 'block' }} // Controla la visibilidad
                                    >
                                        Elegir
                                    </InputLabel>
                                    <Select
                                        value={currentPublicClient?.provincia || ''}
                                        onFocus={() => setFocus(true)} // Agregar estado para manejar el foco
                                        onBlur={() => setFocus(false)} // Agregar estado para manejar el desenfoque
                                        onChange={(e) => {
                                            const selectedProvince = e.target.value as string;
                                            setCurrentPublicClient((curr: PublicClient | null) => {
                                                if (!curr) return null;
                                                const updatedClient = { ...curr, provincia: selectedProvince, canton: '', distrito: '', municipalidades: selectedProvince ? `Municipalidad de ${selectedProvince}` : '' };
                                                return updatedClient;
                                            });
                                            // Actualiza la lista de cantones según la provincia seleccionada
                                            setAvailableDistricts([]); // Resetea los distritos al cambiar provincia
                                            setAvailableCantons(costaRicaData.cantons[selectedProvince as keyof typeof costaRicaData.cantons] || []); // Actualiza los cantones
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    '& .MuiMenuItem-root': {
                                                        fontFamily: 'Roboto, sans-serif', // Tipo de letra para las opciones del menú
                                                    },
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                fontFamily: 'Roboto, sans-serif', // Tipo de letra para el texto seleccionado
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#27BEC8', // Cambia el color del borde al enfocar
                                            },
                                            width: '80%',
                                            height: '40px',
                                        }}
                                    >
                                        {costaRicaData.provinces.map((province) => (
                                            <MenuItem key={province} value={province}>{province}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                    Cantón
                                </Typography>
                                <FormControl fullWidth margin="dense" sx={{
                                    '& .MuiInputLabel-root': {
                                        fontFamily: 'Roboto, sans-serif', // Tipo de letra para el label
                                    },
                                }}>
                                    <InputLabel
                                        sx={{ fontFamily: 'Roboto, sans-serif', marginTop: '-7px' }}
                                        style={{ display: currentPublicClient?.canton || focusCanton ? 'none' : 'block' }} // Controla la visibilidad
                                    >
                                        Elegir
                                    </InputLabel>
                                    <Select
                                        value={currentPublicClient?.canton || ''}
                                        onFocus={() => setFocusCanton(true)} // Agregar estado para manejar el foco
                                        onBlur={() => setFocusCanton(false)} // Agregar estado para manejar el desenfoque
                                        onChange={(e) => {
                                            const selectedCanton = e.target.value as string;
                                            setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, canton: selectedCanton, distrito: '' } : null);
                                            // Actualiza distritos según el cantón seleccionado
                                            setAvailableDistricts(costaRicaData.districts[currentPublicClient?.provincia as keyof typeof costaRicaData.districts]?.[selectedCanton as keyof typeof costaRicaData.districts[keyof typeof costaRicaData.districts]] || []);
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    '& .MuiMenuItem-root': {
                                                        fontFamily: 'Roboto, sans-serif', // Tipo de letra para las opciones del menú
                                                    },
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                fontFamily: 'Roboto, sans-serif', // Tipo de letra para el texto seleccionado
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#27BEC8', // Cambia el color del borde al enfocar
                                            },
                                            width: '80%',
                                            height: '40px',
                                        }}
                                    >
                                        {availableCantons.map((canton) => (
                                            <MenuItem key={canton} value={canton}>{canton}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1 }}>
                                    Distrito
                                </Typography>
                                <FormControl fullWidth margin="dense" sx={{
                                    '& .MuiInputLabel-root': {
                                        fontFamily: 'Roboto, sans-serif', // Tipo de letra para el label
                                    },
                                }}>
                                    <InputLabel
                                        sx={{ fontFamily: 'Roboto, sans-serif', marginTop: '-7px' }}
                                        style={{ display: currentPublicClient?.distrito || focusDistrito ? 'none' : 'block' }} // Controla la visibilidad
                                    >
                                        Elegir
                                    </InputLabel>
                                    <Select
                                        value={currentPublicClient?.distrito || ''}
                                        onFocus={() => setFocusDistrito(true)} // Agregar estado para manejar el foco
                                        onBlur={() => setFocusDistrito(false)} // Agregar estado para manejar el desenfoque
                                        onChange={(e) => setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, distrito: e.target.value } : null)}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    '& .MuiMenuItem-root': {
                                                        fontFamily: 'Roboto, sans-serif', // Tipo de letra para las opciones del menú
                                                    },
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& .MuiSelect-select': {
                                                fontFamily: 'Roboto, sans-serif', // Tipo de letra para el texto seleccionado
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#27BEC8', // Cambia el color del borde al enfocar
                                            },
                                            width: '80%',
                                            height: '40px',
                                        }}
                                    >
                                        {availableDistricts.map((district) => (
                                            <MenuItem key={district} value={district}>{district}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1, lineHeight: '1.3' }}>
                                    Dirección exacta de tu domicilio y señas de tu casa:
                                </Typography>
                                <TextField
                                    margin="dense"
                                    label="Tu respuesta"
                                    type="text"
                                    fullWidth
                                    variant="standard" // Cambiado a "standard" para mostrar solo la línea inferior
                                    InputLabelProps={{
                                        sx: {
                                            fontFamily: 'Roboto, sans-serif',
                                            // Cambiar el color del label al estar enfocado
                                            '&.Mui-focused': {
                                                color: '#27BEC8', // Color del label al enfocar
                                            },
                                            // Cambiar el color del label en caso de error
                                            '&.Mui-error': {
                                                color: 'orange', // Color del label en caso de error
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'Roboto, sans-serif',
                                        },
                                        // Cambiar el color del borde al estar enfocado
                                        '& .MuiInputBase-root.Mui-focused:before': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        '& .MuiInputBase-root.Mui-focused:after': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        // Cambiar el color del borde si hay error
                                        '& .MuiInputBase-root.Mui-error:before': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                        '& .MuiInputBase-root.Mui-error:after': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                    }}
                                    error={!!currentPublicClient?.senas && !validateSenasNota(currentPublicClient.senas)} // Validación de señas
                                    helperText={!!currentPublicClient?.senas && !validateSenasNota(currentPublicClient.senas) ? 'Las señas pueden tener hasta 250 caracteres.' : ''}
                                    value={currentPublicClient?.senas || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCurrentPublicClient(curr => curr ? { ...curr, senas: value } : null);
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: '100%', // Ancho completo
                                    height: 'auto', // Altura automática para adaptarse al contenido
                                    display: 'flex',
                                    flexDirection: 'row', // Cambiado a fila para alinear el texto y el botón horizontalmente
                                    alignItems: 'center', // Alinear verticalmente al centro
                                    position: 'relative', // Para el hover
                                    backgroundColor: 'white', // Color de fondo blanco
                                    padding: 2, // Espaciado interno
                                    marginBottom: '16px',
                                }}
                            >
                                <Box sx={{ flex: 1 }}> {/* Added a Box to wrap the text */}
                                    <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: '-3px !important', lineHeight: '1.3' }}>
                                        Selecciona la ubicación<br />
                                        de tu lugar de recolecta:
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#AAAAAA', marginBottom: 1, fontSize: '0.77rem' }}>
                                        (casa, oficina, veterinaria)
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        width: '72px',
                                        height: '61px',
                                        backgroundColor: selectedLocation ? '#002243' : buttonColorMap,
                                        borderRadius: '22px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative',
                                        marginRight: selectedLocation ? '4px' : '20px',
                                        transition: 'background-color 0.3s ease', // Transición suave
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setButtonColorMap('#E5E5E5');
                                            setTimeout(() => {
                                                setButtonColorMap('#F5F5F5'); // Restaurar el color original después de un tiempo
                                            }, 200); // Cambia el tiempo según sea necesario
                                            handleLocationClick();
                                        }}
                                        sx={{
                                            borderRadius: '8px',
                                            padding: '10px',
                                            // Eliminar hover effect
                                            '&:hover': {
                                                backgroundColor: 'transparent', // Sin cambio de color al pasar el mouse
                                            },
                                        }}
                                    >
                                        <img src={`${process.env.PUBLIC_URL}/images/maps.png`} alt="Obtener ubicación" style={{ width: '35px', height: '35px' }} />
                                        <Box
                                            sx={{
                                                color: selectedLocation ? '#3E90FF' : '#707070', // Cambia el color del triángulo
                                                width: 0,
                                                height: 0,
                                                borderTop: '3px solid transparent',
                                                borderBottom: '3px solid transparent',
                                                borderLeft: '3px solid', // Cambia el color del triángulo
                                                position: 'absolute',
                                                right: '0px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                                {selectedLocation && ( // Verifica si hay una ubicación seleccionada

                                    <img

                                        src={`${process.env.PUBLIC_URL}/images/checkMap.png`} // Asegúrate de tener un ícono de check azul

                                        alt="Ubicación seleccionada"

                                        style={{
                                            top: '0',

                                            left: '40px', // Ajusta la posición según sea necesario

                                            width: '23px',
                                            height: '20px',
                                            zIndex: 1,
                                            padding: '4px 0px 0px 4px',
                                            marginRight: '-8px',
                                            marginBottom: '3px',

                                        }}

                                    />

                                )}

                            </Box>

                            <Box
                                sx={{
                                    width: '100%', // Ancho completo
                                    height: 'auto', // Altura automática para adaptarse al contenido
                                    display: 'flex',
                                    flexDirection: 'row', // Cambiado a fila para alinear el texto, icono e imagen horizontalmente
                                    alignItems: 'center', // Alinear verticalmente al centro
                                    position: 'relative', // Para el hover
                                    backgroundColor: 'white', // Color de fondo blanco
                                    padding: 2, // Espaciado interno
                                }}
                            >
                                <Box sx={{ flex: 1 }}> {/* Added a Box to wrap the text */}
                                    <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: 1, lineHeight: '1.3' }}>
                                        Añadí una foto de<br />
                                        la ubicación de recolecta:
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '72px',
                                        height: '61px',
                                        backgroundColor: currentPublicClient?.imageUrl ? '#002243' : (isClicked ? '#E5E5E5' : buttonColorImg), // Usar el nuevo estado
                                        borderRadius: '22px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative', // Cambiado a relative para centrar en el cuadrado
                                        marginRight: currentPublicClient?.imageUrl ? '4px' : '20px',
                                        transition: 'background-color 0.3s ease', // Transición suave
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setIsClicked(true); // Cambia el estado a clicado
                                            setButtonColorImg('#E5E5E5'); // Cambia el estado a clicado
                                            // Cambia el color de fondo
                                            setTimeout(() => {
                                                setIsClicked(false); // Restaura el estado después de 200ms
                                                setButtonColorImg('#F5F5F5'); // Restaura el estado después de 200ms
                                            }, 200);
                                        }}
                                        sx={{

                                            borderRadius: '8px',
                                            padding: '10px',
                                            // Eliminar hover effect
                                            '&:hover': {
                                                backgroundColor: 'transparent', // Sin cambio de color al pasar el mouse
                                            },
                                        }}

                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/subirImagen.png`}
                                            alt="Obtener imagen"
                                            style={{ width: '40px', height: '25px' }}
                                        />
                                    </IconButton>
                                    {/* Triángulo negro apuntando a la derecha */}
                                    <Box
                                        sx={{
                                            color: currentPublicClient?.imageUrl ? '#3E90FF' : '#707070', // Cambia el color de fondo según la selección
                                            width: 0,
                                            height: 0,
                                            borderTop: '3px solid transparent',
                                            borderBottom: '3px solid transparent',
                                            borderLeft: '3px solid', // Cambiado para que apunte a la derecha
                                            position: 'absolute',
                                            right: '8px', // Ajusta la posición según sea necesario
                                            top: '50%',
                                            transform: 'translateY(-50%)', // Centra verticalmente
                                        }}
                                    />
                                </Box>
                                {currentPublicClient?.imageUrl && ( // Verifica si hay una ubicación seleccionada

                                    <img

                                        src={`${process.env.PUBLIC_URL}/images/checkMap.png`} // Asegúrate de tener un ícono de check azul

                                        alt="Ubicación seleccionada"

                                        style={{
                                            top: '0',

                                            left: '40px', // Ajusta la posición según sea necesario

                                            width: '23px',
                                            height: '20px',
                                            zIndex: 1,
                                            padding: '4px 0px 0px 4px',
                                            marginRight: '-8px',
                                            marginBottom: '3px',

                                        }}

                                    />

                                )}
                                <label htmlFor="raised-button-file" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, cursor: 'pointer', opacity: 0 }} />
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={handleImageUploadPublic}
                                />
                            </Box>

                            <Typography variant="h4" sx={{
                                alignItems: 'center',
                                color: 'black',
                                padding: '12px 20px',
                                justifyContent: 'center',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: 'bold',
                                fontSize: 'larger',
                                marginTop: '22px',
                            }}>
                                ¿Cuántas mascotas tenés<br />
                                y de qué tamaño?
                            </Typography>
                            {['cant_gato', 'cant_perro_mini', 'cant_perro_pequeno', 'cant_perro_mediano', 'cant_perro_grande', 'cant_perro_gigante'].map((field, index) => (
                                <Box key={field} sx={{
                                    flex: 1,
                                    margin: '0px 0px 11px 0px',
                                    backgroundColor: 'white', // Color de fondo para cada carta de mascota
                                    borderRadius: '8px',
                                    padding: '19px', // Reducido para un diseño más compacto
                                    display: 'flex',
                                    alignItems: 'center', // Centrar elementos verticalmente
                                    width: '100%', // Asegura que todas las cartas tengan el mismo ancho
                                }}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/${field}.png`}
                                        alt={publicColumnNames[field as keyof PublicClient]}
                                        style={{
                                            width: '42px', // Tamaño reducido de la imagen
                                            height: '42px', // Tamaño reducido de la imagen
                                            marginRight: '13px', // Espacio entre la imagen y el texto
                                        }}
                                    />
                                    <Box sx={{ flex: '0 1 33%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', fontSize: '0.875rem' }}>
                                            {publicColumnNames[field as keyof PublicClient]?.split(' ')[0]}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontFamily: 'Roboto, sans-serif', fontSize: '0.75rem' }}>
                                            {publicColumnNames[field as keyof PublicClient]?.split(' ').slice(1).join(' ')}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1, marginTop: '0px !important' }}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: clickedButton === field + '-minus' ? '#E5E5E5' : '#F5F5F5',
                                            borderRadius: '14px',
                                            padding: '5px',
                                            width: '44px',
                                            height: '44px',
                                        }}>
                                            <IconButton
                                                onClick={() => {
                                                    handleQuantityChangePublic(field, false);
                                                    setClickedButton(field + '-minus'); // Cambia el estado del botón menos
                                                    // Cambia el color de fondo
                                                    setTimeout(() => {
                                                        setClickedButton(field); // Restaura el estado después de 200ms
                                                    }, 200);
                                                }}
                                                sx={{
                                                    color: 'black',
                                                    width: '24px', // Tamaño reducido del botón
                                                    height: '24px', // Tamaño reducido del botón
                                                    transition: 'background-color 0.3s ease', // Transición suave
                                                    marginLeft: '5px !important',
                                                    // Eliminar hover effect
                                                    '&:hover': {
                                                        backgroundColor: 'transparent', // Sin cambio de color al pasar el mouse
                                                    },
                                                }}
                                            >
                                                <RemoveIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        <TextField
                                            value={currentPublicClient?.[field as keyof PublicClient] || '0'}
                                            onChange={(e) => setCurrentPublicClient((curr: PublicClient | null) => curr ? { ...curr, [field]: e.target.value } : null)}
                                            inputProps={{
                                                style: { textAlign: 'center' },
                                                min: 0,
                                            }}
                                            sx={{
                                                width: '40px', // Ancho reducido del campo de texto
                                                '& .MuiInputBase-input': {
                                                    padding: '5px',
                                                    fontFamily: 'Roboto, sans-serif',
                                                    fontWeight: 'bold',
                                                    fontSize: '0.875rem', // Tamaño de fuente reducido
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'transparent',
                                                    },
                                                },
                                            }}
                                        />
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: clickedButton === field + '-plus' ? '#E5E5E5' : '#F5F5F5',
                                            borderRadius: '14px',
                                            padding: '5px',
                                            width: '44px',
                                            height: '44px',
                                        }}>
                                            <IconButton
                                                onClick={() => {
                                                    handleQuantityChangePublic(field, true);
                                                    setClickedButton(field + '-plus'); // Cambia el estado del botón más
                                                    // Cambia el color de fondo
                                                    setTimeout(() => {
                                                        setClickedButton(field); // Restaura el estado después de 200ms
                                                    }, 200);
                                                }}
                                                sx={{
                                                    color: 'black',
                                                    width: '24px', // Tamaño reducido del botón
                                                    height: '24px', // Tamaño reducido del botón
                                                    transition: 'background-color 0.3s ease', // Transición suave
                                                    marginLeft: '5px !important',
                                                    // Eliminar hover effect
                                                    '&:hover': {
                                                        backgroundColor: 'transparent', // Sin cambio de color al pasar el mouse
                                                    },
                                                }}
                                            >
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}

                            <Box sx={{
                                justifyContent: 'space-between',
                                backgroundColor: 'white', // Color de fondo suave
                                borderRadius: '8px',
                                padding: 2,
                                marginTop: '30px',
                            }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="subtitle1" sx={{ fontFamily: 'Roboto, sans-serif', marginBottom: '-3px !important', lineHeight: '1.3' }}>
                                        Si tenés un código de referencia,<br />
                                        indicálo acá:
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#AAAAAA', marginBottom: 1, fontSize: '0.67rem' }}> {/* Tamaño de fuente ajustado */}
                                        *Aplica si te recomendó uno de nuestros comercios afiliados
                                    </Typography>
                                </Box>
                                <TextField
                                    margin="dense"
                                    label="Tu respuesta"
                                    type="text"
                                    fullWidth
                                    variant="standard" // Cambiado a "standard" para mostrar solo la línea inferior
                                    InputLabelProps={{
                                        sx: {
                                            fontFamily: 'Roboto, sans-serif',
                                            // Cambiar el color del label al estar enfocado
                                            '&.Mui-focused': {
                                                color: '#27BEC8', // Color del label al enfocar
                                            },
                                            // Cambiar el color del label en caso de error
                                            '&.Mui-error': {
                                                color: 'orange', // Color del label en caso de error
                                            },
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'Roboto, sans-serif',
                                        },
                                        // Cambiar el color del borde al estar enfocado
                                        '& .MuiInputBase-root.Mui-focused:before': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        '& .MuiInputBase-root.Mui-focused:after': {
                                            borderBottom: 'solid 2px #27BEC8', // Color del borde al enfocar
                                        },
                                        // Cambiar el color del borde si hay error
                                        '& .MuiInputBase-root.Mui-error:before': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                        '& .MuiInputBase-root.Mui-error:after': {
                                            borderBottom: 'solid 2px orange', // Color del borde en caso de error
                                        },
                                    }}
                                    value={currentPublicClient?.codigo_referencia || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setCurrentPublicClient(curr => curr ? { ...curr, codigo_referencia: value } : null);
                                    }}
                                />
                            </Box>

                            <Box sx={{ height: '1069px', overflow: 'auto', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
                                <Box sx={{
                                    backgroundColor: 'white',
                                    padding: 2,
                                    marginTop: 2,
                                }}>
                                    <Box sx={{
                                        justifyContent: 'space-between', // Espacio entre las columnas
                                        marginBottom: '9px', // Espaciado inferior
                                    }}>
                                        <Box sx={{ padding: 2 }}> {/* Columna izquierda */}
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 2 }}>
                                                Términos y condiciones
                                            </Typography>
                                            <Box sx={{
                                                marginBottom: 2,
                                            }}> {/* Contenedor con scroll */}
                                                <Typography variant="body2" sx={{ marginBottom: 2, textAlign: 'justify' }} >
                                                    El <strong>pago inicial</strong> corresponde a la entrega del <strong>Kit de Popostaje</strong>. Este es un pago único de <strong>₡18,000.00</strong> +iva.
                                                </Typography>
                                                <Typography variant="body2" sx={{ marginBottom: 2, textAlign: 'justify' }} >
                                                    El pago mensual incluye: <strong>recolección</strong>, <strong>traslado</strong> y <strong>abono</strong>. Tiene un valor de <strong>₡13,000.00</strong> +iva, programado para el día <strong>01 ó 15</strong> de cada mes.
                                                </Typography>
                                                <Typography variant="body2" sx={{ marginBottom: 2, textAlign: 'justify' }} >
                                                    El sistema realizará <strong>tres intentos de pago</strong> en <strong>48 horas</strong>. Si no se logra, recibirá un <strong>correo electrónico</strong> con el resultado.
                                                </Typography>
                                                <Typography variant="body2" sx={{ marginBottom: 2, textAlign: 'justify' }} >
                                                    Si el pago es <strong>declinado</strong>, se generará un recargo del <strong>10%</strong>. Asegúrese de tener <strong>fondos suficientes</strong> en la fecha programada.
                                                </Typography>
                                            </Box>

                                            <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 2 }}>
                                                Duración del Servicio
                                            </Typography>
                                            <Box sx={{
                                                marginBottom: 2,
                                            }}> {/* Contenedor con scroll */}
                                                <Typography variant="body2" sx={{ marginBottom: 2, textAlign: 'justify' }} >
                                                    El servicio tiene un compromiso mínimo de <strong>seis meses</strong>. Si desea cancelarlo, deberá notificarlo con un mes de anticipación, durante el cual se coordinará el último pago y la última recolección.
                                                </Typography>
                                                <Typography variant="body2" sx={{ marginBottom: 2, textAlign: 'justify' }} >
                                                    Dado que la <strong>cubeta con desechos</strong> es material contaminante, es responsabilidad social y ambiental asegurar su manejo adecuado. Al recibir el kit, usted se compromete a entregarla al personal de <strong>Ollín</strong> durante la recolección.
                                                </Typography>
                                                <Typography variant="body2" sx={{ marginBottom: 2, textAlign: 'justify' }} >
                                                    Las cubetas son propiedad de <strong>Ollín</strong> y llevan la etiqueta y logo de la empresa. En caso de daño o de no reintegrarla, se aplicará una <strong>multa de ₡10,000</strong>. En este último caso, Ollín no será responsable por un uso inadecuado de la cubeta o su contenido.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center', // Centrar horizontalmente
                                        justifyContent: 'center', // Centrar verticalmente
                                        textAlign: 'center', // Asegura que el texto esté centrado
                                        marginBottom: '8px',
                                        fontSize: '14px',
                                        marginRight: '20px',
                                    }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={termsAccepted}
                                                    onChange={(e) => setTermsAccepted(e.target.checked)} // Update state on checkbox change
                                                    color="primary" // Cambia el color a uno que se ajuste a tu paleta
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#27BEC8', // Color del checkbox cuando está seleccionado
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '0.85rem' }}> {/* Cambia el color del texto del label */}
                                                    Acepto términos y condiciones.
                                                </Typography>
                                            }
                                        />
                                    </Box>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bottom: 0,
                                    left: '50%',
                                    width: '100%',
                                    px: 3,
                                    py: 2,
                                    padding: '29px 0px 29px 0px',
                                }}>
                                    <Button
                                        onClick={() => {
                                            setButtonColorEnviar('#169CA5');
                                            setTimeout(() => {
                                                setButtonColorEnviar('#27BEC8'); // Restaurar el color original después de un tiempo
                                            }, 200); // Cambia el tiempo según sea necesario
                                            confirmCreateClient();
                                        }}
                                        disabled={!isFormValid() || !termsAccepted}
                                        sx={{
                                            backgroundColor: (!isFormValid() || !termsAccepted) ? '#D7E6EB' : buttonColorEnviar,
                                            color: 'white',
                                            fontFamily: 'Roboto, sans-serif',
                                            px: 4,
                                            py: 2, // Aumentar el padding vertical para mayor tamaño
                                            borderRadius: '25px', // Ajustar el radio de borde
                                            height: '50px', // Altura fija
                                            marginLeft: '0',
                                            width: 'inherit',
                                            // Eliminar hover effect
                                            '&:hover': {
                                                backgroundColor: (!isFormValid() || !termsAccepted) ? '#D7E6EB' : buttonColorEnviar, // Mantener el color al pasar el mouse
                                            },
                                        }}
                                    >
                                        <Typography variant="button" sx={{ fontWeight: 'bold', textTransform: 'none' }}>
                                            Enviar
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>

                        </Box>

                        <style>
                            {`
            @keyframes move {
                0% { transform: translateY(0); }
                100% { transform: translateY(-5px); }
            }
        `}
                        </style>
                        {/* Error Snackbar */}
                        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClearError}>
                            <Alert onClose={handleClearError} severity="error" sx={{ width: '100%' }}>
                                {error}
                            </Alert>
                        </Snackbar>
                        {/* Success Snackbar */}
                        <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage(null)}>
                            <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%', backgroundColor: 'green', color: 'white' }}>
                                {successMessage}
                            </Alert>
                        </Snackbar>
                    </Box>
                    <Dialog
                        open={openConfirmationDialog}
                        onClose={() => setOpenConfirmationDialog(false)}
                        fullWidth
                        maxWidth="sm"
                        BackdropProps={{
                            sx: {
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            },
                        }}
                        PaperProps={{
                            sx: {
                                borderRadius: '8px',
                            },
                        }}
                    >
                        <DialogTitle sx={{
                            backgroundColor: '#87CEEB',
                            color: 'white',
                            fontWeight: 'bold'
                        }}>
                            Confirmar Creación de Cliente
                        </DialogTitle>
                        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4, paddingTop: '29px !important' }}>
                            <Box sx={{
                                backgroundColor: 'green',
                                borderRadius: '50%',
                                padding: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 2
                            }}>
                                <PersonAddIcon sx={{ color: 'white', fontSize: 40 }} />
                            </Box>
                            <Typography variant="h6" align="center" sx={{ color: '#333', fontWeight: 'bold' }}>
                                ¿Está seguro de que desea crear este cliente como definitivo?
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{
                            justifyContent: 'center',
                            px: 3,
                            py: 2,
                            backgroundColor: 'rgba(135, 206, 235, 0.1)'
                        }}>
                            <Button onClick={() => setOpenConfirmationDialog(false)}
                                variant="outlined"
                                sx={{
                                    color: '#87CEEB',
                                    borderColor: '#87CEEB',
                                    '&:hover': {
                                        backgroundColor: 'rgba(135, 206, 235, 0.1)',
                                        borderColor: '#5CACEE',
                                    },
                                    fontWeight: 'bold',
                                    px: 4,
                                    py: 1,
                                    mr: 2,
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button onClick={confirmCreateClient} // Llama a la función para crear el cliente
                                variant="contained"
                                sx={{
                                    backgroundColor: '#87CEEB',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#5CACEE',
                                    },
                                    fontWeight: 'bold',
                                    px: 4,
                                    py: 1,
                                }}>
                                Crear Cliente
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openSuccessDialog} onClose={() => {
                        setOpenSuccessDialog(false);
                        window.location.reload(); // Recargar la página actual
                    }} PaperProps={{ // Agregado para aplicar estilos al papel del diálogo
                        sx: {
                            borderRadius: '22px', // Borde redondeado de 16px
                        },
                    }}>
                        <DialogTitle sx={{
                            backgroundColor: 'white',
                            color: 'black',
                            fontWeight: 'bold',
                            height: '77%',
                            textAlign: 'center',
                            marginTop: '13px',
                        }}>
                            <img src={`${process.env.PUBLIC_URL}/images/iconCheck.png`} alt="iconCheck" style={{ width: '35px', height: '35px' }} />
                            <br />
                            ¡Hemos recibido tus datos!
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" sx={{ textAlign: 'center', marginTop: 2, fontSize: '0.85rem' }}>
                                Te contactaremos por WhatsApp<br />
                                para confirmar el día de tu entrega.
                                <br />
                                <br />
                                ¡Nos vemos pronto! 🐶🐱♻️✨
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{
                            justifyContent: 'center',
                            px: 3,
                            py: 2,
                            backgroundColor: 'white'
                        }}>
                            <Button onClick={() => {
                                setButtonColorrCerraLastOne('#D7E6EB');
                                setTimeout(() => {
                                    setButtonColorrCerraLastOne('#F3F9FB'); // Restaurar el color original después de un tiempo
                                }, 200); // Cambia el tiempo según sea necesario
                                setOpenSuccessDialog(false);
                                window.location.reload(); // Recargar la página actual
                            }}
                                sx={{
                                    backgroundColor: buttonColorCerraLastOne,
                                    color: '#6C7A8B',
                                    border: 'none',
                                    fontWeight: 'bold',
                                    px: 4,
                                    py: 1,
                                    mr: 2,
                                    borderRadius: '22px',
                                    width: '239px',
                                    // Eliminar hover effect
                                    '&:hover': {
                                        backgroundColor: buttonColorCerraLastOne, // Sin cambio de color al pasar el mouse
                                    },
                                }}
                            >
                                Cerrar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openLocationIcon}
                        onClose={() => setOpenLocationIcon(false)}
                        PaperProps={{
                            sx: {
                                width: '293px',
                                borderRadius: '11px',
                            },
                        }}
                    >
                        <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: '18px' }}>
                            <img src={`${process.env.PUBLIC_URL}/images/iconoMoradoMapa.png`} alt="Icono Morado Mapa" style={{ width: '36px', height: '46px', marginRight: '8px', justifySelf: 'center', marginBottom: '6px' }} />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#333' }}>
                                ¿Querés actualizar<br />
                                tu ubicación?
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}> {/* Contenedor con padding */}
                                <DialogContentText sx={{
                                    margin: 0,
                                    color: 'black', // Color de texto más oscuro para mejor contraste
                                    fontWeight: '500', // Peso de fuente más ligero
                                }}>
                                    Asegurate de que tu locación<br />
                                    seleccionada sea la correcta.
                                </DialogContentText>
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'center', marginBottom: '8px' }}>
                            <Button onClick={() => setOpenLocationIcon(false)} sx={{
                                color: '#6C7A8B',
                                borderColor: 'white',
                                backgroundColor: 'white',
                                fontWeight: 'bold',
                                px: 4,
                                py: 1,
                                borderRadius: '25px',
                                textTransform: 'none'
                            }}>
                                Cancelar
                            </Button>
                            <Button onClick={confirmLocationUpdate} sx={{
                                backgroundColor: buttonColor,
                                fontWeight: 'bold',
                                color: '#6C7A8B',
                                px: 4,
                                py: 1,
                                borderRadius: '25px',
                                textTransform: 'none'
                            }}>
                                Actualizar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Grid item xs={12}>
                        <Dialog
                            open={openLocationDialog} // Controla la apertura del diálogo
                            onClose={() => setOpenLocationDialog(false)} // Cierra el diálogo
                            PaperProps={{
                                sx: {
                                    borderRadius: '8px', // Bordes redondeados
                                    width: '333px',
                                },
                            }}
                        >
                            <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: '18px' }}>
                                Selecciona tu ubicación
                            </DialogTitle>
                            <DialogContent>
                                {selectedLocation ? ( // Verifica si hay una ubicación seleccionada
                                    <MapContainer center={selectedLocation} zoom={15} style={{ height: '400px', width: 'auto', borderRadius: '8px' }}>
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        <MapEventHandler onClick={handleMapClick} />
                                        <Marker position={selectedLocation} icon={new L.Icon({ // Usar un nuevo ícono
                                            iconUrl: `${process.env.PUBLIC_URL}/images/puntoMapa.png`,
                                            iconSize: [29, 35], // Tamaño del ícono
                                            popupAnchor: [0, -35] // Punto de anclaje del popup
                                        })}>
                                            {/* <Popup>
                                                <div style={{ textAlign: 'center', padding: '10px', backgroundColor: '#f0f8ff', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)' }}>
                                                    <strong style={{ color: '#333', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' }}>Ubicación seleccionada</strong>
                                                    <br />
                                                    <a href={`https://www.google.com/maps/search/?api=1&query=${selectedLocation.lat},${selectedLocation.lng}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#007bff', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px', transition: 'background-color 0.3s', padding: '5px', borderRadius: '4px', fontFamily: 'Roboto, sans-serif' }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0f7fa'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                                                        <img src={`${process.env.PUBLIC_URL}/images/maps.png`} alt="Maps" style={{ width: '35px', height: '35px', marginRight: '5px' }} />
                                                        Google Maps
                                                    </a>
                                                    <a href={`https://waze.com/ul?q=${selectedLocation.lat},${selectedLocation.lng}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#007bff', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'background-color 0.3s', padding: '5px', borderRadius: '4px', fontFamily: 'Roboto, sans-serif' }} onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e0f7fa'} onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}>
                                                        <img src={`${process.env.PUBLIC_URL}/images/waze.png`} alt="Waze" style={{ width: '40px', height: '40px', marginRight: '5px' }} />
                                                        Waze
                                                    </a>
                                                </div>
                                            </Popup> */}
                                        </Marker>
                                    </MapContainer>
                                ) : ( // Muestra el ícono de carga si no hay ubicación seleccionada
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                                        <CircularProgress /> {/* Ícono de carga */}
                                    </Box>
                                )}
                                <style>
                                    {`
                                    .leaflet-bottom.leaflet-right {
                                        display: none; /* Oculta la atribución de Leaflet */
                                    }
                                `}
                                </style>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'center' }}>
                                <Button onClick={() => {
                                    setButtonColorGuardado('#D7E6EB');
                                    setTimeout(() => {
                                        setButtonColorGuardado('#F3F9FB'); // Restaurar el color original después de un tiempo
                                    }, 200); // Cambia el tiempo según sea necesario
                                    setOpenLocationDialog(false)
                                }} sx={{
                                    color: '#6C7A8B',
                                    backgroundColor: buttonColorGuardado,
                                    fontWeight: 'bold',
                                    px: 4,
                                    py: 1,
                                    borderRadius: '25px',
                                    width: '88%',
                                    marginBottom: '6px',
                                    textTransform: 'none'
                                }}>
                                    Guardar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Box>
            </Container >
        </Box >
    );
};


export default PublicClientDialog;