import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Slider,
    Checkbox,
    ListItemText,
    FormControlLabel,
    Chip,
    FormGroup,
    Tabs,
    Tab,
    Switch,
} from '@mui/material';
import {
    Search as SearchIcon,
    FilterList as FilterIcon,
    GetApp as DownloadIcon,
    Print as PrintIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    ViewColumn as ViewColumnIcon,
    Add as AddIcon,
    CloudUpload as CloudUploadIcon,
    Close as CloseIcon,
} from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';

interface InventoryItem {
    id: string;
    name: string;
    price: number;
    quantity: number;
    imageUrl: string;
    category: string;
    description?: string;
    color?: string;
    tamano?: string;
}

interface InventoryManagementProps {
    inventoryItems: InventoryItem[];
    onUpdateItem: (item: InventoryItem) => Promise<void>;
    onDeleteItem: (id: string) => Promise<void>;
    onAddItem: (item: Omit<InventoryItem, 'id'>) => Promise<void>;
}

const InventoryManagement: React.FC<InventoryManagementProps> = ({
    inventoryItems,
    onUpdateItem,
    onDeleteItem,
    onAddItem
}) => {
    // Estados para la lista de cartas
    const [cardFilteredItems, setCardFilteredItems] = useState<InventoryItem[]>(inventoryItems);
    const [cardSearchTerm, setCardSearchTerm] = useState('');
    const [cardCategoryFilter, setCardCategoryFilter] = useState('Todos');
    const [cardQuantityRange, setCardQuantityRange] = useState<[number, number]>([1, 5500]);
    const [cardAppliedFilters, setCardAppliedFilters] = useState<{
        category: string | null;
        quantityRange: [number, number] | null;
    }>({
        category: null,
        quantityRange: null,
    });

    // Estados para la tabla
    const [tableFilteredItems, setTableFilteredItems] = useState<InventoryItem[]>(inventoryItems);
    const [tableSearchTerm, setTableSearchTerm] = useState('');
    const [tableCategoryFilter, setTableCategoryFilter] = useState('Todos');
    const [tablePriceRange, setTablePriceRange] = useState<[number, number]>([0, 132000]);
    const [tableQuantityRange, setTableQuantityRange] = useState<[number, number]>([1, 500]);
    const [tableAppliedFilters, setTableAppliedFilters] = useState<{
        category: string | null;
        priceRange: [number, number] | null;
        quantityRange: [number, number] | null;
    }>({
        category: null,
        priceRange: null,
        quantityRange: null,
    });

    // Estados comunes
    const [selectedItem, setSelectedItem] = useState<InventoryItem | null>(null);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
    const [isViewDetailsOpen, setIsViewDetailsOpen] = useState(false);
    const [newItem, setNewItem] = useState<Omit<InventoryItem, 'id'>>({
        name: '',
        price: 0,
        quantity: 0,
        imageUrl: '',
        category: '',
        description: '',
        color: '',
        tamano: '',
    });

    // Estados para la tabla
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState<keyof InventoryItem>('name');
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [visibleColumns, setVisibleColumns] = useState<Set<string>>(new Set(['name', 'category', 'price', 'quantity']));

    // Nuevos estados para diálogos
    const [isQuantityRangeDialogOpen, setIsQuantityRangeDialogOpen] = useState(false);
    const [isTableFilterDialogOpen, setIsTableFilterDialogOpen] = useState(false);
    const [isColumnSelectionDialogOpen, setIsColumnSelectionDialogOpen] = useState(false);

    // Nuevo estado para controlar las pestañas
    const [tabValue, setTabValue] = useState(0);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const categories = ['Todos', 'Kits de popostaje perros', 'Kits de popostaje gatos', 'Accesorios', 'Productos de mantenimiento'];

    useEffect(() => {
        applyCardFilters();
    }, [inventoryItems, cardSearchTerm, cardAppliedFilters]);

    useEffect(() => {
        applyTableFilters();
    }, [inventoryItems, tableSearchTerm, tableAppliedFilters]);

    const applyCardFilters = () => {
        let filtered = inventoryItems.filter(item =>
            item.name.toLowerCase().includes(cardSearchTerm.toLowerCase())
        );

        if (cardAppliedFilters.category && cardAppliedFilters.category !== 'Todos') {
            filtered = filtered.filter(item => item.category === cardAppliedFilters.category);
        }

        if (cardAppliedFilters.quantityRange) {
            const [min, max] = cardAppliedFilters.quantityRange;
            filtered = filtered.filter(item =>
                item.quantity >= min && item.quantity <= max
            );
        }

        setCardFilteredItems(filtered);
    };

    const applyTableFilters = () => {
        let filtered = inventoryItems.filter(item =>
            Object.values(item).some(value =>
                value.toString().toLowerCase().includes(tableSearchTerm.toLowerCase())
            )
        );

        if (tableAppliedFilters.category && tableAppliedFilters.category !== 'Todos') {
            filtered = filtered.filter(item => item.category === tableAppliedFilters.category);
        }

        if (tableAppliedFilters.priceRange) {
            const [min, max] = tableAppliedFilters.priceRange;
            filtered = filtered.filter(item =>
                item.price >= min && item.price <= max
            );
        }

        if (tableAppliedFilters.quantityRange) {
            const [min, max] = tableAppliedFilters.quantityRange;
            filtered = filtered.filter(item =>
                item.quantity >= min && item.quantity <= max
            );
        }

        setTableFilteredItems(filtered);
    };

    const handleRequestSort = (property: keyof InventoryItem) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const storageRef = ref(storage, `product-images/${file.name}`);
            try {
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);
                if (selectedItem) {
                    setSelectedItem({ ...selectedItem, imageUrl: downloadURL });
                } else {
                    setNewItem({ ...newItem, imageUrl: downloadURL });
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                toast.error('Error al subir la imagen');
            }
        }
    };

    const handleSaveEdit = async () => {
        if (selectedItem) {
            try {
                await onUpdateItem(selectedItem);
                setIsViewDialogOpen(false);
                setSelectedItem(null);
                toast.success('Producto actualizado exitosamente');
            } catch (error) {
                console.error('Error updating item:', error);
                toast.error('Error al actualizar el producto');
            }
        }
    };

    const handleDeleteItem = async (id: string) => {
        if (window.confirm('¿Está seguro de que desea eliminar este producto?')) {
            try {
                await onDeleteItem(id);
                toast.success('Producto eliminado exitosamente');
            } catch (error) {
                console.error('Error deleting item:', error);
                toast.error('Error al eliminar el producto');
            }
        }
    };

    const handleAddItem = async () => {
        try {
            await onAddItem(newItem);
            setIsAddDialogOpen(false);
            setNewItem({
                name: '',
                price: 0,
                quantity: 0,
                imageUrl: '',
                category: '',
                description: '',
                color: '',
                tamano: '',
            });
            toast.success('Nuevo producto agregado exitosamente');
        } catch (error) {
            console.error('Error adding new item:', error);
            toast.error('Error al agregar el nuevo producto');
        }
    };

    const handleEditItem = (item: InventoryItem) => {
        setSelectedItem({ ...item });
        setIsViewDialogOpen(true);
    };

    const handleViewDetails = (item: InventoryItem) => {
        setSelectedItem(item);
        setIsViewDetailsOpen(true);
    };

    const handlePrint = () => {
        window.print();
    };

    const handleOpenQuantityRangeDialog = () => {
        setIsQuantityRangeDialogOpen(true);
    };

    const handleCloseQuantityRangeDialog = () => {
        setIsQuantityRangeDialogOpen(false);
    };

    const handleApplyQuantityRange = () => {
        setCardAppliedFilters(prev => ({ ...prev, quantityRange: cardQuantityRange }));
        handleCloseQuantityRangeDialog();
    };

    const handleOpenTableFilterDialog = () => {
        setIsTableFilterDialogOpen(true);
    };

    const handleCloseTableFilterDialog = () => {
        setIsTableFilterDialogOpen(false);
    };

    const handleApplyTableFilters = () => {
        setTableAppliedFilters({
            category: tableCategoryFilter === 'Todos' ? null : tableCategoryFilter,
            priceRange: tablePriceRange,
            quantityRange: tableQuantityRange,
        });
        handleCloseTableFilterDialog();
    };

    const handleOpenColumnSelectionDialog = () => {
        setIsColumnSelectionDialogOpen(true);
    };

    const handleCloseColumnSelectionDialog = () => {
        setIsColumnSelectionDialogOpen(false);
    };

    const handleColumnToggle = (column: string) => {
        setVisibleColumns(prev => {
            const newSet = new Set(prev);
            if (newSet.has(column)) {
                newSet.delete(column);
            } else {
                newSet.add(column);
            }
            return newSet;
        });
    };

    // Función para manejar el cambio de pestañas
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ padding: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setIsAddDialogOpen(true)}
                    sx={{
                        mb: 3, backgroundColor: '#87CEEB',
                        '&:hover': {
                            backgroundColor: 'rgb(135, 206, 235)',
                        },
                        fontWeight: 'bold'
                    }}
                >
                    AGREGAR PRODUCTO
                </Button>
                <FormControlLabel
                    control={
                        <Switch
                            checked={tabValue === 1}
                            onChange={() => setTabValue(tabValue === 0 ? 1 : 0)}
                            color="primary"
                            sx={{
                                '&.MuiSwitch-root': {
                                    '& .MuiSwitch-thumb': {
                                        backgroundColor: 'rgb(135, 206, 235) !important',
                                    }
                                },
                            }}
                        />
                    }
                    label={
                        <span style={{ fontWeight: 'bold' }}>
                            {tabValue === 0 ? "Tabla de Productos" : "Lista de Cartas"}
                        </span>
                    }
                    sx={{ mb: 2, marginBottom: '21px' }}
                />
            </Box>

            {/* <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
                <Tab label="Tabla de Productos" />
                <Tab label="Lista de Cartas" />
            </Tabs> */}

            {tabValue === 0 && (
                // Contenido de la pestaña de tabla
                <Box>
                    {/* Filtros para la tabla */}
                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Buscar..."
                            value={tableSearchTerm}
                            onChange={(e) => setTableSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                            }}
                            sx={{ alignItems: 'left' }}
                        />
                        <Box>
                            <Tooltip title="Filtrar">
                                <IconButton onClick={handleOpenTableFilterDialog}>
                                    <FilterIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Seleccionar columnas">
                                <IconButton onClick={handleOpenColumnSelectionDialog}>
                                    <ViewColumnIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Imprimir">
                                <IconButton onClick={handlePrint}>
                                    <PrintIcon />
                                </IconButton>
                            </Tooltip>
                            <CSVLink data={tableFilteredItems} filename="inventario.csv">
                                <Tooltip title="Descargar CSV">
                                    <IconButton>
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            </CSVLink>
                        </Box>
                    </Box>

                    {/* Applied filters for table */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                        {tableAppliedFilters.category && (
                            <Chip
                                label={`Categoría: ${tableAppliedFilters.category}`}
                                onDelete={() => setTableAppliedFilters(prev => ({ ...prev, category: null }))}
                                variant="outlined"
                                sx={{ mr: 1, backgroundColor: '#87CEEB', color: 'white', borderColor: '#87CEEB' }}
                            />
                        )}
                        {tableAppliedFilters.priceRange && (
                            <Chip
                                label={`Precio: ₡${tableAppliedFilters.priceRange[0]} - ₡${tableAppliedFilters.priceRange[1]}`}
                                onDelete={() => setTableAppliedFilters(prev => ({ ...prev, priceRange: null }))}
                                variant="outlined"
                                sx={{ mr: 1, backgroundColor: '#87CEEB', color: 'white', borderColor: '#87CEEB' }}
                            />
                        )}
                        {tableAppliedFilters.quantityRange && (
                            <Chip
                                label={`Cantidad: ${tableAppliedFilters.quantityRange[0]} - ${tableAppliedFilters.quantityRange[1]}`}
                                onDelete={() => setTableAppliedFilters(prev => ({ ...prev, quantityRange: null }))}
                                variant="outlined"
                                sx={{ mr: 1, backgroundColor: '#87CEEB', color: 'white', borderColor: '#87CEEB' }}
                            />
                        )}
                    </Box>

                    {/* Tabla */}
                    <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                        <Table sx={{ minWidth: 650 }} aria-label="inventory table">
                            <TableHead sx={{ backgroundColor: '#87CEEB' }}>
                                <TableRow>
                                    {visibleColumns.has('name') && (
                                        <TableCell>
                                            <TableSortLabel
                                                active={orderBy === 'name'}
                                                direction={orderBy === 'name' ? order : 'asc'}
                                                onClick={() => handleRequestSort('name')}
                                                sx={{ color: 'white', fontWeight: 'bold' }}
                                            >
                                                Nombre
                                            </TableSortLabel>
                                        </TableCell>
                                    )}
                                    {visibleColumns.has('category') && <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Categoría</TableCell>}
                                    {visibleColumns.has('price') && (
                                        <TableCell align="right">
                                            <TableSortLabel
                                                active={orderBy === 'price'}
                                                direction={orderBy === 'price' ? order : 'asc'}
                                                onClick={() => handleRequestSort('price')}
                                                sx={{ color: 'white', fontWeight: 'bold' }}
                                            >
                                                Precio
                                            </TableSortLabel>
                                        </TableCell>
                                    )}
                                    {visibleColumns.has('quantity') && (
                                        <TableCell align="right">
                                            <TableSortLabel
                                                active={orderBy === 'quantity'}
                                                direction={orderBy === 'quantity' ? order : 'asc'}
                                                onClick={() => handleRequestSort('quantity')}
                                                sx={{ color: 'white', fontWeight: 'bold' }}
                                            >
                                                Cantidad
                                            </TableSortLabel>
                                        </TableCell>
                                    )}
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Imagen</TableCell>
                                    <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableFilteredItems
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow key={row.id}>
                                            {visibleColumns.has('name') && <TableCell component="th" scope="row">{row.name}</TableCell>}
                                            {visibleColumns.has('category') && <TableCell>{row.category}</TableCell>}
                                            {visibleColumns.has('price') && <TableCell align="right">₡{row.price.toLocaleString()}</TableCell>}
                                            {visibleColumns.has('quantity') && <TableCell align="right">{row.quantity}</TableCell>}
                                            <TableCell>
                                                <img src={row.imageUrl} alt={row.name} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton size="small" onClick={() => handleViewDetails(row)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                                <IconButton size="small" onClick={() => handleEditItem(row)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton size="small" onClick={() => handleDeleteItem(row.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={tableFilteredItems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Filas por página:"
                    />
                </Box>
            )}

            {tabValue === 1 && (
                // Contenido de la pestaña de cartas
                <Box>
                    {/* Filtros para la lista de cartas */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Buscar..."
                            value={cardSearchTerm}
                            onChange={(e) => setCardSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                            }}
                            sx={{ alignItems: 'left' }}
                        />
                        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                            <InputLabel>Categoría</InputLabel>
                            <Select
                                value={cardCategoryFilter}
                                onChange={(e: SelectChangeEvent<string>) => {
                                    const category = e.target.value;
                                    setCardCategoryFilter(category);
                                    setCardAppliedFilters(prev => ({
                                        ...prev,
                                        category: category === 'Todos' ? null : category
                                    }));
                                }}
                                label="Categoría"
                            >
                                {categories.map((category) => (
                                    <MenuItem key={category} value={category}>{category}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Tooltip title="Filtrar por cantidad">
                            <IconButton onClick={handleOpenQuantityRangeDialog}>
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* Applied filters for card list */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                        {cardAppliedFilters.category && (
                            <Chip
                                label={`Categoría: ${cardAppliedFilters.category}`}
                                onDelete={() => setCardAppliedFilters(prev => ({ ...prev, category: null }))}
                                variant="outlined"
                                sx={{ mr: 1, backgroundColor: '#87CEEB', color: 'white', borderColor: '#87CEEB' }}
                            />
                        )}
                        {cardAppliedFilters.quantityRange && (
                            <Chip
                                label={`Cantidad: ${cardAppliedFilters.quantityRange[0]} - ${cardAppliedFilters.quantityRange[1]}`}
                                onDelete={() => setCardAppliedFilters(prev => ({ ...prev, quantityRange: null }))}
                                variant="outlined"
                                sx={{ mr: 1, backgroundColor: '#87CEEB', color: 'white', borderColor: '#87CEEB' }}
                            />
                        )}
                    </Box>

                    {/* Lista de cartas */}
                    <Grid container spacing={2}>
                        {cardFilteredItems.map((item) => (
                            <Grid item xs={12} sm={6} md={4} lg={2} key={item.id}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3, borderRadius: 2 }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={item.imageUrl}
                                        alt={item.name}
                                        sx={{ objectFit: 'contain', p: 2 }}
                                    />
                                    <CardContent sx={{ flexGrow: 1, p: 2 }}>
                                        <Typography gutterBottom variant="subtitle1" component="div" noWrap>
                                            {item.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Stock: {item.quantity}
                                        </Typography>
                                        <Typography variant="h6" color="#00b4d8">
                                            ₡{item.price.toLocaleString()}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                                        <IconButton size="small" onClick={() => handleViewDetails(item)} sx={{ color: '#00b4d8' }}>
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => handleEditItem(item)} sx={{ color: '#00b4d8' }}>
                                            <EditIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            {/* Quantity Range Dialog for Card List */}
            <Dialog open={isQuantityRangeDialogOpen} onClose={handleCloseQuantityRangeDialog}>
                <DialogTitle>Filtrar por Cantidad</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: 300 }}>
                        <Typography gutterBottom>Rango de Cantidad</Typography>
                        <Slider
                            value={cardQuantityRange}
                            onChange={(_, newValue) => setCardQuantityRange(newValue as [number, number])}
                            valueLabelDisplay="auto"
                            min={1}
                            max={500}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseQuantityRangeDialog}>Cancelar</Button>
                    <Button onClick={handleApplyQuantityRange} color="primary">
                        Aplicar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Table Filter Dialog */}
            <Dialog open={isTableFilterDialogOpen} onClose={handleCloseTableFilterDialog}>
                <DialogTitle>Filtros de Tabla</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Categoría</InputLabel>
                        <Select
                            value={tableCategoryFilter}
                            onChange={(e) => setTableCategoryFilter(e.target.value)}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category} value={category}>{category}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box sx={{ width: 300, mt: 3 }}>
                        <Typography gutterBottom>Rango de Precio</Typography>
                        <Slider
                            value={tablePriceRange}
                            onChange={(_, newValue) => setTablePriceRange(newValue as [number, number])}
                            valueLabelDisplay="auto"
                            min={0}
                            max={132000}
                        />
                    </Box>
                    <Box sx={{ width: 300, mt: 3 }}>
                        <Typography gutterBottom>Rango de Cantidad</Typography>
                        <Slider
                            value={tableQuantityRange}
                            onChange={(_, newValue) => setTableQuantityRange(newValue as [number, number])}
                            valueLabelDisplay="auto"
                            min={1}
                            max={500}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTableFilterDialog}>Cancelar</Button>
                    <Button onClick={handleApplyTableFilters} color="primary">
                        Aplicar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Column Selection Dialog */}
            <Dialog open={isColumnSelectionDialogOpen} onClose={handleCloseColumnSelectionDialog}>
                <DialogTitle>Seleccionar Columnas</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        {['name', 'category', 'price', 'quantity'].map((column) => (
                            <FormControlLabel
                                key={column}
                                control={
                                    <Checkbox
                                        checked={visibleColumns.has(column)}
                                        onChange={() => handleColumnToggle(column)}
                                    />
                                }
                                label={column === 'name' ? 'Nombre' :
                                    column === 'category' ? 'Categoría' :
                                        column === 'price' ? 'Precio' : 'Cantidad'}
                            />
                        ))}
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseColumnSelectionDialog} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>


            {/* View Details Dialog */}
            <Dialog open={isViewDetailsOpen} onClose={() => setIsViewDetailsOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Detalles del Producto</DialogTitle>
                <DialogContent>
                    {selectedItem && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={selectedItem.imageUrl} alt={selectedItem.name} style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h4" gutterBottom>{selectedItem.name}</Typography>
                                <Typography variant="h5" color="#00b4d8" gutterBottom>₡{selectedItem.price.toLocaleString()}</Typography>
                                <Box sx={{ backgroundColor: '#f5f5f5', p: 2, borderRadius: 2, mb: 2 }}>
                                    <Typography variant="body1" gutterBottom><strong>Categoría:</strong> {selectedItem.category}</Typography>
                                    <Typography variant="body1" gutterBottom><strong>Cantidad en stock:</strong> {selectedItem.quantity}</Typography>
                                    <Typography variant="body1" gutterBottom><strong>Color:</strong> {selectedItem.color || 'N/A'}</Typography>
                                    <Typography variant="body1" gutterBottom><strong>Tamaño:</strong> {selectedItem.tamano || 'N/A'}</Typography>
                                </Box>
                                <Typography variant="body1" gutterBottom><strong>Descripción:</strong></Typography>
                                <Typography variant="body2">{selectedItem.description}</Typography>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsViewDetailsOpen(false)} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>


            {/* Edit Dialog */}
            <Dialog open={isViewDialogOpen} onClose={() => setIsViewDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>{selectedItem ? 'Editar Producto' : 'Agregar Producto'}</DialogTitle>
                <DialogContent>
                    {selectedItem && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dashed #00b4d8', borderRadius: 2, mb: 2 }}>
                                        <img src={selectedItem.imageUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                    </Box>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="edit-button-file"
                                        type="file"
                                        onChange={handleImageUpload}
                                    />
                                    <label htmlFor="edit-button-file">
                                        <Button
                                            variant="contained"
                                            component="span"
                                            startIcon={<CloudUploadIcon />}
                                            fullWidth
                                            sx={{ backgroundColor: '#00b4d8', '&:hover': { backgroundColor: '#0095b6' } }}
                                        >
                                            Cambiar Imagen
                                        </Button>
                                    </label>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Nombre"
                                    value={selectedItem.name}
                                    onChange={(e) => setSelectedItem({ ...selectedItem, name: e.target.value })}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal" label="Precio"
                                    type="number"
                                    value={selectedItem.price}
                                    onChange={(e) => setSelectedItem({ ...selectedItem, price: Number(e.target.value) })}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Cantidad"
                                    type="number"
                                    value={selectedItem.quantity}
                                    onChange={(e) => setSelectedItem({ ...selectedItem, quantity: Number(e.target.value) })}
                                />
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Categoría</InputLabel>
                                    <Select
                                        value={selectedItem.category}
                                        onChange={(e) => setSelectedItem({ ...selectedItem, category: e.target.value })}
                                    >
                                        {categories.filter(cat => cat !== 'Todos').map((category) => (
                                            <MenuItem key={category} value={category}>{category}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Color"
                                    value={selectedItem.color || ''}
                                    onChange={(e) => setSelectedItem({ ...selectedItem, color: e.target.value })}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Tamaño"
                                    value={selectedItem.tamano || ''}
                                    onChange={(e) => setSelectedItem({ ...selectedItem, tamano: e.target.value })}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Descripción"
                                    multiline
                                    rows={4}
                                    value={selectedItem.description || ''}
                                    onChange={(e) => setSelectedItem({ ...selectedItem, description: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsViewDialogOpen(false)}>Cancelar</Button>
                    <Button onClick={handleSaveEdit} color="primary" sx={{ backgroundColor: '#00b4d8', color: 'white', '&:hover': { backgroundColor: '#0095b6' } }}>Guardar Cambios</Button>
                </DialogActions>
            </Dialog>


            {/* Add Product Dialog */}
            <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Agregar Nuevo Producto</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dashed #00b4d8', borderRadius: 2, mb: 2 }}>
                                    {newItem.imageUrl ? (
                                        <img src={newItem.imageUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                    ) : (
                                        <Typography variant="body1" color="textSecondary">Vista previa de la imagen</Typography>
                                    )}
                                </Box>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={handleImageUpload}
                                    ref={fileInputRef}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                        fullWidth
                                        sx={{ backgroundColor: '#00b4d8', '&:hover': { backgroundColor: '#0095b6' } }}
                                    >
                                        Subir Imagen
                                    </Button>
                                </label>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Nombre"
                                value={newItem.name}
                                onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Precio"
                                type="number"
                                value={newItem.price}
                                onChange={(e) => setNewItem({ ...newItem, price: Number(e.target.value) })}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Cantidad"
                                type="number"
                                value={newItem.quantity}
                                onChange={(e) => setNewItem({ ...newItem, quantity: Number(e.target.value) })}
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Categoría</InputLabel>
                                <Select
                                    value={newItem.category}
                                    onChange={(e) => setNewItem({ ...newItem, category: e.target.value })}
                                >
                                    {categories.filter(cat => cat !== 'Todos').map((category) => (
                                        <MenuItem key={category} value={category}>{category}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Color"
                                value={newItem.color}
                                onChange={(e) => setNewItem({ ...newItem, color: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Tamaño"
                                value={newItem.tamano}
                                onChange={(e) => setNewItem({ ...newItem, tamano: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Descripción"
                                multiline
                                rows={4}
                                value={newItem.description}
                                onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAddDialogOpen(false)}>Cancelar</Button>
                    <Button onClick={handleAddItem} color="primary" sx={{ backgroundColor: '#00b4d8', color: 'white', '&:hover': { backgroundColor: '#0095b6' } }}>Agregar</Button>
                </DialogActions>
            </Dialog>

        </Box >
    );
};

export default InventoryManagement;