import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Avatar,
    Modal,
    Fade,
    IconButton,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { Order, OrderItem, InventoryItem } from '../models';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { collection, addDoc, writeBatch, increment, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { getUserRole } from '../utils/auth';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { RouteGroup, Route } from '../models';

interface OrderCreationModalProps {
    open: boolean;
    onClose: () => void;
    inventoryItems: InventoryItem[];
    existingOrder?: Order;
    onOrderCreated: (order: Order) => void;
    currentRouteGroup?: RouteGroup;
    currentRoute?: Route;
    onRouteGroupUpdate?: (updatedRouteGroup: RouteGroup) => Promise<void>;
}

// Definir un tipo extendido localmente
interface ExtendedOrder extends Order {
    routeGroupId?: string;
    routeId?: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: theme.spacing(1),
}));

const FixedBottomBox = styled(Box)(({ theme }) => ({
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const ProductImage = styled(Avatar)(({ theme }) => ({
    width: 60,
    height: 60,
    cursor: 'pointer',
    '&:hover': {
        boxShadow: theme.shadows[10],
    },
}));

const FullScreenImageModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const FullScreenImage = styled('img')({
    maxWidth: '90vw',
    maxHeight: '90vh',
    objectFit: 'contain',
    borderRadius: '8px',
    boxShadow: '0 0 24px rgba(0, 0, 0, 0.5)',
});

const successAnimation = keyframes`
  0% { transform: scale(0); opacity: 0; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`;

const SuccessIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
    fontSize: 60,
    color: theme.palette.success.main,
    animation: `${successAnimation} 0.5s ease-out`,
}));

const OrderCreationModal: React.FC<OrderCreationModalProps> = ({
    open,
    onClose,
    inventoryItems,
    existingOrder,
    onOrderCreated,
    currentRouteGroup,
    currentRoute,
    onRouteGroupUpdate
}) => {
    const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
    const [availableQuantities, setAvailableQuantities] = useState<{ [key: string]: number }>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [fullScreenImageOpen, setFullScreenImageOpen] = useState(false);
    const [fullScreenImageUrl, setFullScreenImageUrl] = useState('');
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (existingOrder) {
            setOrderItems(existingOrder.items);
        } else {
            setOrderItems([]);
        }

        const initialAvailableQuantities = inventoryItems.reduce((acc, item) => {
            const orderItem = existingOrder?.items.find(oi => oi.inventoryItemId === item.id);
            acc[item.id] = item.quantity - (orderItem?.quantity || 0);
            return acc;
        }, {} as { [key: string]: number });
        setAvailableQuantities(initialAvailableQuantities);
    }, [existingOrder, inventoryItems]);

    const handleQuantityChange = useCallback((itemId: string, quantity: number) => {
        setOrderItems(prevItems => {
            const existingItemIndex = prevItems.findIndex(item => item.inventoryItemId === itemId);
            if (existingItemIndex > -1) {
                const updatedItems = [...prevItems];
                updatedItems[existingItemIndex] = { ...updatedItems[existingItemIndex], quantity };
                return updatedItems;
            } else {
                return [...prevItems, { inventoryItemId: itemId, quantity }];
            }
        });

        setAvailableQuantities(prev => ({
            ...prev,
            [itemId]: inventoryItems.find(item => item.id === itemId)!.quantity - quantity
        }));
    }, [inventoryItems]);


    const handleSave = async () => {
        setIsLoading(true);
        try {
            const updatedOrderItems: OrderItem[] = orderItems.filter(item => item.quantity > 0).map(item => ({
                inventoryItemId: item.inventoryItemId,
                quantity: item.quantity
            }));

            const orderData: Omit<ExtendedOrder, 'id'> = {
                items: updatedOrderItems,
                ...(currentRouteGroup?.id && { routeGroupId: currentRouteGroup.id }),
                ...(currentRoute?.id && { routeId: currentRoute.id }),
            };

            let savedOrder: ExtendedOrder;

            if (existingOrder?.id) {
                const orderRef = doc(db, 'orders', existingOrder.id);
                await setDoc(orderRef, orderData, { merge: true });
                savedOrder = { ...orderData, id: existingOrder.id };
            } else {
                const docRef = await addDoc(collection(db, 'orders'), orderData);
                savedOrder = { ...orderData, id: docRef.id };
            }

            await updateInventory(updatedOrderItems, existingOrder?.items || []);

            // Update RouteGroup if necessary
            if (currentRouteGroup && currentRoute && onRouteGroupUpdate) {
                const updatedRouteGroup = { ...currentRouteGroup };
                const routeIndex = updatedRouteGroup.routes.findIndex(r => r.id === currentRoute.id);
                if (routeIndex !== -1) {
                    updatedRouteGroup.routes[routeIndex] = {
                        ...updatedRouteGroup.routes[routeIndex],
                        order: savedOrder as Order
                    };
                    await onRouteGroupUpdate(updatedRouteGroup);
                }
            }

            onOrderCreated(savedOrder as Order);
            setShowSuccessDialog(true);

            // Close the modal after a short delay
            setTimeout(() => {
                setShowSuccessDialog(false);
                onClose();
            }, 1500);
        } catch (error) {
            console.error('Error saving order:', error);
            toast.error('Error al guardar la orden. Por favor, inténtalo de nuevo.');
        } finally {
            setIsLoading(false);
        }
    };

    const saveOrder = async (order: Order): Promise<string> => {
        const orderRef = collection(db, 'orders');
        const docRef = await addDoc(orderRef, order);
        return docRef.id;
    };

    const updateInventory = async (newItems: OrderItem[], oldItems: OrderItem[] = []) => {
        const batch = writeBatch(db);

        const oldQuantities = new Map(oldItems.map(item => [item.inventoryItemId, item.quantity]));

        for (const item of newItems) {
            const itemRef = doc(db, 'inventoryItems', item.inventoryItemId);
            const itemDoc = await getDoc(itemRef);

            if (!itemDoc.exists()) {
                console.warn(`El item con ID ${item.inventoryItemId} no existe en el inventario.`);
                continue;
            }

            const oldQuantity = oldQuantities.get(item.inventoryItemId) || 0;
            const quantityDifference = oldQuantity - item.quantity;
            batch.update(itemRef, { quantity: increment(quantityDifference) });
        }

        for (const oldItem of oldItems) {
            if (!newItems.some(newItem => newItem.inventoryItemId === oldItem.inventoryItemId)) {
                const itemRef = doc(db, 'inventoryItems', oldItem.inventoryItemId);
                const itemDoc = await getDoc(itemRef);

                if (!itemDoc.exists()) {
                    console.warn(`El item con ID ${oldItem.inventoryItemId} no existe en el inventario.`);
                    continue;
                }

                batch.update(itemRef, { quantity: increment(oldItem.quantity) });
            }
        }

        await batch.commit();
    };

    const calculateTotalOrderPrice = useCallback(() => {
        return orderItems.reduce((total, item) => {
            const inventoryItem = inventoryItems.find(ii => ii.id === item.inventoryItemId);
            return total + (inventoryItem ? inventoryItem.price * item.quantity : 0);
        }, 0);
    }, [orderItems, inventoryItems]);

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('es-CR', { style: 'currency', currency: 'CRC' }).format(amount);
    };

    const handleImageClick = useCallback((imageUrl: string) => {
        setFullScreenImageUrl(imageUrl);
        setFullScreenImageOpen(true);
    }, []);

    const handleFullScreenImageClose = useCallback(() => {
        setFullScreenImageOpen(false);
    }, []);

    const filteredItems = inventoryItems.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (categoryFilter === '' || item.category === categoryFilter)
    );

    const categories = Array.from(new Set(inventoryItems.map(item => item.category)));

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle sx={{
                    backgroundColor: '#87CEEB',
                    color: 'white',
                    fontWeight: 'bold'
                }}>
                    {existingOrder ? 'Editar Orden' : 'Crear Orden'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, marginTop: 1 }}>
                        <TextField
                            label="Buscar"
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                endAdornment: <SearchIcon />
                            }}
                        />
                        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                            <InputLabel>Categoría</InputLabel>
                            <Select
                                value={categoryFilter}
                                onChange={(e) => setCategoryFilter(e.target.value as string)}
                                label="Categoría"
                            >
                                <MenuItem value="">Todas</MenuItem>
                                {categories.map((category) => (
                                    <MenuItem key={category} value={category}>{category}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell width="60px">Imagen</StyledTableCell>
                                    <StyledTableCell width="25%">Producto</StyledTableCell>
                                    <StyledTableCell width="15%" align="right">Precio</StyledTableCell>
                                    <StyledTableCell width="20%" align="center">Cantidad</StyledTableCell>
                                    <StyledTableCell width="20%" align="right">Total</StyledTableCell>
                                    <StyledTableCell width="60px" align="center">Acciones</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredItems.map((item) => {
                                    const orderItem = orderItems.find(oi => oi.inventoryItemId === item.id);
                                    const quantity = orderItem?.quantity || 0;
                                    const totalPrice = item.price * quantity;
                                    return (
                                        <TableRow key={item.id}>
                                            <StyledTableCell>
                                                <ProductImage
                                                    src={item.imageUrl}
                                                    alt={item.name}
                                                    onClick={() => handleImageClick(item.imageUrl)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {item.name}
                                                <Typography variant="caption" display="block" color="text.secondary">
                                                    Disponible: {availableQuantities[item.id]}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{formatCurrency(item.price)}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <IconButton
                                                    onClick={() => handleQuantityChange(item.id, Math.max(0, quantity - 1))}
                                                    disabled={quantity === 0}
                                                    size="small"
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                                <TextField
                                                    type="number"
                                                    value={quantity}
                                                    onChange={(e) => handleQuantityChange(item.id, Math.min(Math.max(0, parseInt(e.target.value) || 0), availableQuantities[item.id] + quantity))}
                                                    inputProps={{
                                                        min: 0,
                                                        max: availableQuantities[item.id] + quantity,
                                                    }}
                                                    sx={{
                                                        width: '40px',
                                                        mx: 1,
                                                        '& input': {
                                                            padding: '4px',
                                                            fontSize: '0.875rem',
                                                            textAlign: 'center',
                                                            MozAppearance: 'textfield',
                                                            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                                                                WebkitAppearance: 'none',
                                                                margin: 0,
                                                            },
                                                        }
                                                    }}
                                                    size="small"
                                                    disabled={quantity === 0 && availableQuantities[item.id] === 0}
                                                />
                                                <IconButton
                                                    onClick={() => handleQuantityChange(item.id, Math.min(quantity + 1, availableQuantities[item.id] + quantity))}
                                                    disabled={quantity === availableQuantities[item.id] + quantity}
                                                    size="small"
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{formatCurrency(totalPrice)}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleQuantityChange(item.id, 0)}
                                                    sx={{ color: 'rgb(255, 100, 100)' }}
                                                    disabled={quantity === 0}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </StyledTableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <FixedBottomBox>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">
                                Total de la Orden:
                            </Typography>
                            <Typography variant="h5" color="primary">
                                {formatCurrency(calculateTotalOrderPrice())}
                            </Typography>
                        </Box>
                    </FixedBottomBox>
                </DialogContent>
                <DialogActions sx={{
                    justifyContent: 'center',
                    px: 3,
                    py: 2,
                    backgroundColor: 'rgba(135, 206, 235, 0.1)'
                }}>
                    <Button onClick={onClose} variant="outlined"
                        sx={{
                            color: '#87CEEB',
                            borderColor: '#87CEEB',
                            '&:hover': {
                                backgroundColor: 'rgba(135, 206, 235, 0.1)',
                                borderColor: '#5CACEE',
                            },
                            fontWeight: 'bold',
                            px: 4,
                            py: 1,
                        }}
                        disabled={isLoading}>Cancelar</Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        sx={{
                            backgroundColor: '#87CEEB',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#5CACEE',
                            },
                            '&:disabled': {
                                backgroundColor: 'rgba(135, 206, 235, 0.5)',
                                color: 'rgba(255, 255, 255, 0.7)',
                            },
                            fontWeight: 'bold',
                            px: 4,
                            py: 1,
                        }}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Guardar'}
                    </Button>
                </DialogActions>
                <FullScreenImageModal
                    open={fullScreenImageOpen}
                    onClose={handleFullScreenImageClose}
                    closeAfterTransition
                >
                    <Fade in={fullScreenImageOpen}>
                        <FullScreenImage
                            src={fullScreenImageUrl}
                            alt="Product"
                            onClick={handleFullScreenImageClose}
                        />
                    </Fade>
                </FullScreenImageModal>
            </Dialog>
            <Dialog open={showSuccessDialog} aria-labelledby="success-dialog-title">
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
                    <SuccessIcon />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        {existingOrder ? 'Orden creada con éxito' : 'Orden actualizada con éxito'}
                    </Typography>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default OrderCreationModal;