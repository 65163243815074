import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
  }

  * {
    box-sizing: border-box;
  }

  :root {
    --primary-color: #87CEEB;
    --secondary-color: #5F9EA0;
    --text-color: #333333;
    --background-color: #FFFFFF;
  }

`;

export default GlobalStyles;